import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApiPrivate from '../../hooks/useApiPrivate';
import logoImg from '../../assets/imgs/logos/nav-logo.png';
import PlayerRegisterMainSection from './PlayerRegisterMainSection';
import PlayerRegisterHsSection from './PlayerRegisterHsSection';
import PlayerRegisterCollegeSection from './PlayerRegisterCollegeSection';
import PlayerRegisterGamertagSection from './PlayerRegisterGamertagsSection';
import PlayerRegisterSocialSection from './PlayerRegisterSocilaSection';


const PlayerRegisterPage = () => {
  const { apiPutPrivate, result } = useApiPrivate();
  const navigate = useNavigate();
  const [section, setSection] = useState(1);
  const [playerType, setPlayerType] = useState();
  // const [birthDate, setBirthDate] = useState('');
  // const [username, setUsername] = useState('');
  // const [fName, setFName] = useState('');
  // const [lName, setLName] = useState('');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [password2, setPassword2] = useState('');
  // const [mainData, setMainData] = useState({});
  // const [schoolState, setSchoolState] = useState('');
  // const [school, setSchool] = useState('');
  // const [gradDate, setGradDate] = useState('');

  useEffect(() => {
    if (result?.signupCompleted) {
      navigate('/');
    }
  }, [navigate, result]);

  const mainSectionDoneH = (data) => {
    setPlayerType(data?.playerType);
    if (data?.playerType === 2 || data?.playerType === 3) {
      setSection(2);
    } else {
      setSection(3);
    }
  }

  const schoolSectionDoneH = () => {
    setSection(3);
  }

  const gamertagsSectionDoneH = () => {
    setSection(4);
  }

  const socialSectionDoneH = () => {
    apiPutPrivate('/api/auth/register-final', {});
  }

  return (
    <div className='mx-auto w-90/100 xl:w-45/100'>
      <div className='flex justify-center mt-224 mx-10'>
        <img
          className=''
          src={logoImg}
          alt='Logo' />
      </div>

      <div className='mt-20'>

          {section === 1 &&
            <PlayerRegisterMainSection
              onDone={mainSectionDoneH} />}

          {section === 2 && playerType === 2 &&
            <PlayerRegisterHsSection
              onDone={schoolSectionDoneH} />}

          {section === 2 && playerType === 3 &&
            <PlayerRegisterCollegeSection
              onDone={schoolSectionDoneH} />}

          {section === 3 &&
            <PlayerRegisterGamertagSection
              onDone={gamertagsSectionDoneH} />}

          {section === 4 &&
            <PlayerRegisterSocialSection
              onDone={socialSectionDoneH} />}

      </div>

    </div>
  )
}

export default PlayerRegisterPage;
