import { useEffect, useState } from 'react';

import useApiPrivate from '../../../hooks/useApiPrivate';
import MatchCardProfile from './matches/MatchCardProfile';
import MatchDetails from './matches/MatchDetiails';


const MatchesPrivate = ({ player }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [matches, setMatches] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState();
  const [tab, setTab] = useState('pending');

  useEffect(() => {
    if (result?.matches) {
      setMatches(result.matches);
    }
  }, [result]);

  useEffect(() => {
    if (player?.id) {
      let filter = '';
      if (tab === 'pending') {
        filter = 'pending';
      } else if (tab === 'uploaded') {
        filter = 'uploaded'; 
      } else if (tab === 'finished') {
        filter = 'finished';
      }
      apiGetPrivate(`/api/matches/by-player`, { params: { filter }});
    }
  }, [apiGetPrivate, player.id, tab]);

  const showMatchDetailsH = (matchId) => {
    if (selectedMatchId === matchId) {
      setSelectedMatchId(null);
    } else {
      setSelectedMatchId(matchId);
    }
  }

  const renderMatches = (match, index) => {
    return (
      <div
        key={index}
        onClick={() => showMatchDetailsH(match.id)}
        className='cursor-pointer' >

        <MatchCardProfile match={match} />

        {match.id === selectedMatchId &&
          <div className='mb-6'>
            <MatchDetails matchId={selectedMatchId} />
          </div>}
      </div>
    )
  }

  return (
    <div>
      <div className='mb-6'>
        <button
          onClick={() => setTab('pending')}
          className={`mr-4 prof-link${tab === 'pending' ? ' active' : ''}`}>Pending</button>
        <button
          onClick={() => setTab('uploaded')}
          className={`mr-4 prof-link${tab === 'uploaded' ? ' active' : ''}`}>Uploaded</button>
        <button
          onClick={() => setTab('finished')}
          className={`prof-link${tab === 'finished' ? ' active' : ''}`}>Finished</button>
      </div>

      <div>
        {matches.length === 0 && <p>No {tab} matches</p>}
        {matches.map(renderMatches)}
      </div>

    </div>
  )
}

export default MatchesPrivate;
