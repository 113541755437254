import { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';

import useApiPrivate from '../../../../hooks/useApiPrivate';
// import { userActions } from '../../../../store/user';
import FormLabelInput from '../FormLabelInput';
import FormInput from '../FormInput';
import Button from '../../buttons/Buttons';


const GamertagsForm = ({ username, gamertags, playerType, onDone, onCancel }) => {
  const { apiPutPrivate, status } = useApiPrivate();

  const [lolTag, setLolTag] = useState({
    val: gamertags.lol ? gamertags.lol : '',
    origVal: gamertags.lol ? gamertags.lol : '',
    changed: false
  });
  const [rlTag, setRlTag] = useState({
    val: gamertags.rl ? gamertags.rl : '',
    origVal: gamertags.rl ? gamertags.rl : '',
    changed: false
  });

  useEffect(() => {
    if (status === 200) {
      let t = {};
      if (lolTag.val !== '') t['lol'] = lolTag.val;
      if (rlTag.val !== '') t['rl'] = rlTag.val;
      onDone({ gamertags: t });
    }
  }, [onDone, status, lolTag, rlTag]);

  const lolTagChange = (e) => {
    setLolTag({ ...lolTag, val: e.target.value, changed: true });
  }

  const rlTagChange = (e) => {
    setRlTag({ ...rlTag, val: e.target.value, changed: true });
  }

  const submit = (e) => {
    e.preventDefault();

    let newTags = {};
    if (lolTag.val !== '') newTags['lol'] = lolTag.val;
    if (rlTag.val !== '') newTags['rl'] = rlTag.val;

    const data = {
      form: e.target.id,
      username: username,
      playerType: playerType,
      gamertags: JSON.stringify(newTags)
    };

    apiPutPrivate(`/api/players/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='gamertags' onSubmit={submit}>
      <FormLabelInput
        label='Gamertags'
        classes=''
        classes2='mb-2 b-bk'
        placeholder='LOL'
        name='lol'
        value={lolTag.val}
        onChangH={lolTagChange} />
      <FormInput
        classes='mb-2 b-bk'
        placeholder='Rocketleague'
        name='rl'
        value={rlTag.val}
        onChangH={rlTagChange} />
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2 mr-3'
        type='submit'>Save</Button>
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2'
        click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default GamertagsForm;
