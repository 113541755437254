import LogoutBtn from "../../auth/LogoutBtn";


const ActionsPrivate = () => {
  return (
    <div className='flex'>
      <LogoutBtn classes='self-center' />
    </div>
  )
}

export default ActionsPrivate;
