// import { useState } from 'react';

import PageContainer from '../../components/layout/PageContainer';


const PlayerSubscriptionPage = () => {
  return (
    <PageContainer>
      <div>
        <p>Account Subscription</p>
      </div>
    </PageContainer>
    )
}

export default PlayerSubscriptionPage;
