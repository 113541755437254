import Buckets from './buckets/Buckets';


const HomePrivateC = ({ coach }) => {
  return (
    <div>
      <Buckets coach={coach} />
    </div>
  )
}

export default HomePrivateC;
