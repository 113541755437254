import { Link } from 'react-router-dom';

import PageContainer from '../../components/layout/PageContainer';
import GameImg from '../../components/games/GameImg';


const Leaderboards = () => {
  return (
    <PageContainer>

      <div>
        <Link to='/leaderboards/league-of-legends'>
          <div className='b3px-1 radius1 mb-6 p-2 flex'>
            <GameImg classes='radius1 h-24 mr-6' />
            <p className='self-center'>League of Legends</p>
          </div>
        </Link>
      </div>

    </PageContainer>
  )
}

export default Leaderboards;
