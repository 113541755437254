const ToolTip = ({ children, classes }) => {
  classes = classes + ' bg-2 px-3 py-2 radius2 absolute -top-10 hidden tip'

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default ToolTip;
