

const HomePublicC = ({ coach }) => {
  return (
    <div>
      Coach landing page
    </div>
  )
}

export default HomePublicC;
