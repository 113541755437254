import { useEffect, useState } from 'react';

import BackDrop from '../layout/modals/Backdrop';
import Clip from './Clip';
import ClipModalViewInfo from './ClipModalViewInfo';
import useApiPrivate from '../../hooks/useApiPrivate';


const ClipModalViewBase = ({ clipId, creator, onClose}) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [clip, setClip] = useState({});



  useEffect(() => {
    if (result?.clip) {
      console.log('R', result)
      setClip(result.clip);
    }
  }, [result]);

  useEffect(() => {
    apiGetPrivate(`/api/clips/${clipId}`);
  }, [apiGetPrivate, clipId]);

  return (
    <BackDrop>
      <div
        className='flex justify-between w-full h-full clip-modal'
        onClick={onClose}>

        <div className='w-full flex px-28'>
          <Clip
            clipUrl={clip.video}
            classes='radius1 pb-0.5 b-w mx-auto self-center' />
        </div>

        <div className='info-container bg-262435 shrink-0'>
          <ClipModalViewInfo clip={clip} creator={creator} />
        </div>

      </div>
    </BackDrop>
  )
}

export default ClipModalViewBase;
