import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

import useApiPrivate from '../../hooks/useApiPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';


// player send profile to coach
const SendPlayerCoach = ({ coachId, sentProfile = undefined, classes }) => {
  const { apiPostPrivate, apiDeletePrivate, result, status } = useApiPrivate();
  const [hasSent, setHasSent] = useState(sentProfile);
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);
  const userStateAccountType = useSelector(state => state.user.user.accountType);
  // const navigate = useNavigate();

  useEffect(() => {
    setHasSent(sentProfile);
  }, [sentProfile]);

  useEffect(() => {
    if (result && status === 200) {
      setHasSent(previous => !previous);
    }
  }, [result, status]);

  const sendProfile = useCallback(() => {
    const data = { coachId: coachId };
    apiPostPrivate(`/api/player-coach-sends/sends/${coachId}/`, data);
  }, [apiPostPrivate, coachId]);

  const unSendProfile = useCallback(() => {
    apiDeletePrivate(`/api/player-coach-sends/sends/${coachId}/`, { data: { coachId  }});
  }, [apiDeletePrivate, coachId]);

  // const upgradeAccount = useCallback(() => {
  //   navigate('/players/subscription')
  // }, [navigate]);

  let action = undefined;
  if (!hasSent) {
    action = sendProfile;
  } else {
    action = unSendProfile;
  }

  return (
    <>
      {userStateIsLoggedIn &&
      userStateAccountType === 1 &&
      <div className={classes}>
        <FontAwesomeIcon icon={faPaperPlane} className='mr-3' />
        <button onClick={action}>
          {hasSent ? 'Unsend' : 'Send'} profile
        </button>
      </div>}
    </>
  )
}

export default SendPlayerCoach;
