const LolPlayerEntryHeader = () => {
  return (
    <div className='w-full fs-14 flex justify-start leading-tight px-4 py-2.5 mb-1'>
      <div className='self-center t-4 mr-11 w-18/100'>
        <p>Game type</p>
      </div>
      <div className='self-center t-4 mr-11 w-8/100'>
        <p>Tier</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p>Rank</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p>Wins</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p>Losses</p>
      </div>
    </div>

  )
}

export default LolPlayerEntryHeader;
