import BackDrop from './Backdrop';
import Button from '../buttons/Buttons';


const ConfirmModal = ({ onConfirm, onCancel, children }) => {

  const confirmH = () => {
    onConfirm();
  }

  const cancelH = () => {
    onCancel();
  }

  const stopP = (e) => {
    e.stopPropagation();
  }

  return (
    <BackDrop onClose={onCancel}>
      <div onClick={stopP} className='b-1 radius1 p-8 w-30/100 bg-bk'>
        <div className='mb-4'>
          {children}
        </div>

        <div className='flex justify-between'>
          <Button classes='w-45/100 py-2 px-8 bg-5 t-3 ft1-med radius2' click={confirmH}>Confirm</Button>
          <Button classes='w-45/100 py-2 px-8 bg-5 t-3 ft1-med radius2' click={cancelH}>Cancel</Button>
        </div>
      </div>
    </BackDrop>
  )
}

export default ConfirmModal;
