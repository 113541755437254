import { useEffect, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import BucketPlayerCard from './BucketPlayerCard';


const Bucket = ({ bucketId }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [players, setPlayers] = useState();

  useEffect(() => {
    apiGetPrivate(`/api/player-buckets/${bucketId}/`);
  }, [apiGetPrivate, bucketId]);

  useEffect(() => {
    if (result?.players) {
      setPlayers(result.players);
    }
  }, [result]);

  const onRemoved = (removedPlayerId) => {
    setPlayers(players.filter(p => p.id !== removedPlayerId));
  };

  const renderPlayers = (player, index) => {
    return (
      <BucketPlayerCard
        key={index}
        bucketId={bucketId}
        playerId={player.id}
        username={player.username}
        slug={player.slug}
        imgUrl={player.img_1}
        playerType={player.player_type}
        onRemoved={onRemoved} />
    )
  }

  return (
    <div className='w-full'>
      <div className='grid grid-cols-5 md:grid-cols-4 gap-8 mb-12'>
        {players && players.map(renderPlayers)}
      </div>
    </div>
  )
}

export default Bucket;
