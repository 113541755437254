import { useEffect, useState } from 'react';

import Button from '../../../layout/buttons/Buttons';
import BackDrop from '../../../layout/modals/Backdrop';
import useApiPrivate from '../../../../hooks/useApiPrivate';


const CreateBucket = ({ coachId, onCreate }) => {
  const { apiPostPrivate, result, loading, error } = useApiPrivate();
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const showFormH = () => {
    setShowForm(true);
  }

  const hideFormH = () => {
    setName('');
    setDescription('');
    setShowForm(false);
  }

  useEffect(() => {
    if (result?.bucket) {
      result.bucket['playerCount'] = 0;
      result.bucket['players'] = [];
      onCreate(result.bucket);
      hideFormH();
    }
  }, [onCreate, result]);

  useEffect(() => {
    if (error?.data) {
      hideFormH();
    }
  }, [error]);

  const nameChangeH = (e) => {
    setName(e.target.value);
  }

  const descriptionChangeH = (e) => {
    setDescription(e.target.value);
  }

  const submitH = (e) => {
    e.preventDefault();

    const data = { coachId, name, description };

    apiPostPrivate(`/api/player-buckets/create-bucket/${coachId}/`, data);
  }

  return (
    <>
      <Button
        click={showFormH}
        classes='py-1.5 px-4 bg-5 t-3 ft1-med radius2'>Create bucket</Button>

      {showForm &&
        <BackDrop onClose={hideFormH}>
          <div className='w-40/100 p-6 b-1 bg-bk radius1'>
            <h1 className='mb-4'>Create Player Bucket</h1>
            <form onSubmit={submitH}>
              <div className='mb-4'>
                <label className='fs-13 t-5'>Bucket name</label>
                <input
                  id='title'
                  className='w-full p-0 fs-15 px-2'
                  type='text'
                  value={name}
                  placeholder='50 characters or less'
                  onChange={nameChangeH}
                  onFocus={nameChangeH}
                  onBlur={nameChangeH} />
              </div>
              <div className='mb-4'>
                <label className='fs-13 t-5'>Description</label>
                <textarea
                  id='description'
                  className='t-bk w-full p-0 fs-15 px-2'
                  value={description}
                  rows={4}
                  placeholder='100 characters or less'
                  autoComplete='off'
                  onChange={descriptionChangeH}
                  onFocus={descriptionChangeH}
                  onBlur={descriptionChangeH} />
              </div>
              <div className='flex justify-center mb-12'>
                <button
                  className={`btn w-full py-4 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}
                  type='submit'>
                  <span className={`${loading ? 'invisible' : ''}`}>Create bucket</span>
                </button>
              </div>
            </form>
          </div>
        </BackDrop>}
    </>
  )
}

export default CreateBucket;
