const LolPlayerMatchHeader = () => {
  return (
    <div className='w-full fs-14 flex justify-start leading-tight px-4 py-2.5 mb-1'>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p>Match ID</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p>KDA</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p>Kills</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p>Assists</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p>Deaths</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p>Total Damage</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p>DPM</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p>Gold Earned</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p>Position</p>
      </div>
      <div className='self-center t-4 w-8/100'>
        <p>Game Start</p>
      </div>
    </div>
  )
}

export default LolPlayerMatchHeader;
