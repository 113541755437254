import { useState, useCallback } from 'react';

import ProfileImg from '../players/ProfileImg';
import PlayerModalViewBase from '../players/profileModalView/PlayerModalViewBase';


const PlayerLeaderboardCard = ({ playerId, slug, imgUrl }) => {
  const [modalView, setModalView] = useState(false);

  const openModalViewH = useCallback(() => {
    setModalView(true);
  }, []);

  const closeModalViewH = useCallback(() => {
    setModalView(false);
  }, []);

  return (
    <>
      <div className='cursor-pointer img-container' onClick={openModalViewH}>
        <ProfileImg
          userId={playerId}
          imgName={imgUrl}
          classes='rounded-full b4px-w self-center' />
      </div>

      {modalView &&
        <PlayerModalViewBase
          playerId={playerId}
          slug={slug}
          onClose={closeModalViewH} />}
    </>
  )
}

export default PlayerLeaderboardCard;
