import './Form.css';


const FormInput = ({
    classes,
    id,
    type,
    name,
    placeholder,
    value,
    onChangH,
    onBlurH,
    onFocusH,
    errors
}) => {

  const renderErrors = (error, index) => {
    return (
      <li key={index}>{error}</li>
    )
  }

  return (
    <>
      <input
        id={id}
        className={classes}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete='off'
        onChange={onChangH}
        onFocus={onFocusH}
        onBlur={onBlurH} />

      <div>
        <ul>
          {errors && errors.map(renderErrors)}
        </ul>
      </div>
    </>
  )
}

export default  FormInput;
