import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faTwitch, faYoutube, faInstagram } from "@fortawesome/fontawesome-free-brands"
import { faVideo } from "@fortawesome/free-solid-svg-icons"

const SocialMediaIcon = ({ link, linkKey, classes, size }) => {
  let socialIcon = '';
  if (linkKey === 'tw') {
    socialIcon = <FontAwesomeIcon icon={faTwitch} size={size} className={classes} />;
  } else if (linkKey === 'tt') {
    socialIcon = <FontAwesomeIcon icon={faTwitter} size={size} className={classes} />;
  } else if (linkKey === 'yt') {
    socialIcon = <FontAwesomeIcon icon={faYoutube} size={size} className={classes} />;
  } else if (linkKey === 'tk') {
    socialIcon = <FontAwesomeIcon icon={faVideo} size={size} className={classes} />;
  } else if (linkKey === 'ig') {
    socialIcon = <FontAwesomeIcon icon={faInstagram} size={size} className={classes} />;
  }

  return (
    <a href={link} rel='noreferrer' target='_blank'>
      {socialIcon}
    </a>
  )
}

export default SocialMediaIcon;
