import { useEffect, useCallback, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import ConfirmModal from '../../../layout/modals/ConfirmModal';


const DeclineBtn = ({ matchId, classes, onDecline }) => {
  const { apiPutPrivate, result } = useApiPrivate();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (result?.declined) {
      setShowConfirm(false);
      onDecline();
    }
  }, [onDecline, result]);

  const declineH = useCallback((e) => {
    e.stopPropagation();

    setShowConfirm(true);
  }, []);

  const confirmH = useCallback(() => {
    const data = { matchId };

    apiPutPrivate(`/api/matches/player-decline`, data)
  }, [apiPutPrivate, matchId]);

  return (
    <>
      <button
        className={classes}
        onClick={declineH} >Decline</button>

      {showConfirm &&
        <ConfirmModal
          onConfirm={confirmH}
          onCancel={() => setShowConfirm(false)} >
          <div>Decline match</div>
          </ConfirmModal>}
    </>
  )
}

export default DeclineBtn;
