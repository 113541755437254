import { useEffect, useState, useCallback } from 'react';

import { formatDateTime } from '../../../../utils/formatting';
import AcceptBtn from './AcceptBtn';
import DeclineBtn from './DeclineBtn';


const MatchCardProfile = ({ match }) => {
  const [matchPlayerStatus, setMatchPlayerStatus] = useState();
  const [matchEdited, setMatchEdited] = useState();
  // 0 no show, 1 show decline, 2 show accept
  const [showEdit, setShowEdit] = useState(0);
  
  useEffect(() => {
    setMatchPlayerStatus(match.entrant_status);
    setMatchEdited(match.edited);
  }, [match.entrant_status, match.edited]);

  const acceptH = useCallback(() => {
    setMatchPlayerStatus(2);
    setShowEdit(false);
  }, []);

  const declineH = useCallback(() => {
    setMatchPlayerStatus(3);
  }, []);

  const editH = useCallback((e) => {
    e.stopPropagation();

    if (showEdit === 0) {
      if (matchPlayerStatus === 2) {
        setShowEdit(1);
      // } else if (matchPlayerStatus === 3) {
      //   setShowEdit(2);
      } else {
        setShowEdit(0);
      }
    } else {
      setShowEdit(0);
    }
  }, [matchPlayerStatus, showEdit]);

  return (
    <div className='bg-wgr radius1 px-4 py-3 mb-4 ft1 flex justify-between b-4'>
      <div className='flex items-center'>
        <p className='self-center'>ID{String(match.id).padStart(8, '0')}</p>

        {matchEdited &&
          <span className='ml-4 t-4 px-4 py-1 bg-pr radius1 fs-14'>Edited</span>}
      </div>

      <div className='flex items-center'>
        <p>Start {formatDateTime(match.start)}</p>

        {matchPlayerStatus === 2 &&
          <span className='ml-4 t-4 px-4 py-1 bg-pnk radius1 fs-14'>Accepted</span>}

        {matchPlayerStatus === 3 &&
          <span className='ml-4 t-4 px-4 py-1 bg-6 radius1 fs-14'>Declined</span>}

        {(matchPlayerStatus === 1) &&
          <AcceptBtn
            matchId={match.id}
            classes='b-ltg fs-14 t-4 px-3 py-1 radius1 ml-4 bg-pnk'
            onAccept={acceptH} />}
        {(matchPlayerStatus === 1 || showEdit === 1) &&
          <DeclineBtn
            matchId={match.id}
            classes='b-ltg fs-14 t-4 px-3 py-1 radius1 ml-4'
            onDecline={declineH} />}
        {matchPlayerStatus === 2 &&
          <button
            className='b-ltg fs-14 t-4 px-4 py-1 radius1 ml-4 bg-6'
            onClick={editH}>Edit</button>}
      </div>
    </div>
  )
}

export default MatchCardProfile;
