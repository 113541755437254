import BackDrop from './Backdrop';
import Button from '../buttons/Buttons';
import Privacy from '../../../pages/info/Privacy';


const TermsModal = ({ acceptTerms, declineTerms, onCancel }) => {

  // const acceptTermsH = () => {
  //   acceptTerms();
  // }

  // const declineTermsH = () => {
  //   declineTerms();
  // }

  // const cancelH = () => {
  //   onCancel();
  // }

  return (
    <BackDrop onClose={onCancel}>
      <div className='m-auto'>
      <div className='b-1 radius1 p-8 bg-bk my-8'>
        <div className='mb-4'>
          <Privacy />
        </div>

        <div className='flex justify-between'>
          <Button classes='w-45/100 py-2 px-8 bg-5 t-3 ft1-med radius2' click={acceptTerms}>Accept</Button>
          <Button classes='w-45/100 py-2 px-8 bg-5 t-3 ft1-med radius2' click={declineTerms}>Decline</Button>
        </div>
      </div>
      </div>
    </BackDrop>
  )
}

export default TermsModal;
