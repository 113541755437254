import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useApiPublic from '../../hooks/useApiPublic';
import { userActions } from '../../store/user';
import TermsModal from '../../components/layout/modals/TermsModal';
import { STATES } from '../../data/states';


const PlayerRegisterMainSection = ({ onDone }) => {
  const dispatch = useDispatch();
  const { apiPostPublic, result, error } = useApiPublic();
  const { apiGetPublic, result: resultGet } = useApiPublic();
  const [birthdate, setBirthdate] = useState('');
  const [birthdateClean, setBirthdateClean] = useState(false);
  const [playerType, setPlayerType] = useState(1);
  const [username, setUsername] = useState('');
  const [usernameClean, setusernameClean] = useState(false);
  const [fName, setFName] = useState('');
  const [fNameClean, setFNameClean] = useState(false);
  const [lName, setLName] = useState('');
  const [lNameClean, setLNameClean] = useState(false);
  const [email, setEmail] = useState('');
  const [emailClean, setEmailClean] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordClean, setPasswordClean] = useState(false);
  const [password2, setPassword2] = useState('');
  const [password2Clean, setPassword2Clean] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [termsDisabled, setTermsDisabled] = useState(true);
  const [terms, setTerms] = useState(false);
  const [city, setCity] = useState({});
  const [state, setState] = useState();
  const [cities, setCities] = useState([]);
  const [states] = useState(STATES);
  const [formClean, setFormClean] = useState(true);

  useEffect(() => {
    if (birthdateClean && usernameClean && fNameClean &&
        lNameClean && emailClean && passwordClean && password2Clean && terms) {
      setFormClean(true);
    } else {
      setFormClean(true);
    }
  }, [birthdateClean, usernameClean, fNameClean, lNameClean,
      emailClean, passwordClean, password2Clean, terms]);

  useEffect(() => {
    if (birthdateClean && usernameClean && fNameClean &&
        lNameClean && emailClean && passwordClean && password2Clean && !terms) {
      setShowTerms(true);
    } else {
      setShowTerms(false);
    }
  }, [birthdateClean, usernameClean, fNameClean, lNameClean,
      emailClean, passwordClean, password2Clean, terms]);

  useEffect(() => {
    if (resultGet?.cities) {
      setCities(resultGet.cities);
    }
  }, [resultGet]);

  useEffect(() => {
    if (state) {
      apiGetPublic('/api/locations/cities/', { params: { state_abr: state }});
    }
  }, [apiGetPublic, state]);

  const doneH = useCallback((playerType) => {
    onDone({ playerType });
  }, [onDone]);

  useEffect(() => {
    if (result?.user) {
      const { playerType } = result.user;
      dispatch(userActions.register(
        {
          ...result.user,
          token: result.accessToken
        }
      ));
      doneH(playerType);
    }
  }, [dispatch, doneH, result]);

  const submitH = (e) => {
    e.preventDefault();

    const data = {
      birthdate,
      playerType,
      username,
      fName,
      lName,
      email,
      password,
      password2,
      terms,
      locationId: city?.id,
      cityState: JSON.stringify(city)
    }

    apiPostPublic('/api/auth/register', data, { withCredentials: true });
  }

  const birthDateChangeH = (e) => {
    setBirthdate(e.target.value);
    if (e.target.value.length > 0)
      setBirthdateClean(true);
    else
      setBirthdateClean(false);
  }

  const playerTypeChangeH = (e) => {
    setPlayerType(e.target.value);
  }

  const fNameChangeH = (e) => {
    setFName(e.target.value);
    if (e.target.value.length > 0)
      setFNameClean(true);
    else
      setFNameClean(false);
  }

  const lNameChangeH = (e) => {
    setLName(e.target.value);
    if (e.target.value.length > 0)
      setLNameClean(true);
    else
      setLNameClean(false);
  }

  const usernameChangeH = (e) => {
    setUsername(e.target.value);
    if (e.target.value.length > 0)
      setusernameClean(true)
    else
      setusernameClean(false);
  }

  const emailChangeH = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0)
      setEmailClean(true);
    else
      setEmailClean(false);
  }

  const passwordChangeH = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length > 0) {
      setPasswordClean(true);
    }
    else {
      setPasswordClean(false);
    }
  }

  const password2ChangeH = (e) => {
    setPassword2(e.target.value);
    if (e.target.value === password)
      setPassword2Clean(true);
    else
      setPassword2Clean(false);
  }

  const termsChangeH = (e) => {
      setTerms(previous => !previous);
  }

  const termsAcceptedH = () => {
    setTermsDisabled(false);  
    setTerms(true);
    setShowTerms(false);
  }

  const termsDeclinedH = () =>  {
    setTerms(false);
    setShowTerms(false);
  }

  const clickedSTermsH = (e) => {
    e.stopPropagation();
    setShowTerms(true);
  }

  const stateChangeH = (e) => {
    setCity({});
    setState(e.target.value);
  }

  const cityChangeH = (e) => {
    const city = e.target.options[e.target.selectedIndex].getAttribute('data-name');
    setCity({ id: e.target.value, city: city, state: state });
  }

  const renderErrors = (error, index) => {
    return (
      <li key={index}>{error.message}</li>
    )
  }

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  const renderCities = (city, index) => {
    return(
      <option
        key={index}
        value={city.id}
        data-name={city.city} >{city.city}</option>
    )
  }

  return (
    <>
      <div>

      {error?.data !== undefined &&
        <div className='ml-6 mb-6 t-pk'>
          <ul>
            {error.data.map(renderErrors)}
          </ul>
        </div>}

        <form onSubmit={submitH}>
          <div className='mb-8 flex justify-between flex-wrap'>
            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Birth date</label>
              <input
                id='birthdate'
                className='auth p-0 t-4 fs-15'
                type='date'
                value={birthdate}
                max="2023-01-01"
                onChange={birthDateChangeH} />

            </div>

            <div className='w-full md:w-48/100 b-4 radius2 py-2 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Player type</label>
              <select
                id='playertype'
                className='auth w-full p-0 t-4 fs-15'
                value={playerType}
                onChange={playerTypeChangeH}
                onFocus={playerTypeChangeH}
                onBlur={playerTypeChangeH} >

                <option value='1'>Amateur</option>
                <option value='2'>High School</option>
                <option value='3'>Collegiate</option>
                <option value='4'>Semi Pro</option>
                <option value='5'>Pro</option>

              </select>
            </div>
          </div>

          <div className='mb-8 flex justify-between flex-wrap'>
            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 bg-prgr leading-none'>
              <label className='fs-13 t-5'>First name</label>
              <input
                id='fname'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={fName}
                autoComplete='off'
                onChange={fNameChangeH} />
            </div>

            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Last name</label>
              <input
                id='lname'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={lName}
                autoComplete='off'
                onChange={lNameChangeH} />
            </div>
          </div>


          <div className='mb-8 flex justify-between flex-wrap'>
            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
              <label className='fs-13 t-5'>State</label>
              <select
                id='state'
                className='auth w-full p-0 t-4 fs-15'
                onChange={stateChangeH} >

                  <option value='0'>Select One</option>
                  {states.map(renderStates)}

                </select>
            </div>

            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>City</label>
              <select
                id='city'
                className='auth w-full p-0 t-4 fs-15'
                onChange={cityChangeH} >

                {city?.id && <option value={city.id}>{city.city}</option>}
                {!city?.id &&<option value=''>Select One</option>}
                <option value='0'>None</option>
                {cities.map(renderCities)}

              </select>
            </div>
          </div>


          <div className='mb-8 flex justify-between flex-wrap'>
            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Username</label>
              <input
                id='username'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={username}
                autoComplete='off'
                onChange={usernameChangeH} />
            </div>

            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Email</label>
              <input
                id='email'
                className='auth w-full p-0 t-4 fs-15'
                type='email'
                value={email}
                autoComplete='off'
                onChange={emailChangeH} />
            </div>
          </div>

          <div className='flex justify-between flex-wrap'>
            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Password</label>
              <input
                id='password'
                className='auth w-full p-0 t-4 fs-15'
                type='password'
                value={password}
                autoComplete='off'
                onChange={passwordChangeH} />
            </div>

            <div className='b-4 w-full md:w-48/100 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Password confirm</label>
              <input
                id='password2'
                className='auth w-full p-0 t-4 fs-15'
                type='password'
                value={password2}
                autoComplete='off'
                onChange={password2ChangeH} />
            </div>
          </div>

          <div className='mt-6 pl-6 p-0 flex items-start justify-start grow-0'>
            <label
              htmlFor='tos'
              className='fs-13 t-5 mb-0 max-w-fit whitespace-nowrap' >
              <span onClick={clickedSTermsH}>Terms of Service</span>
            <input
                id='tos'
                disabled={termsDisabled}
                className='b-r ml-4'
                type='checkbox'
                checked={terms}
                onChange={termsChangeH} />
            </label>

          </div>


          <div className='mt-8'>
            <button
              type='submit'
              disabled={!formClean}
              className='btn w-full py-4 bg-5 t-3 ft1-med relative radius2'>Next</button>
          </div>

        </form>
      </div>

      {showTerms &&
        <TermsModal
          acceptTerms={termsAcceptedH}
          declineTerms={termsDeclinedH}
          onCancel={() => {setShowTerms(false)}} />}
    </>
  )
}

export default PlayerRegisterMainSection;
