import { useState, useEffect } from 'react';

import DefaultImg from '../../assets/imgs/defaults/default-prof.webp';


const ProfileImg = ({ userId, imgName, classes = '' }) => {
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    const getImg = async () => {
      let src = '';
      if (imgName && imgName !== 'img/defaults/user.webp') {
        src = `${process.env.REACT_APP_BASE_MEDIA_URL}/images${imgName}`;
      } else {
        src = DefaultImg;
      }
      setImgSrc(src);
    };

    getImg();
  }, [userId, imgName]);

  return (
    <img 
      src={imgSrc}
      alt='profile-img'
      className={classes} />
  )
}

export default ProfileImg;
