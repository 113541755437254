import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';


const PlayerModalViewNav = ({ onSectionChange }) => {

  const sectionChangeH = useCallback((section) => {
    onSectionChange(section);
  }, [onSectionChange]);

  return (
    <div className=''>
      <button
        onClick={() => sectionChangeH('stats')}
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faChartSimple} className='mr-3' />
        Stats
      </button>

      <button
        onClick={() => sectionChangeH('videos')}
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faPhotoFilm} className='mr-3' />
        Video Gallery
      </button>
    </div>
  )
}

export default PlayerModalViewNav;
