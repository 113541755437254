import { useEffect, useState, useCallback } from 'react';

import PageContainer from '../../components/layout/PageContainer';
import useApiPublic from '../../hooks/useApiPublic';
import Button from '../../components/layout/buttons/Buttons';
import GamesGameCard from '../../components/games/GamesGameCard';


const GamesPage = () => {
  const { apiGetPublic, error } = useApiPublic();
  const [page, setPage] = useState(0);
  const [perPage] = useState(5);
  const [moreGames, setMoreGames] = useState(true);
  const [games, setGames] = useState([]);

  const gamesSet = useCallback(result => {
    if (result?.games.length === 0) {
      setMoreGames(false);
    } else {
      setGames(previous => [...previous, ...result.games]);
      if (result?.games.length < perPage) {
        setMoreGames(false);
      }
    }
  }, [perPage]);

  const getGames = useCallback((page, perPage) => {
    const params = { page: page, perPage: perPage };

    apiGetPublic(
      '/api/games/',
      { params: params},
      gamesSet);
  }, [apiGetPublic, gamesSet]);

  useEffect(() => {
    getGames(0, perPage);
    setPage(1);
  }, [getGames, perPage]);

  const getMoreGames = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getGames(page, perPage)
  }

  const renderGames = (game, index) => {
    return (
      <GamesGameCard
        key={index}
        game={game} />
    )
  }

  return (
    <PageContainer>
      <div className='grid grid-cols-2 md:grid-cols-5 gap-8 mb-10'>
        {games.length > 0 && games.map(renderGames)}
      </div>

      {moreGames && error === undefined &&
        <div>
          <Button
            classes='btn-blk b-pr py-3 px-2.5 radius1 bg-pr t-w'
            click={getMoreGames}>Get More</Button>
        </div>}
    </PageContainer>
  )
}

export default GamesPage;
