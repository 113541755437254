import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import FormatDetails from './FormatDetails';
import { formatFormatId } from '../../../../utils/formatting';


const MatchDetails = ({ matchId, onRemoveMatch }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const { apiPutPrivate, result: putResult } = useApiPrivate();
  const [match, setMatch] = useState();
  const [entrants, setEntrants] = useState([]);
  const [currentMatchStart, setCurrentMatchStart] = useState();
  const [start, setStart] = useState('');
  const [formatId, setFormatId] = useState('');
  const [allowUpload, setAllowUpload] = useState(false);
  const [allowSend, setAllowSend] = useState(false);

  useEffect(() => {
    if (result?.match) {
      setMatch(result.match);
      setEntrants(result.match.entrants);
      setFormatId(formatFormatId(result.match.format_id));

      if (result.match.start) {
        const d = new Date(result.match.start);
        const localStart = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
        setStart(localStart.toISOString().slice(0, 16));
        setCurrentMatchStart(localStart.toISOString().slice(0, 16));
      } else {
        setStart('');
        setCurrentMatchStart('')
      }
    }
  }, [result]);

  useEffect(() => {
    if (putResult?.status === 2) {
      onRemoveMatch(matchId);
    } else if (putResult?.status === 3) {
      onRemoveMatch(matchId);
    } else if (putResult?.start) {
      const d = new Date(putResult.start.start);
      const localStart = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
      setStart(localStart.toISOString().slice(0, 16));
      setCurrentMatchStart(localStart.toISOString().slice(0, 16));
    }
  }, [onRemoveMatch,  putResult, matchId]);

  useEffect(() => {
    apiGetPrivate(`/api/matches/for-coach/${matchId}`)
  }, [apiGetPrivate, matchId]);

  const saveStartH = () => {
    const utcStart = new Date(start).toISOString();
    const data = { matchId, start: utcStart };
    apiPutPrivate(`/api/matches/start/${matchId}`, data);
  }

  const onAllowSendH = useCallback((teamsAreFull) => {
    if (teamsAreFull && match.status === 1 && start) {
      setAllowSend(true);
    } else {
      setAllowSend(false);
    }
  }, [start, match?.status]);

  const onAllowUploadH = useCallback((teamsAreFull) => {
    if (teamsAreFull && start) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
  }, [start]);

  const uploadMatchH = () => {
    const data = { matchId, status: 3 };
    apiPutPrivate(`/api/matches/status/${matchId}`, data);
  };

  const sendMatchH = () => {
    const data = { matchId, status: 2 };
    apiPutPrivate(`/api/matches/status/${matchId}`, data);
  };

  const onEntrantRemovedH = useCallback((userId) => {
    const newEntrants = entrants.filter(e => e.user_id !== userId);
    setEntrants(newEntrants);
  }, [entrants]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  return (
    <div className='my-8' onClick={stopPropagation}>
      <div className='mb-6 flex justify-between'>
        <div
          onClick={stopPropagation}
          className='b-4 w-full md:w-32/100 radius2 py-2.5 px-8 bg-prgr leading-none'>
          <input
            className='auth w-full p-0 t-4 fs-15'
            type='text'
            value={formatId}
            autoComplete='off'
            disabled={true} />
        </div>

        <div className='w-full md:w-32/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none flex justify-between'>
          <input
            className='auth p-0 t-4 fs-15'
            type='datetime-local'
            value={start}
            onChange={(e) => setStart(e.target.value)} />
          {currentMatchStart !== start &&
            <span
              className='fs-15 mt-1'
              onClick={saveStartH} >save</span>}
        </div>

        {allowSend &&
          <div className='flex justify-end w-full md:w-32/100'>
            <button
              className='w-full btn px-10 py-4 bg-5 t-3 ft1-med relative radius2'
              onClick={sendMatchH} >Send</button>
          </div>}

        {(match?.status === 2) && allowUpload &&
          <div className='flex justify-end w-full md:w-32/100'>
            <button
              className='w-full btn px-10 py-4 bg-5 t-3 ft1-med relative radius2'
              onClick={uploadMatchH}
              disabled={!allowUpload} >Upload</button>
          </div>}

      </div>

      <div className='mb-8'>
        {match?.id &&
          <FormatDetails
            matchId={match.id}
            gameId={match.game_id}
            formatId={match.format_id}
            matchStatus={match.status}
            entrants={entrants}
            onEntrantRemoved={onEntrantRemovedH}
            onAllowUpload={onAllowUploadH}
            onAllowSend={onAllowSendH} />}
      </div>

    </div>
  )
}

export default MatchDetails;
