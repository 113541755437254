import { createSlice } from '@reduxjs/toolkit';

const initMessagesState = {
  message: ''
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initMessagesState,
  reducers: {
    newMessage(state, action) {
      state.message = action.payload.message
    }
  }
});

export const messagesActions = messagesSlice.actions;

export default messagesSlice.reducer;
