import { useState, useEffect, useRef } from 'react';


const Clip = ({
    clipUrl,
    classes,
    hasControls = true,
    playOnHover = false }) => {

  const [vidSrc, setVidSrc] = useState();
  const videoRef = useRef();

  useEffect(() => {
    setVidSrc(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${clipUrl}#t=0.1`);

    videoRef.current?.load();
  }, [clipUrl]);

  const onClickH = (e) => {
    e.preventDefault();
  }

  if (playOnHover) {
    return (
      <video
        ref={videoRef}
        className={classes}
        controls={hasControls}
        muted={true}
        onClick={onClickH}
        preload='metadata'
        onMouseEnter={(e) => {e.target.play()}}
        onMouseLeave={(e) => {e.target.pause()}} >

        <source src={vidSrc} type='video/mp4' />

      </video>
    )
  }

  return (
    <video
      ref={videoRef}
      className={classes}
      controls={hasControls}
      onClick={onClickH}
      preload='metadata' >

      <source src={vidSrc} type='video/mp4' />

    </video>
  )
}

export default Clip;
