import { useState, useCallback } from 'react';
import axios from 'axios';


const useApiPublic = () => {
  const [result, setResult] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const apiGetPublic = useCallback(async (url, options = {}, callBackF = undefined) => {
    setLoading(true);
    axios.get(process.env.REACT_APP_BASE_URL + url, options)
      .then((res) => {
        console.log('GET RES PUBLIC: ', url, res.data);
        setError(previous => {
          if (previous) {
            return [];
          } else {
            return;
          }
        });
        if (callBackF !== undefined) {
          callBackF(res.data);
        } else {
          setResult(res.data);
        }
        setStatus(res.status);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response);
        setStatus(err.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const apiPostPublic = useCallback(async (url, data = {}, options = {}) => {
    setLoading(true);
    axios.post(process.env.REACT_APP_BASE_URL + url, data, options)
      .then((res) => {
        console.log('POST RES PUBLIC: ', url, res.data);
        setResult(res.data);
        setStatus(res.status);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response);
        setStatus(err.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    apiGetPublic,
    apiPostPublic,
    setStatus,
    status,
    result,
    error,
    loading
  }
}

export default useApiPublic;
