import PageContainer from '../../components/layout/PageContainer';


const AboutPage = () => {
  return (
    <PageContainer>
      <h1>About</h1>
    </PageContainer>
  )
}

export default AboutPage;
