import StatsPublic from "./StatsPublic";


const HomePublic = ({ player }) => {
  return (
    <div>
      <StatsPublic player={player} />
    </div>
  )
}

export default HomePublic;
