import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../hooks/useApiPrivate';


const PlayerRegisterSocialSection = ({ onDone, onBack }) => {
  const { apiPutPrivate, result } = useApiPrivate();
  const [twitter, setTwitter] = useState('');
  const [twitch, setTwitch] = useState('');
  const [youTube, setYoutube] = useState('');
  const [facebook, setFacebook] = useState('');
  const [tikTok, setTicktok] = useState('');
  const [instagram, setInstagram] = useState('');
  const [formClean, setFormClean] = useState(false);

  useEffect(() => {
    if (twitter.length > 0 || twitch.length > 0 ||
        youTube.length > 0 || facebook.length > 0 ||
        tikTok.length > 0 || instagram.length > 0) {
      setFormClean(true);
    } else {
      setFormClean(false);
    }
  }, [twitter, twitch, youTube, facebook, tikTok, instagram]);

  const doneH = useCallback(() => {
    onDone();
  }, [onDone]);

  useEffect(() => {
    if (result?.socialLinks) {
      doneH();
    }
  }, [doneH, result]);

  const submitH = (e) => {
    e.preventDefault();

    const socialLinks = {};
    if (twitter !== '') socialLinks['tt'] = twitter;
    if (twitch !== '') socialLinks['tw'] = twitch;
    if (facebook !== '') socialLinks['fb'] = facebook;
    if (youTube !== '') socialLinks['yt'] = youTube;
    if (tikTok !== '') socialLinks['tk'] = tikTok;
    if (instagram !== '') socialLinks['ig'] = instagram;

    const data = { socialLinks: JSON.stringify(socialLinks)}

    apiPutPrivate('/api/auth/register-social', data);
  }

  const facebookChangeH = (e) => {
    setFacebook(e.target.value);
  }

  const twitterChangeH = (e) => {
    setTwitter(e.target.value);
  }

  const twitchChangeH = (e) => {
    setTwitch(e.target.value);
  }

  const tikTokChangeH = (e) => {
    setTicktok(e.target.value);
  }

  const instagramChangeH = (e) => {
    setInstagram(e.target.value);
  }

  const youTubeChange = (e) => {
    setYoutube(e.target.value);
  }

  return (
    <div>
      <form onSubmit={submitH}>
        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
            <label className='fs-13 t-5'>Facebook</label>
            <input
              id='facebook'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={facebook}
              onChange={facebookChangeH} />
          </div>

          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
            <label className='fs-13 t-5'>Twitter</label>
            <input
              id='twitter'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={twitter}
              onChange={twitterChangeH}/>
          </div>
        </div>

        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
            <label className='fs-13 t-5'>Twitch</label>
            <input
              id='twitch'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={twitch}
              onChange={twitchChangeH} />
          </div>

          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
            <label className='fs-13 t-5'>TikTok</label>
            <input
              id='tiktok'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={tikTok}
              onChange={tikTokChangeH}/>
          </div>
        </div>

        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
            <label className='fs-13 t-5'>Instagram</label>
            <input
              id='instagram'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={instagram}
              onChange={instagramChangeH} />
          </div>

          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
            <label className='fs-13 t-5'>YouTube</label>
            <input
              id='youtube'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={youTube}
              onChange={youTubeChange}/>
          </div>
        </div>

        <div className='mt-8 flex justify-between flex-wrap'>
          <button
            className='btn w-full md:w-48/100 py-4 bg-5 t-3 ft1-med relative radius2'>Skip</button>
          <button
            type='submit'
            disabled={!formClean}
            className='btn w-full md:w-48/100 py-4 bg-5 t-3 ft1-med relative radius2'>Next</button>
        </div>
      </form>
    </div>
  )
}

export default PlayerRegisterSocialSection;