import { useEffect, useState, useCallback } from 'react';

import BackDrop from '../../layout/modals/Backdrop';
import PageContainer from '../../layout/PageContainer';
import useApiPrivate from '../../../hooks/useApiPrivate';

import PlayerModalViewHeader from './PlayerModalViewHeader';
import ActionsPublic from '../profilePublic/ActionsPublic';
import PlayerModalViewNav from './PlayerModalViewNav';
import HomePublic from '../profilePublic/HomePublic';
import VideosPublic from '../profilePublic/VideosPublic';
import FollowersPublic from '../profilePublic/FollowersPublic';
import FollowingPublic from '../profilePublic/FollowingPublic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';


const PlayerModalViewBase = ({ playerId, slug, onClose}) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [player, setPlayer] = useState({});
  const [section, setSection] = useState('home');

  useEffect(() => {
    if (result?.user) {
      setPlayer(result.user);
    }
  }, [result]);

  useEffect(() => {
    apiGetPrivate(`/api/players/${slug}/`);
  }, [apiGetPrivate, slug]);

  const sectionChangeH = useCallback((section) => {
    if (section === 'stats') {
      setSection('home');
    } else if (section === 'videos') {
      setSection('videos');
    } else if (section === 'followers') {
      setSection('followers');
    } else if (section === 'following') {
      setSection('following');
    }
  }, []);

  return (
    <BackDrop onClose={onClose}>
      <FontAwesomeIcon
        className='cursor-pointer absolute top-2 left-2'
        icon={faCircleXmark}
        size='2x'
        onClick={() => {onClose()}} />

      <PageContainer>
        <PlayerModalViewHeader
          player={player}
          onSectionChange={sectionChangeH} />

        <div className='mt-10 mb-6 flex justify-between'>
          <ActionsPublic player={player} />
          <PlayerModalViewNav onSectionChange={sectionChangeH} />
        </div>

        {section === 'home' && <HomePublic player={player} />}
        {section === 'videos' && <VideosPublic player={player} />}
        {section === 'followers' && <FollowersPublic player={player} />}
        {section === 'following' && <FollowingPublic player={player} />}

      </PageContainer>
    </BackDrop>
  )
}

export default PlayerModalViewBase;
