import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import useTalkjs from '../../hooks/useTalkjs';


const Chat = () => {
  const { createInbox, createInboxWithOther } = useTalkjs();
  const location = useLocation();
  const otherId = location.state?.otherUserId;
  const otherUsername = location.state?.otherUserUsername;
  const otherImg = location.state?.otherUserImg;
  const chatInboxElRef = useRef(null);

  useEffect(() => {
    if (otherId) {
      createInboxWithOther(chatInboxElRef.current, otherId, otherUsername, otherImg);
    } else {
      createInbox(chatInboxElRef.current);
    }
  }, [createInboxWithOther, createInbox, otherId, otherUsername, otherImg]);

  return (
    <div className=''>

      <div
        className='w-full h-75v mr-8'
        ref={chatInboxElRef} >
      </div>

    </div>
  )
}

export default Chat;
