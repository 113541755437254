import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import useRefreshAccess from './apiRefreshAccess';


const useApiPrivate = () => {
  const [result, setResult] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.user.token);
  const { refreshToken } = useRefreshAccess();

  const apiGetPrivate = useCallback(async (url, options = {}, callBackF = undefined) => {
    if (token !== '') {
      setLoading(true);
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.get(process.env.REACT_APP_BASE_URL + url, options)
        .then((res) => {
          console.log('GET RES PRIVATE: ', url, res.data);
          if (callBackF !== undefined) {
            callBackF(res.data);
          } else {
            setResult(res.data);
          }
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken]);

  const apiPostPrivate = useCallback(async (url, data, options = {}) => {
    if (token !== '') {
      setLoading(true);
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.post(process.env.REACT_APP_BASE_URL + url, data, options)
        .then((res) => {
          console.log('POST RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken]);

  const apiPutPrivate = useCallback(async (url, data, options) => {
    if (token !== '') {
      setLoading(true);
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.put(process.env.REACT_APP_BASE_URL + url, data, options)
        .then((res) => {
          console.log('PUT RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.response.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken]);
  
  const apiDeletePrivate = useCallback(async (url, data, options) => {
    if (token !== '') {
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.delete(process.env.REACT_APP_BASE_URL + url, data, options)
        .then((res) => {
          console.log('DELETE RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken]);

  return {
    apiGetPrivate,
    apiPostPrivate,
    apiPutPrivate,
    apiDeletePrivate,
    setStatus,
    status,
    result,
    error,
    loading
  }
}

export default useApiPrivate;
