import { useEffect, useState } from 'react';

import useApiPrivate from '../../../hooks/useApiPrivate';
import LolPlayerEntryHeader from '../../gameApis/LolPlayerEntryHeader';
import LolPlayerEntryCard from '../../gameApis/LolPlayerEntryCard';
import LolPlayerMatchHeader from '../../gameApis/LolPlayerMatchHeader';
import LolPlayerMatchCard from '../../gameApis/LolPlayerMatchCard';


const StatsPublic = ({ player }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [matches, setMatches] = useState([]);
  const [entries, setEntries] = useState([]);


  useEffect(() => {
    if (player?.id) {
      apiGetPrivate(`/api/lol-api/player-stats/${player.id}`);
    }
  }, [apiGetPrivate, player]);

  useEffect(() => {
    if (result?.data?.entries) {
      setEntries(result.data.entries);
    }
    if (result?.data?.matches) {
      setMatches(result.data.matches);
    }
  }, [result]);

  const renderEntries = (entry, index) => {
    return (
      <LolPlayerEntryCard key={index} entry={entry} />
    )
  }

  const renderMatches = (match, index) => {
    return (
      <LolPlayerMatchCard key={index} match={match} />
    )
  }

  return (
    <div>
      {matches.length > 0 &&
        <div>
          <h1 className='mb-2 ml-4 fs-32'>League of Legends Player Stats</h1>
          <LolPlayerEntryHeader />
          {entries.map(renderEntries)}
          <div className='mt-10 mb-6 mt-2'>
            <h1 className='mb-2 ml-4 fs-32'>League of Legends recent matches</h1>
            <LolPlayerMatchHeader />
            {matches.map(renderMatches)}
          </div>
        </div>}

      {matches.length === 0 &&
        <h1>You have no LOL stats currently</h1>}
    </div>
  )
}

export default StatsPublic;
