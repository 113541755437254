const LolPlayerEntryCard = ({ entry }) => {
  return (
    <div className='w-full fs-14 flex justify-start leading-tight bg-wgr b-4 px-4 py-2.5 mb-4 radius1'>
      <div className='self-center t-4 mr-11 w-18/100'>
        <p className='t-1 fs-16 mt-1'>{entry.queueType}</p>
      </div>
      <div className='self-center t-4 mr-11 w-8/100'>
        <p className='t-1 fs-16 mt-1 w-5/100'>{entry.tier}</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p className='t-1 fs-16 mt-1 w-5/100'>{entry.rank}</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p className='t-1 fs-16 mt-1 w-5/100'>{entry.wins}</p>
      </div>
      <div className='self-center t-4 mr-11 w-5/100'>
        <p className='t-1 fs-16 mt-1'>{entry.losses}</p>
      </div>
    </div>
  )
}

export default LolPlayerEntryCard;
