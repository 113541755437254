import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from '../../components/layout/PageContainer';
import useApiPublic from '../../hooks/useApiPublic';


const GamePage = () => {
  const { slug } = useParams();
  const { apiGetPublic, result } = useApiPublic();

  useEffect(() => {
    apiGetPublic(`/api/games/${slug}`);
  }, [apiGetPublic, slug]);

  return (
    <PageContainer>
      <h1 className='mb-4'>{result?.game.name}</h1>
      <p>This is a game details API endpoint that we can display whatever we want such as related clips / api leaderboards / top players of game etc...</p>
    </PageContainer>
  )
}

export default GamePage;
