import { useEffect, useState, useCallback } from 'react';

import useApiPublic from '../../hooks/useApiPublic';
import useApiPrivate from '../../hooks/useApiPrivate';
import { STATES } from '../../data/states';

const PlayerRegisterHsSection = ({ onDone, onBack }) => {
  const { apiGetPublic, result: getResult } = useApiPublic();
  const { apiPutPrivate, result } = useApiPrivate();
  const [states] = useState(STATES);
  const [state, setState] = useState('');
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState('');
  const [classOf, setClassOf] = useState('');
  const [formClean, setFromClean] = useState(false);
  const schoolType = 2;

  useEffect(() => {
    if (school && classOf.length > 0) {
      setFromClean(true);
    } else {
      setFromClean(false);
    }
  }, [school, classOf]);

  useEffect(() => {
    if (getResult?.schools) {
      setSchools(getResult.schools);
    }
  }, [getResult]);

  const doneH = useCallback(() => {
    const data = { schoolType, schoolId: school.id };
    onDone(data);
  }, [onDone, schoolType, school]);

  useEffect(() => {
    if (result?.school) {
      doneH();
    }
  }, [doneH, result]);

  useEffect(() => {
    if (state) {
      apiGetPublic('/api/highschools/', { params: { state: state }});
    }
  }, [apiGetPublic, state]);

  const stateChangeH = (e) => {
    setState(e.target.value);
  }

  const schoolChangeH = (e) => {
    const schoolName = e.target.options[e.target.selectedIndex].getAttribute('data-name');
    setSchool({ id: e.target.value, name: schoolName });
  }

  const classOfChangeH = (e) => {
    setClassOf(e.target.value);
  }

  const submitH = (e) => {
    e.preventDefault();

    const data = { schoolType: schoolType, schoolId: school.id, classOf: classOf };
    apiPutPrivate('/api/auth/register-school', data);
  }

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  const renderSchools = (school, index) => {
    return (
      <option
        key={index}
        value={school.id}
        data-name={school.name} >{school.name} ({school.city})</option>
    )
  }

  return (
    <div>
      <h1 className='mb-4 ml-4 fs-20'>High school</h1>
      <form onSubmit={submitH}>
        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
            <label className='fs-13 t-5'>High school state</label>
            <select
              id='state'
              className='auth w-full p-0 t-4 fs-15'
              onChange={stateChangeH} >

                <option value='0'>Select One</option>
                {states.map(renderStates)}

              </select>
          </div>

          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
            <label className='fs-13 t-5'>High school</label>
            <select
              id='school'
              className='auth w-full p-0 t-4 fs-15'
              onChange={schoolChangeH} >

              {school?.id && <option value={school.id}>{school.name}</option>}
              {!school?.id &&<option value=''>Select One</option>}
              <option value='0'>None</option>
              {schools.map(renderSchools)}

            </select>
          </div>
        </div>

        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 bg-prgr leading-none'>
            <label className='fs-13 t-5'>Graduating class</label>
            <input
              id='classof'
              className='auth p-0 t-4 fs-15'
              type='date'
              value={classOf}
              max="2033-01-01"
              onChange={classOfChangeH} />
          </div>

        </div>

        <div className='mt-8 flex justify-between flex-wrap'>
          <button
            type='submit'
            disabled={!formClean}
            className='btn w-full py-4 bg-5 t-3 ft1-med relative radius2'>Next</button>
        </div>
      </form>
    </div>
  )
}

export default PlayerRegisterHsSection;
