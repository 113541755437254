import { useState, useEffect } from 'react';

import DefaultImg from '../../assets/imgs/defaults/default-game.webp';


const GameImg = ({ gameId, imgName, classes = '' }) => {
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    const getImg = async () => {
      let src = '';
      if (imgName && imgName !== 'img/defaults/game.webp') {
        src = `${process.env.REACT_APP_BASE_URL}/public/game/${gameId}/${imgName}`;
      } else {
        src = DefaultImg;
      }
      setImgSrc(src);
    };

    getImg();
  }, [gameId, imgName]);

  return (
    <img 
      src={imgSrc}
      alt='game img'
      className={classes} />
  )
}

export default GameImg;
