import { useState, useCallback, useEffect } from 'react';

import CreateBucket from './CreateBucket';
import Bucket from './Bucket';
import BucketCard from './BucketCard';
import Button from '../../../layout/buttons/Buttons';
import useApiPrivate from '../../../../hooks/useApiPrivate';


const Buckets = ({ coach }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [buckets, setBuckets] = useState([]);
  const [selectedBucket, setSelecteedBucket] = useState({});
  const [showBucketPlayers, setShowBucketPlayers] = useState(false);

  useEffect(() => {
    if (result?.buckets) {
      setBuckets(result.buckets);
    }
  }, [result]);

  useEffect(() => {
    if (coach?.id) {
      apiGetPrivate(`/api/player-buckets/`);
    }
  }, [apiGetPrivate, coach.id]);

  const onBucketCreatedH = useCallback((newBcuket) => {
    setBuckets(previous => {
      return [...previous, newBcuket];
    });
  }, [setBuckets]);

  const showBucketPlayersH = (e, bucketId, bucketTitle, bucketDescription) => {
    setSelecteedBucket({ id: bucketId, title: bucketTitle, description: bucketDescription });
    setShowBucketPlayers(true);
  }

  const hidebucketPlayersH = () => {
    setShowBucketPlayers(false);
  }

  const onDeletedH = (bucketId) => {
    setBuckets(buckets.filter(p => p.id !== bucketId));
  }

  const renderBuckets = (bucket, index) => {
    return (
      <div
        key={index}
        onClick={(e) => showBucketPlayersH(e, bucket.id, bucket.title, bucket.description)}>
        <BucketCard
          selected={bucket.id === selectedBucket.id ? true : false}
          bucketId={bucket.id}
          bucketTitle={bucket.title}
          bucketDescription={bucket.description}
          playersList={bucket.players}
          onDeleted={onDeletedH} />
      </div>
    )
  }

  return (
    <div className='flex'>

      <div className='w-full'>
        <div className='w-full flex justify-between items-start'>
          <p className='fs-32 fnt1-med mb-6'>Player buckets</p>
          <CreateBucket
            coachId={coach.id}
            onCreate={onBucketCreatedH} />
        </div>

        <div className='grid grid-cols-2 md:grid-cols-4 gap-8 mb-12'>
          {buckets.map(renderBuckets)}
        </div>

        {showBucketPlayers &&
          <div>
            <div className='flex justify-between'>
              <div className='mb-4 mr-12'>
                <p className='fs-32 ft1-med'>{selectedBucket.title}</p>
              </div>
              <Button
                click={hidebucketPlayersH}
                classes='self-start py-1.5 px-8 bg-5 t-3 ft1-med radius2'>Close</Button>
            </div>
            <Bucket bucketId={selectedBucket.id} />
          </div>}

      </div>

    </div>
  )
}

export default Buckets;
