import { useEffect, useState, useCallback } from 'react';

import ProfileImg from '../../players/ProfileImg';
import Button from '../../layout/buttons/Buttons';
import ProfileImgForm from '../../layout/forms/players/ProfileImgForm';
import NameFormCoach from '../../layout/forms/coaches/NameFormCoach';
import CoachTypeForm from '../../layout/forms/coaches/CoachTypeForm';
import SchoolForm from '../../layout/forms/coaches/SchoolForm';
import CollegeForm from '../../layout/forms/coaches/CollegeForm';
import PasswordChange from '../../layout/forms/PasswordChange';


const SettingsC = ({ coachInfo, onCoachUpdate }) => {
  const [section, setSection] = useState(0);
  const [coach, setCoach] = useState({});
  const [profileImg, setProfileImg] = useState(coachInfo.img_1);

  useEffect(() => {
    setCoach(coachInfo);
  }, [coachInfo]);

  const onDoneH = useCallback((updatedInfo) => {
    setCoach(previous => {
      return {...previous, ...updatedInfo };
    });
    setSection(0);
  }, []);

  const onDoneImgH = useCallback((newImgSrc) => {
    setCoach(previous => {
      return { ...previous, img_1: newImgSrc }
    });
    setProfileImg(newImgSrc);
    setSection(0);
  }, []);

  const onCancelH = useCallback((e) => {
    e.preventDefault();
    setSection(0);
  }, []);

  return (
    <div className='flex justify-between'>

      <div className='w-45/100'>

        <div className=''>
          <div className='mb-12 flex justify-center'>
            {section !== 8 &&
              <div className='relative rounded-full b4px-w w-40/100 img-container'>
                <ProfileImg
                  userId={coach.id}
                  imgName={profileImg}
                  classes='rounded-full' />
                <Button
                  classes='bg-pr t-w absolute bottom-0 -right-5 px-2 radius1'
                  click={() => setSection(8)}>Change</Button>
              </div>}

            {section === 8 &&
              <ProfileImgForm
                onDone={onDoneImgH}
                onCancel={onCancelH} />}
            </div>
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 1 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Name</h2>
                  <Button click={() => setSection(1)}>Edit</Button>
                </div>
                <p className='t-b2'>{coach.firstName} {coach.lastName}</p>
              </div>}
            {section === 1 &&
              <NameFormCoach
                username={coach.username}
                fName={coach.firstName}
                lName={coach.lastName}
                onDone={onDoneH}
                onCancel={onCancelH} />}
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 6 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Coach Type</h2>
                  <Button click={() => setSection(6)}>Edit</Button>
                </div>
                <div className='t-b2'>
                  {coach.coach_type === 1 && <p>Unknown</p>}
                  {coach.coach_type === 2 && <p>High School</p>}
                  {coach.coach_type === 3 && <p>Collegiate</p>}
                  {coach.coach_type === 4 && <p>Pro</p>}
                </div>
              </div>}
              {section === 6 &&
                <CoachTypeForm
                  username={coach.username}
                  playerType={coach.coach_type}
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 2 &&
            <div>
              <div className='flex justify-between'>
                <h2>High School</h2>
                <Button click={() => setSection(2)}>Edit</Button>
              </div>
              <p className='t-b2'>{coach.high_school?.name ? coach.high_school?.name : 'None'}</p>
            </div>}
            {section === 2 &&
              <SchoolForm
                username={coach.username}
                coachesSchool={coach.school}
                onDone={onDoneH}
                onCancel={onCancelH} />}
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 7 &&
            <div>
              <div className='flex justify-between'>
                <h2>College</h2>
                <Button click={() => setSection(7)}>Edit</Button>
              </div>
              <p className='t-b2'>{coach.college?.name ? coach.college.name : 'None'}</p>
            </div>}
            {section === 7 &&
              <CollegeForm
                username={coach.username}
                coachCollege={coach.college}
                onDone={onDoneH}
                onCancel={onCancelH} />}
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 9 &&
            <div>
              <div className='flex justify-between'>
                <h2>Change Password</h2>
                <Button classes='t-4' click={() => setSection(9)}>Edit</Button>
              </div>
            </div>}
            {section === 9 &&
              <PasswordChange
                username={coachInfo.username}
                onDone={onDoneH}
                onCancel={onCancelH} />}
          </div>

      </div>

    </div>
  )
}

export default SettingsC;
