import { useEffect, useState } from 'react';

import PageContainer from '../../components/layout/PageContainer';
import useApiPrivate from '../../hooks/useApiPrivate';


const EmailVerifyLandingPage = () => {
  const { apiPostPrivate, result, loading } = useApiPrivate();
  const [pageState, setPageState] = useState(1);

  useEffect(() =>{
    if (result?.sent) {
      setPageState(2);
    }
  }, [result]);



  const reSendEmailH = () => {
    apiPostPrivate('/api/auth/email-verify/resend', {});
  }

  return (
    <PageContainer>
      <div className='text-center'>
      <h1>You must verify you email to access the site.</h1>

      {pageState === 1 &&
        <button
          onClick={reSendEmailH}
          disabled={loading}
          className={`btn mt-8 py-3 px-16 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}>
          <span className={`${loading ? 'invisible' : ''}`}>Resend email verification</span></button>}

      {pageState === 2 &&
        <p>Verification email has been sent. Please check your email</p>
      }
      </div>


    </PageContainer>
  )
}

export default EmailVerifyLandingPage;
