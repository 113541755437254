import { useEffect, useState, useCallback } from 'react';

import PageContainer from '../../components/layout/PageContainer';
import useApiPublic from '../../hooks/useApiPublic';
import Button from '../../components/layout/buttons/Buttons';
import FilterPlayers from '../../components/players/utils/FilterPlayers';
import SearchPlayers from '../../components/players/utils/SearchPlayers';
import PlayerCard from '../../components/players/PlayerCard';


const PlayersPage = () => {
  const { apiGetPublic, error } = useApiPublic();
  const [page, setPage] = useState(0);
  const [perPage] = useState(12);
  const [moreUsers, setMoreUsers] = useState(true);
  const [users, setUsers] = useState([]);
  // 1 none, 2 search, 3 filter
  const [showSearchFilter, setShowSearchFilter] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});

  const usersSet = useCallback((result) => {
    if (result?.users.length === 0) {
      setMoreUsers(false);
    } else {
      setUsers(previous => [...previous, ...result.users]);
      if (result?.users.length < perPage) {
        setMoreUsers(false);
      }
    }
  }, [perPage]);

  const getPlayers = useCallback((page, perPage) => {
    const params = { page, perPage, ...searchFilterParams };

    apiGetPublic(
      '/api/players/',
      { params: params},
      usersSet);
  }, [apiGetPublic, usersSet, searchFilterParams]);

  useEffect(() => {
    getPlayers(0, perPage);
    setPage(1);
  }, [getPlayers, perPage]);

  const getMorePlayers = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getPlayers(page, perPage)
  }

  const searchH = (usernameToSearch) => {
    setPage(0);
    setUsers([]);
    setMoreUsers(true);
    setSearchFilterParams({ search: usernameToSearch});
  }

  const filterH = (filterVal) => {
    setPage(0);
    setUsers([]);
    setMoreUsers(true);
    setSearchFilterParams({ filter: filterVal });
  }

  const clearSearchFilter = () => {
    setShowSearchFilter(1);
    setPage(0);
    setUsers([]);
    setMoreUsers(true);
    setSearchFilterParams({});
  }

  const cancelSearchFilter = () => {
    if (Object.keys(searchFilterParams).length > 0 ) {
      clearSearchFilter();
    } else {
      setShowSearchFilter(1);
    }
  }

  const renderPlayers = (player, index) => {
    return (
      <PlayerCard
        key={index}
        playerId={player.id}
        username={player.username}
        slug={player.slug}
        imgUrl={player.img_1}
        playerType={player.player_type} />
    )
  }

  return (
    <PageContainer>
      <div className='mt-4 mb-10 flex'>
        {showSearchFilter === 1 &&
          <div>
            <Button
              click={() => setShowSearchFilter(2)}
              classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'>Search</Button>
            <Button
              click={() => setShowSearchFilter(3)}
              classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'>Filter</Button>
          </div>}

        {showSearchFilter === 2 &&
          <div className='flex'>
            <SearchPlayers onSearch={searchH} />
            {Object.keys(searchFilterParams).length === 0 &&
              <Button
                click={() => cancelSearchFilter()}
                classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'>Cancel</Button>}
          </div>}

        {showSearchFilter === 3 &&
          <div className='flex'>
            <FilterPlayers onFilter={filterH} />
            {Object.keys(searchFilterParams).length === 0 &&
              <Button
                click={() => cancelSearchFilter()}
                classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'>Cancel</Button>}
          </div>}

          {Object.keys(searchFilterParams).length > 0 &&
            <Button
              classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'
              click={clearSearchFilter}>Clear</Button>}
      </div>

      <div className='grid grid-cols-2 md:grid-cols-4 gap-8 mb-12'>
        {users.length > 0 && users.map(renderPlayers)}
      </div>

      {moreUsers && error === undefined &&
        <div className='flex justify-center'>
          <Button
            classes='py-4 px-8 bg-5 t-3 ft1-med radius2'
            click={getMorePlayers}>Load more</Button>
        </div>}
    </PageContainer>
  )
}

export default PlayersPage;
