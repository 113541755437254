import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const FeatureClipPublic = ({ featured, size = 'fs-24' }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faStar}
        className={`${size} ${featured ? 't-y' : 't-w'}`} />
    </>
  )
}

export default FeatureClipPublic;
