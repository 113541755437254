import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from '../../components/layout/PageContainer';
import useApiPublic from '../../hooks/useApiPublic';
import LoadingSpinner from '../../components/layout/LoadingSpinner';
import useApiUpdateUserState from '../../hooks/useApiUpdateUserState';


const EmailVerifyPage = () => {
  const { token } = useParams();
  const { apiPostPublic, result, error } = useApiPublic();
  const { updateState } = useApiUpdateUserState();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (result?.verified) {
      setVerified(true);
      updateState();
    }
  }, [updateState, result]);

  useEffect(() => {
    if (token) {
      apiPostPublic(`/api/auth/email-verify`, { token });
    }
  }, [apiPostPublic, token]);

  let pageContext = '';
  if (error?.data === undefined) {
    if (!verified) {
      pageContext = (
        <div className='text-center'>
          <h1 className='mb-6'>Verifying your email</h1>
          <LoadingSpinner size='100px' />
        </div>
      )  
    } else {
      pageContext = (
        <div className='text-center'>
          <h1 className='mb-6'>Your email has been verified</h1>
        </div>
      )
    }
  }

  return (
    <PageContainer>
      <div className='text-center'>
        {pageContext}
        {error?.data !== undefined &&
          <div className='m-3'>
            <p>Error: {error.data?.message}</p>
          </div>}
      </div>
    </PageContainer>
  )
}


export default EmailVerifyPage;
