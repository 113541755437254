import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userActions } from '../../store/user';
import useApiPrivate from '../../hooks/useApiPrivate';


const LogoutBtn = ({ classes }) => {
  const { apiPostPrivate, status } = useApiPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 200 || status === 500) {
      dispatch(userActions.logout());
      navigate('/login');
    }
  }, [dispatch, navigate, status]);

  const logoutH = () => {
    apiPostPrivate('/api/auth/logout');
  }

  return (
    <button
      className={classes}
      onClick={logoutH}>Logout</button>
  )
}

export default LogoutBtn;
