import { useEffect, useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';

import useApiPrivate from '../../../hooks/useApiPrivate';
import PageContainer from '../../layout/PageContainer';
import HeaderPrivate from './HeaderPrivate';
import ActionsPrivate from './ActionsPrivate';
import NavPrivate from './NavPrivate';
import HomePrivate from './HomePrivate';
import FollowingPrivate from './FollowingPrivate';
import FollowersPrivate from './FollowersPrivate';
import FollowersCoachPrivate from './FollowersCoachPrivate';
import FollowingCoachPrivate from './FollowingCoachPrivate';
import MatchesPrivate from './MatchesPrivate';
import Settings from './Settings';
import VideosPrivate from './VideosPrivate';
import Chat from '../../chat/Chat';
import VideoUpload from '../../layout/forms/players/VideoUpload';


const BasePrivate = ({ slug }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [player, setPlayer] = useState({});

  useEffect(() => {
    if (result?.user) {
      setPlayer(result.user);
    }
  }, [result]);

  const getPlayer = useCallback(() => {
    apiGetPrivate(`/api/players/${slug}/`);
  }, [apiGetPrivate, slug]);

  useEffect(() => {
    getPlayer();
  }, [getPlayer]);

  const playerInfoChangeH = useCallback(() => {
    getPlayer();
  }, [getPlayer]);

  return (
    <>
      <PageContainer>
        <HeaderPrivate player={player} />

        <div className='mt-10 mb-6 flex justify-between'>
          <ActionsPrivate />
          <NavPrivate />
        </div>

      </PageContainer>

      <Routes>
        <Route
          path=''
          element={<HomePrivate player={player} />} />
        <Route
          path='/followers'
          element={<FollowersPrivate player={player} />} />
        <Route
          path='/following'
          element={<FollowingPrivate player={player} />} />
        <Route
          path='/followers-coach'
          element={<FollowersCoachPrivate player={player} />} />
        <Route
          path='/following-coach'
          element={<FollowingCoachPrivate player={player} />} />
        <Route
          path='/matches'
          element={<MatchesPrivate player={player} />} />
        <Route
          path='/settings'
          element={<Settings playerInfo={player} onPlayerUpdate={playerInfoChangeH} />} />
        <Route
          path='/messages'
          element={<Chat />} />
        <Route
          path='/videos/gallery'
          element={<VideosPrivate player={player} />} />
        <Route
          path='/videos/upload'
          element={<VideoUpload player={player} />} />
      </Routes>
    </>
  )
}

export default BasePrivate;
