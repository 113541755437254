import { useState } from 'react';

import Button from '../../layout/buttons/Buttons';
import FormInput from '../../layout/forms/FormInput';


const SearchPlayers = ({ onSearch }) => {
  const [search, setSearch] = useState('');

  const onSearchH = () => {
    onSearch(search);
  }

  const onChangeH = (e) => {
    setSearch(e.target.value);
  }

  return (
    <>
      <div className='b-4 radius2 py-2.5 px-8 bg-prgr leading-none mr-4'>
        <FormInput
          classes='auth w-full p-0 t-4 fs-15'
          type='text'
          placeholder='Search username starts with'
          value={search}
          onChangH={onChangeH} />
      </div>
      <Button
        click={onSearchH}
        classes='py-2 px-4 bg-5 t-3 ft1-med radius2 mr-3'>Apply</Button>
    </>
  )
}

export default SearchPlayers;
