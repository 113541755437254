import { useState, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import DeleteClipModal from './DeleteClipModal';
import useMediaPrivate from '../../hooks/useMediaPrivate';


const DeleteClip = ({ clipId }) => {
  const { mediaDeletePrivate } = useMediaPrivate();
  const [showModal, setShowModal] = useState(false);

  const showModalH = useCallback(() => {
    setShowModal(preveious => !preveious);
  }, []);

  const deleteClipH = useCallback(() => {
    mediaDeletePrivate(`/api/videos/delete-clip/${clipId}`);
    setShowModal(false);
  }, [mediaDeletePrivate, clipId]);

  return (
    <>
      <div
        className='radius1 absolute t-r right-2 top-2 cursor-pointer z-1 bg-217-3'
        onClick={showModalH}>
        <FontAwesomeIcon icon={faXmark} size='2x' className='px-2' />
      </div>

      {showModal &&
        <DeleteClipModal
          onClose={() => setShowModal(false)}
          onConfirm={deleteClipH} />}
    </>
  )
}

export default DeleteClip;
