import { useEffect, useState, useCallback } from 'react';

import ProfileImg from '../ProfileImg';
import NameForm from '../../layout/forms/players/NameForm';
import PlayerTypeForm from '../../layout/forms/players/PlayerTypeForm';
import GamertagsForm from '../../layout/forms/players/GamertagsForm';
import SocialLinksForm from '../../layout/forms/players/SocialLinksForm';
import SchoolForm from '../../layout/forms/players/SchoolForm';
import CollegeForm from '../../layout/forms/players/CollegeForm';
import ProfileImgForm from '../../layout/forms/players/ProfileImgForm';
import PasswordChange from '../../layout/forms/PasswordChange';
import LocationForm from '../../layout/forms/players/LocationForm';
import { formatTagLabel, formatLinkLabel } from '../../../utils/formatting';
import Button from '../../layout/buttons/Buttons';

import AdminRunSummonerCheck from '../../gameApis/AdminRunSummonerCheck';


const Settings = ({ playerInfo, onPlayerUpdate }) => {
  const [section, setSection] = useState(0);
  const [player, setPlayer] = useState({});
  const [profileImg, setProfileImg] = useState(playerInfo?.img_1);

  useEffect(() => {
    setPlayer(playerInfo);
  }, [playerInfo]);

  const onDoneH = useCallback((updatedInfo) => {
    setPlayer(previous => {
      return {...previous, ...updatedInfo };
    });
    onPlayerUpdate();
    setSection(0);
  }, [onPlayerUpdate]);

  const onDoneImgH = useCallback((newImgSrc) => {
    setPlayer(previous => {
      return { ...previous, img_1: newImgSrc }
    });
    setProfileImg(newImgSrc);
    setSection(0);
  }, []);

  const onCancelH = useCallback((e) => {
    e.preventDefault();
    setSection(0);
  }, []);

  const renderGamertags = (tag, index, key) => {
    return (
      <div key={index} className='mt-3'>
        <h3 className='fs-13'>{formatTagLabel(key[index])}</h3>
        <p className='t-b2'>{player.gamertags[tag]}</p>
      </div>
    )
  }

  const renderSocialLinks = (link, index, key) => {
    return (
      <div key={index} className='mt-3'>
        <h3 className='fs-13'>{formatLinkLabel(key[index])}</h3>
        <p className='t-b2'>{player.links[link]}</p>
      </div>
    )
  }


  return (
    <>
      <div className='flex justify-between'>
        {/* left column */}
        <div className='w-45/100'>

          <div className=''>
            <div className='mb-12 flex justify-center'>
              {section !== 8 &&
                <div className='relative rounded-full b4px-w'>
                  <ProfileImg
                    userId={player.id}
                    imgName={profileImg}
                    classes='rounded-full' />
                  <Button
                    classes='bg-pr t-w absolute bottom-0 -right-5 px-2 radius1'
                    click={() => setSection(8)}>Change</Button>
                </div>}

              {section === 8 &&
                <ProfileImgForm
                  saveToApiDb={player.gamertags ? true : false}
                  onDone={onDoneImgH}
                  onCancel={onCancelH} />}
            </div>
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 1 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Name</h2>
                  <Button classes='t-4' click={() => setSection(1)}>Edit</Button>
                </div>
                <p className='t-b2'>{player.firstName} {player.lastName}</p>
              </div>}
            {section === 1 &&
              <NameForm
                username={player.username}
                fName={player.firstName}
                lName={player.lastName}
                onDone={onDoneH}
                onCancel={onCancelH} />}
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
              {section !== 20 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Location</h2>
                  <Button classes='t-4' click={() => setSection(20)}>Edit</Button>
                </div>
                <p className='t-b2'>
                  {player.city_state?.state ? `${player.city_state?.state}, ${player.city_state?.city}` : 'None'}
                </p>
              </div>}
              {section === 20 &&
                <LocationForm
                  username={player.username}
                  playerCityState={player.city_state}
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
            </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 6 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Player Type</h2>
                  <Button classes='t-4' click={() => setSection(6)}>Edit</Button>
                </div>
                <div className='t-b2'>
                  {player.player_type === 1 && <p>Amateur</p>}
                  {player.player_type === 2 && <p>High School</p>}
                  {player.player_type === 3 && <p>Collegiate</p>}
                  {player.player_type === 4 && <p>Semi Pro</p>}
                </div>
              </div>}
              {section === 6 &&
                <PlayerTypeForm
                  username={player.username}
                  playerType={player.player_type}
                  gamertags={player.gamertags} // used to check and send player type to api database
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
            </div>

            <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
              {section !== 2 &&
              <div>
                <div className='flex justify-between'>
                  <h2>High School</h2>
                  <Button classes='t-4' click={() => setSection(2)}>Edit</Button>
                </div>
                <p className='t-b2'>{player.high_school?.name ? player.high_school.name : 'None'}</p>
              </div>}
              {section === 2 &&
                <SchoolForm
                  username={player.username}
                  playersSchool={player.school}
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
            </div>

            <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
              {section !== 7 &&
              <div>
                <div className='flex justify-between'>
                  <h2>College</h2>
                  <Button classes='t-4' click={() => setSection(7)}>Edit</Button>
                </div>
                <p className='t-b2'>{player.college?.name ? player.college.name : 'None'}</p>
              </div>}
              {section === 7 &&
                <CollegeForm
                  username={player.username}
                  playerCollege={player.college}
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
            </div>

        </div>


        {/* right column */}
        <div className='w-45/100'>
          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            <p>Subscription level <span className='ml-2 t-g'>{player.subLevel}</span></p>
          </div>

          <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
            {section !== 4 &&
              <div>
                <div className='flex justify-between'>
                  <h2>Gamertags</h2>
                  <Button classes='t-4' click={() => setSection(4)}>Edit</Button>
                </div>
                <div>
                  {player?.gamertags && Object.keys(player.gamertags).map(renderGamertags)}
                </div>
              </div>}
              {section === 4 &&
                <GamertagsForm
                  username={player.username}
                  gamertags={player.gamertags}
                  playerType={player.player_type}
                  onDone={onDoneH}
                  onCancel={onCancelH} />}
            </div>

            <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
              {section !== 5 &&
                <div>
                  <div className='flex justify-between'>
                    <h2>Social Links</h2>
                    <Button classes='t-4' click={() => setSection(5)}>Edit</Button>
                  </div>
                  <div>
                    {player?.links && Object.keys(player.links).map(renderSocialLinks)}
                  </div>
                </div>}
                {section === 5&&
                  <SocialLinksForm
                    username={player.username}
                    socialLinks={player.links}
                    onDone={onDoneH}
                    onCancel={onCancelH} />}
              </div>

              <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
                {section !== 9 &&
                <div>
                  <div className='flex justify-between'>
                    <h2>Change Password</h2>
                    <Button classes='t-4' click={() => setSection(9)}>Edit</Button>
                  </div>
                </div>}
                {section === 9 &&
                  <PasswordChange
                    username={player.username}
                    onDone={onDoneH}
                    onCancel={onCancelH} />}
              </div>

              {playerInfo.username === 'Arcane' && <div className='mb-6 py-2 px-4 radius1 b-4 bg-prgr'>
                <AdminRunSummonerCheck />
              </div>}

        </div>





      </div>
    </>
  )
}

export default Settings;
