import BackDrop from '../../modals/Backdrop';


const VideoUploadSubmitModal = ({ onClose = undefined }) => {
  return (
    <BackDrop onClose={onClose}>
      <div className='b-1 bg-7 radius1 p-10 text-center'>
        <h1 className='fs-30 ft1-med'>Your video is uploading</h1>
        <p className=''>This could take some time. Don't navigate away from this page.</p>
        <div className='relative mt-8'>
          <div className='spinner'></div>
        </div>
      </div>
    </BackDrop>
  )
}

export default VideoUploadSubmitModal;
