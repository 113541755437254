import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FormContainer from '../../components/layout/FormContainer';
import useApiPublic from '../../hooks/useApiPublic';
import logoImg from '../../assets/imgs/logos/nav-logo.png';
import Button from '../../components/layout/buttons/Buttons';
import { userActions } from '../../store/user';


const ForgotPassword = () => {
  const { token } = useParams(); 
  const { apiPostPublic, result, error, status } = useApiPublic();
  const { apiPostPublic: apiPostPublic2, result: result2, error: error2 } = useApiPublic();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [formState, setFormState] = useState(2);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setFormState(4);
    }
  }, [token]);

  useEffect(() => {
    if (result?.sent) {
      setFormState(3);
    } else if (status === 400) {
      setErrors(error.data)
    }
  }, [status, result, error])

  useEffect(() => {
    if (result2?.updated) {
      dispatch(userActions.logout());
      setFormState(5);
    }
  }, [dispatch, result2]);

  const emailChangeH = (e) => {
    setEmail(e.target.value);
  }

  const passwordChangeH = (e) => {
    setPassword(e.target.value);
  }

  const password2ChangeH = (e) => {
    setPassword2(e.target.value);
  }

  const renderErrors = (e, index) => {
    return (
      <li key={index}><span className='t-r'>* </span>{e.message}</li>
    )
  }

  const submit = (e) => {
    e.preventDefault();

    const data = { email };
    apiPostPublic('/api/auth/forgot-password/', data);
  }

  const submit2 = (e) => {
    e.preventDefault();

    const data = { password, password2, token };
    apiPostPublic2('/api/auth/forgot-password/reset', data, {});
  }

  return (
    <FormContainer>
      <img
        className=''
        src={logoImg}
        alt='Logo' />

      <div className='mt-14 w-85/100 mx-auto'>
      {formState === 2 &&
          <form onSubmit={submit}>
            {errors.length > 0 &&
            <div className='mb-3 t-w'>
              <ul>
                {errors.map(renderErrors)}
              </ul>
            </div>}

            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
              <label className='fs-13 t-5'>Email</label>
              <input
                id='email'
                className='auth w-full p-0 t-4 fs-15'
                type='email'
                autoComplete='off'
                value={email}
                onChange={emailChangeH} />
            </div>

            <div className='flex justify-center'>
              <Button
                classes='btn py-4 px-6 bg-5 t-3 ft1-med grow-1 radius2'
                type='submit'>Send Verification link</Button>
            </div>
          </form>}

        {formState === 3 &&
          <div className='text-center'>
            <p>Check your email for a link to reset your password</p>
          </div>}

        {formState === 4 &&
          <form onSubmit={submit2}>
            {error2?.data.length > 0 &&
              <div className='mb-3'>
                <ul>
                  {error2.data.map(renderErrors)}
                </ul>
              </div>}
              {error2?.data &&
              <div className='mb-3'>
                <ul>
                  <li className='t-r'>* {error2.data.message}</li>
                </ul>
              </div>}

            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
              <label className='fs-13 t-5'>New Password</label>
              <input
                id='password'
                className='auth w-full p-0 t-4 fs-15'
                type='password'
                value={password}
                autoComplete='off'
                onChange={passwordChangeH} />
            </div>

            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-10 leading-none'>
              <label className='fs-13 t-5'>New Password confirm</label>
              <input
                id='password2'
                className='auth w-full p-0 t-4 fs-15'
                type='password'
                value={password2}
                autoComplete='off'
                onChange={password2ChangeH} />
            </div>

            <div className='flex justify-center'>
              <Button
                classes='btn py-4 px-4 bg-5 t-3 ft1-med w-50/100 radius2'
                type='submit'>Continue</Button>
            </div>
          </form>}

          {formState === 5 &&
            <div className='text-center'>
              <p>
                Password reset successful&nbsp;
                <Link className='ft1-bld underline' to='/login'>Login</Link>
              </p>
            </div>}

      </div>
    </FormContainer>
  )
}

export default ForgotPassword;
