import { useEffect, useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';

import useApiPrivate from '../../../hooks/useApiPrivate';
import PageContainer from '../../layout/PageContainer';
import HeaderPrivateC from './HeaderPrivateC';
import ActionsPrivateC from './ActionsPrivateC';
import NavPrivateC from './NavPrivateC';
import HomePrivateC from './HomePrivateC';
import FollowersPrivateC from './FollowersPrivateC';
import FollowingPrivateC from './FollowingPrivateC';
import SettingsC from './SettingsC';
import MatchesPrivateC from './MatchesPrivateC';
import Chat from '../../chat/Chat';
import PlayerProfileSubmissions from './PlayerProfilesSubmissions';


const BasePrivateC = ({ slug }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [coach, setCoach] = useState({});

  useEffect(() => {
    if (result?.coach) {
      setCoach(result.coach);
    }
  }, [result]);

  const getCoach = useCallback(() => {
    apiGetPrivate(`/api/coaches/${slug}/`);
  }, [apiGetPrivate, slug]);

  useEffect(() => {
    getCoach();
  }, [getCoach]);

  const coachInfoChangeH = useCallback(() => {
    getCoach();
  }, [getCoach]);

  return (
    <>
      <PageContainer>
        <HeaderPrivateC coach={coach} />

        <div className='mt-10 mb-6 flex justify-between'>
          <ActionsPrivateC />
          <NavPrivateC />
        </div>

      </PageContainer>

      <Routes>
        <Route
          path=''
          element={<HomePrivateC coach={coach} />} />
        <Route
          path='/followers'
          element={<FollowersPrivateC coach={coach} />} />
        <Route
          path='/following'
          element={<FollowingPrivateC coach={coach} />} />
        <Route
          path='/player-profile-submissions'
          element={<PlayerProfileSubmissions coach={coach} />} />
        <Route
          path='/matches'
          element={<MatchesPrivateC coach={coach} />} />
        <Route
          path='/settings'
          element={<SettingsC coachInfo={coach} onCoachUpdate={coachInfoChangeH} />} />
        <Route
          path='/messages'
          element={<Chat />} />
      </Routes>
    </>
  )

}

export default BasePrivateC;
