import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useApiPublic from './useApiPublic';
import { userActions } from '../store/user';


const useRefreshAccess = () => {
  const { apiGetPublic, result, status } = useApiPublic()
  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.accessToken) {
      dispatch(userActions.refreshAccessToken({ token: result.accessToken }));
    }
  }, [dispatch, result]);

  const refreshToken = useCallback(async() => {
    apiGetPublic('/api/auth/refresh/', { withCredentials: true });  
  }, [apiGetPublic]);

  return {
    refreshToken,
    result,
    status
  }
}

export default useRefreshAccess;
