import ProfileImg from '../../../players/ProfileImg';


const FormatCard = ({ player, team, onEntrantAdd }) => {
// onEntrantAdd will be === undefined if player is already in match

  return (
    <>
      {player?.user_id !== undefined &&
        <div className='px-8 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48'>
          <div className='shrink-0'>
            <div className='img-container rounded-full b4px-w w-full mb-4'>
              <ProfileImg
                userId={player.id}
                imgName={player.img_1}
                classes='rounded-full' />
              </div>
            <p className='text-center fs-14 mb-2'>{player.username}</p>
            <hr className='mx-4'></hr>
          </div>
        </div>}

      {player?.user_id === undefined &&
        <div
          onClick={() => {
            if (onEntrantAdd === undefined) {
              return;
            } else {
              onEntrantAdd(team);
            }
          }}
          className={`px-8 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48 ${onEntrantAdd ? 'cursor-pointer' : ''}`}>
          <div className='shrink-0'>
            <p className='text-center fs-14 mb-2'>Add player</p>
            <hr className='mx-4'></hr>
          </div>
        </div>}
    </>
  )
}

export default FormatCard;
