import { useState, useEffect, useCallback } from 'react';

import useApiPrivate from '../../../hooks/useApiPrivate';
import Button from '../../layout/buttons/Buttons';
import PlayerProfileSubmitCard from './PlayerProfileSubmitCard';


const PlayerProfileSubmissions = ({ coach }) => {
  const { apiGetPrivate, error } = useApiPrivate();
  const [page, setPage] = useState(0);
  const [perPage] = useState(5);
  const [moreUsers, setMoreUsers] = useState(true);
  const [users, setUsers] = useState([]);

  const usersSet = useCallback((result) => {
    if (result?.users.length === 0) {
      setMoreUsers(false);
    } else {
      setUsers(previous => [...previous, ...result.users]);
      if (result?.users.length < perPage) {
        setMoreUsers(false);
      }
    }
  }, [perPage]);

  const getPlayers = useCallback((page, perPage) => {
    const params = { page, perPage };

    if (coach?.id) {
      apiGetPrivate(
        `/api/player-coach-sends/sends/${coach.id}/`,
        { params: params},
        usersSet);
      }
  }, [apiGetPrivate, usersSet, coach.id]);
  

  useEffect(() => {
      getPlayers(0, perPage);
      setPage(1);
  }, [getPlayers, perPage]);

  const getMorePlayers = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getPlayers(page, perPage)
  }

  const renderPlayers = (player, index) => {
    return (
      <PlayerProfileSubmitCard
        key={index}
        player={player} />
    )
  }

  return (
    <div className=''>

      <div className='grid grid-cols-2 md:grid-cols-5 gap-8 mb-10'>
        {users.length > 0 && users.map(renderPlayers)}
      </div>

      {moreUsers && error === undefined &&
        <div>
          <Button
            classes='btn-blk b-pr py-3 px-2.5 radius1 bg-pr t-w'
            click={getMorePlayers}>Get More</Button>
        </div>}
    </div>
  )
}

export default PlayerProfileSubmissions;
