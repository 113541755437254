import { useEffect, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import FormatDetails from './FormatDetails';


const MatchDetails = ({ matchId }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [match, setMatch] = useState();
  const [entrants, setEntrants] = useState([]);

  useEffect(() => {
    if (result?.match) {
      setMatch(result.match);
      setEntrants(result.match.entrants);
    }
  }, [result]);


  useEffect(() => {
    apiGetPrivate(`/api/matches/for-player/${matchId}`)
  }, [apiGetPrivate, matchId]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  return (
    <div className='my-8' onClick={stopPropagation}>
      {match?.id &&
        <FormatDetails
          matchId={match.id}
          gameId={match.game_id}
          formatId={match.format_id}
          matchStatus={match.status}
          entrants={entrants} />}
    </div>
  )
}

export default MatchDetails;
