const LoadingSpinner = ({ size }) => {
  return (
    <div
      className={`flex justify-center mx-auto`}
      style={{ width: size, height: size}}>
      <span className='self-center spinner'></span>
    </div>
  )
}

export default LoadingSpinner;
