import StatsPrivate from "./StatsPrivate";


const HomePrivate = ({ player }) => {
  return (
    <div>
      <StatsPrivate playerId={player.id} />
    </div>
  )
}

export default HomePrivate;
