import './Buttons.css';


const Button = ({
    children,
    classes,
    click = undefined,
    disabled }) => {

  return (
    <button
      onClick={click}
      className={classes}
      disabled={disabled}>
      {children}
    </button>
  )
}

export default Button;
