import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../hooks/useApiPrivate';


const LikeClip = ({ clipId, liked, onLiked, onUnliked }) => {
  const { apiPostPrivate, apiDeletePrivate, result } = useApiPrivate();
  const [isLiked, setIsLiked] = useState();

  useEffect(() => {
    setIsLiked(liked);
  }, [liked]);

  useEffect(() => {
    if (result?.liked === true) {
      setIsLiked(true);
      onLiked();
    } else if (result?.unLiked === true) {
      setIsLiked(false);
      onUnliked();
    }
  }, [onLiked, onUnliked, result]);

  const likeH = useCallback((e) => {
    e.stopPropagation();

    const data = { clipId: clipId };
    apiPostPrivate(`/api/likes/clips/${clipId}/`, data);
  }, [apiPostPrivate, clipId]);

  const unLikeH = useCallback((e) => {
    e.stopPropagation();

    const data = { clipId: clipId };
    apiDeletePrivate(`/api/likes/clips/${clipId}`, data);
  }, [apiDeletePrivate, clipId]);

  return (
    <>
      {!isLiked &&
        <p className='cursor-pointer' onClick={likeH}>Like</p>}

      {isLiked &&
        <p className='cursor-pointer' onClick={unLikeH}>Liked</p>}
    </>
  )
}

export default LikeClip;
