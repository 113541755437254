import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BackDrop from '../../../layout/modals/Backdrop';
import useApiPrivate from '../../../../hooks/useApiPrivate';


const CreateMatchModal = ({ onClose, onCreated }) => {
  const { apiPostPrivate, result: postResult } = useApiPrivate();
  const [game, setGame] = useState(0);
  const [format, setFormat] = useState(0);
  const [formats, setFormats] = useState([]);
  const userStateCoachId = useSelector(state => state.user.user.id);

  useEffect(() => {
    if (game !== 0) {
      setFormats([
        { id: 1, name:  '5v5' },
        { id: 2, name: '3v3' },
        { id: 3, name: '1v1' }
      ]);
    }
  }, [game]);

  useEffect(() => {
    if (postResult?.match) {
      onClose();
      if (onCreated !== undefined) {
        onCreated(postResult.match);
      }
    }
  }, [postResult, onClose, onCreated]);
  


  const shubmitH = (e) => {
    e.preventDefault();

    const data = {
      formatId: format,
      gameId: game,
      coachId: userStateCoachId
    };

    apiPostPrivate('/api/matches/create', data);
  }

  const gameChangeH = (e) => {
    setGame(e.target.value);
  }

  const formatChangeH = (e) => {
    setFormat(e.target.value);
  }

  const renderFromats = (format, index) => {
    return (
      <option key={index} value={format.id}>{format.name}</option>
    )
  }

  return (
    <BackDrop onClose={onClose}>
      <form onSubmit={shubmitH}>
        <div className='b-1 radius1 px-10 pt-10 pb-10 bg-7 min-w-[30%]'>
          <h1 className='fs-20 mb-4'>Create new match</h1>

          <div className='mb-6'>
            <label className='t-5 mb-2 block'>Select game</label>
            <div className='w-full b-4 radius2 py-2 px-8 bg-prgr leading-none'>
              <select
                id='game'
                className='auth w-full p-0 t-4 fs-15'
                value={game}
                onChange={gameChangeH}
                onFocus={gameChangeH}
                onBlur={gameChangeH} >

                <option value='0'>Select Game</option>
                <option value='1'>League of Legends</option>
              </select>
            </div>
          </div>

          <div>
            <label className='t-5 mb-2 block'>Select format</label>
            <div className='w-full b-4 radius2 py-2 px-8 bg-prgr leading-none'>
              <select
                id='format'
                className='auth w-full p-0 t-4 fs-15'
                value={format}
                onChange={formatChangeH}
                onFocus={formatChangeH}
                onBlur={formatChangeH} >

                <option value='0'>Select format</option>
                {formats.map(renderFromats)}
              </select>
            </div>
          </div>

          {/* <Format game='1' format={format} /> */}

          <div className='mt-8 text-center'>
            <button
              className='px-12 py-4 bg-5 t-3 ft1-med relative radius2'
              type='submit'>Create match</button>
          </div>
        </div>
      </form>
    </BackDrop>
  )
}

export default CreateMatchModal;
