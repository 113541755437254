import { statFormat, formatDateTime } from "../../utils/formatting";


const LolPlayerMatchCard = ({ match }) => {
  return (
    <div className='w-full fs-13 flex justify-start leading-tight bg-wgr b-4 px-4 py-2.5 mb-4 radius1'>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p className='t-1 fs-12 mt-1'>{match.matchId}</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.kda, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.kills, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.assists, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-5/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.deaths, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.totalDamageDealt, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.damagePerMin)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p className='t-1 fs-15 mt-1'>{statFormat(match.goldEarned, 0)}</p>
      </div>
      <div className='self-center t-4 mr-10 w-8/100'>
        <p className='t-1 fs-15 mt-1'>{match.position}</p>
      </div>
      <div className='self-center t-4 w-8/100'>
        <p className='t-1 fs-15 mt-1'>{formatDateTime(match.epochStart)}</p>
      </div>
    </div>
  )
}

export default LolPlayerMatchCard;
