import PageContainer from '../../components/layout/PageContainer';


const Privacy = () => {
  return (
    <PageContainer>
      <div className='privacy-container'>
        <h1 className='text-4xl sm:text-78px mb-12'>Privacy policy</h1>
        <p>At Ecruit.gg, accessible from ecruit.gg, one of our main priorities is our visitors' privacy. This Privacy Policy document contains types of information that are collected and recorded by Ecruit.gg and how we use it.</p>
        <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
        <p>This Privacy Policy applies only to our online activities. It is valid for visitors to our website regarding the information they shared and/or collect in Ecruit.gg. This policy does not apply to any information collected offline or via channels other than this website.</p>

        <h2>Consent</h2>
        <p>By using our website, you with this consent to our Privacy Policy and agree to its terms.</p>

        <h2>Information we collect</h2>
        <p>The personal information you are asked to provide and the reasons you are asked to ensure it will be made clear to you when we ask you to submit your personal information.</p>
        <p>If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
        <p>When you register for an Account, we may ask for your contact information, including name, company name, address, email address, and telephone number.</p>

        <h2>How we use your information</h2>
        <p>We use the information we collect in various ways, including to:</p>
        <ul className='list-disc list-outside ml-10 mb-5'>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, either directly or through one of our partners, including customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2>Log Files</h2>
        <p>Ecruit.gg follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information. The purpose of the data is to analyze trends, administer the site, track users' movement on the website, and gather demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Online Privacy Policy Generator.</p>

        <h2>Cookies and Web Beacons</h2>
        <p>Like any other website, Ecruit.gg uses 'cookies.' These cookies are used to store information, including visitors' preferences, and the pages on the website that the visitor accessed or visited. The data is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
        <p>For more general information on cookies, please read "What Are Cookies.</p>

        <h2>Advertising Partners Privacy Policies</h2>
        <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Ecruit.gg</p>
        <p>Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons used in their respective advertisements and links that appear on United in Gaming, which is sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or personalize the advertising content you see on websites you visit.</p>
        <p>Note that Ecruit.gg has no access to or control over these cookies used by third-party advertisers.</p>

        <h2>Third-Party Privacy Policies</h2>
        <p>Ecruit.gg's Privacy Policy does not apply to other advertisers or websites. Thus, we advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of specific options.</p> 
        <p>You can choose to disable cookies through your browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browser's respective websites.</p>

        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
        <p>Request that a business that collects a consumer's data disclose the categories and specific pieces of personal data that a company has collected about consumers.</p>
        <p>Request that a business deletes any personal data about the consumer that a company has collected.</p>
        <p>Request that a business that sells a consumer's data, not sell the consumer's data.</p>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

        <h2>GDPR Data Protection Rights</h2>
        <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
        <p>The right to access - You have the right to request copies of your data. We may charge you a small fee for this service.</p>
        <p>The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you think is incomplete.</p>
        <p>The right to erasure - You have the right to request that we erase your data under certain conditions.</p>
        <p>The right to restrict processing - You have the right to request that we limit your data under certain conditions.</p>
        <p>The right to object to processing - You have the right to object to our processing of your data under certain conditions.</p>
        <p>The right to data portability - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

        <h2>Children's Information</h2>
        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
        <p>Ecruit.gg does not knowingly collect any Personal Identifiable Information from children under the age of 13. Suppose you think that your child provided this kind of information on our website. In that case, we strongly encourage you to contact us immediately, and we will do our best to promptly remove such information from our records.</p>
      </div>
    </PageContainer>
  )
}

export default Privacy;
