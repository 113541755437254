import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BackDrop from '../../../layout/modals/Backdrop';
import useApiPrivate from '../../../../hooks/useApiPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import CreateMatchModal from './CreateMatchModal';
import { formatDateTime } from '../../../../utils/formatting';
import InvitePlacementModal from './InvitePlacementModal';


const InviteMatchListModal = ({ playerId, onAdded = undefined, classes }) => {
  const { apiGetPrivate, result: getResult } = useApiPrivate();
  const [showMatchesModal, setShowMatchesModal] = useState(false);
  const [showPlacementModal, setShowPlacementModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [matches, setMatches] = useState();
  const [selectedMatchId, setSelectedMatchId] = useState();
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);
  const userStateAccountType = useSelector(state => state.user.user.accountType);

  const getMatches = () => {
    apiGetPrivate('/api/matches/by-coach', { params: { filter: 'invitelist' }});
  }

  const playerPlacementH = (matchId) => {
    setSelectedMatchId(matchId);
    setShowPlacementModal(true);
  }

  const showMatchesModalH = () => {
    getMatches();
    setShowMatchesModal(true);
  }

  const closeMatchesModalH = () => {
    setShowMatchesModal(false);
  }

  const showCreateModalH = () => {  
    setShowMatchesModal(false);
    setShowCreateModal(true);
  }

  const closeCreateModalH = () => {
    setShowCreateModal(false);
  }

  useEffect(() => {
    if (getResult?.matches) {
      setMatches(getResult.matches);
    }
  }, [getResult]);

  const renderMatches = (match, index) => {
    return (
      <div
        key={index}
        className='b-1 radius1 px-3 py-1 mb-3 cursor-pointer flex'
        onClick={() => playerPlacementH(match.id)} >
        <p className='mr-6'>Match ID: {match.id}</p>
        <p className='mr-6'>Game: {match.game_id}</p>
        <p className='mr-6'>Format: {match.format_id}</p>
        <p className=''>created: {formatDateTime(match.created)}</p>
      </div>
    )
  }

  return (
    <>
      {userStateIsLoggedIn && userStateAccountType === 2 &&
        <div className={classes}>
          <FontAwesomeIcon icon={faGamepad} className='mr-3' />
          <button onClick={showMatchesModalH}>
            Match
          </button>
        </div>}

      {showMatchesModal &&
        <BackDrop onClose={closeMatchesModalH}>
          <div className='p-6 b-1 bg-bk radius1'>
            <h1 className='ft-1-med fs-20 mb-2'>Your matches</h1>
            {matches && matches.map(renderMatches)}
            {matches && matches.length === 0 &&
              <p className=''>Your currently have no created matches</p>}

            <button
              className='mt-6 btn w-full py-2 bg-5 t-3 ft1-med radius2'
              onClick={showCreateModalH} >Create match</button>
          </div>
        </BackDrop>}

      {showPlacementModal &&
        <InvitePlacementModal
          matchId={selectedMatchId}
          playerId={playerId}
          onClose={() => setShowPlacementModal(false)} />}

      {showCreateModal &&
        <CreateMatchModal onClose={closeCreateModalH} />}
    </>
  )
}

export default InviteMatchListModal;
