import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Talk from 'talkjs';
import { getImgUrl } from '../utils/formatting';


const useTalkjs = () => {
  const [talkLoaded, setTalkLoaded] = useState(false);
  const [talkSession, setTalkSession] = useState();
  const [unreads, setUnreads] = useState();
  const userState = useSelector(state => state.user.user);
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);

  const unreadsH = useCallback((notifier) => {
    setUnreads(notifier.length);
  }, [])

  useEffect(() => {
    Talk.ready.then(() => setTalkLoaded(true));
  }, []);

  useEffect(() => {
    if (talkLoaded && userStateIsLoggedIn && userState.verified) {
      try {
        const { id, username, img } = JSON.parse(localStorage.getItem('u'));

        const env = process.env.REACT_APP_ENVIRONMENT;

        const user = new Talk.User({
          id: (env === 'LOCAL' || env === 'DEV') ? env + '-' + id : id,
          name: username,
          photoUrl: getImgUrl(img),
          role: 'default'
        });

        const session = new Talk.Session({
          appId: process.env.REACT_APP_TALK_APP_ID,
          me: user
        });
  
        session.unreads.onChange(unreadsH)
  
        setTalkSession(session);
  
        return () => session.destroy();
      } catch {
        return;
      }
    }

  }, [unreadsH, talkLoaded, userStateIsLoggedIn, userState.verified]);

  const createInbox = useCallback((inboxRef) => {
    if (talkLoaded && talkSession) {
      const inbox = talkSession.createInbox();
      inbox.mount(inboxRef);
    }
  }, [talkLoaded, talkSession]);

  const createInboxWithOther = useCallback((inboxRef, otherId, otherUsername, otherImg) => {
    if (talkLoaded && talkSession) {
      const { id, username, img } = JSON.parse(localStorage.getItem('u'));
      const env = process.env.REACT_APP_ENVIRONMENT;
      const user = new Talk.User({
        id: (env === 'LOCAL' || env === 'DEV') ? env + '-' + id : id,
        name: username,
        photoUrl: getImgUrl(img),
        role: 'default'
      });

      const otherUser = new Talk.User({
        id: (env === 'LOCAL' || env === 'DEV') ? env + '-' + otherId : otherId,
        name: otherUsername,
        photoUrl: getImgUrl(otherImg),
        role: 'default'
      });

      const conversationId = Talk.oneOnOneId(user, otherUser);
      const conversation = talkSession.getOrCreateConversation(conversationId);
      conversation.setParticipant(user);
      conversation.setParticipant(otherUser);
      const inbox = talkSession.createInbox({
        selected: conversation
      });
      inbox.mount(inboxRef);
    }
  }, [talkLoaded, talkSession]);

  const initTalk = useCallback((user) => {
    console.log('Init talk session')

  }, []);

  const destroySession = useCallback(() => {
    console.log('Destroying session')

    talkSession.destroy();
  }, [talkSession]);

  return {
    initTalk,
    destroySession,
    talkLoaded,
    unreads,
    talkSession,
    createInbox,
    createInboxWithOther
  }
}

export default useTalkjs;
