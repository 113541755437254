import { useEffect, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import BackDrop from '../../../layout/modals/Backdrop';


const MovePlayerToDiffBucket = ({
    currentBucketId, playerId,
    children, onMoved = undefined }) => {

  const { apiPutPrivate, result: putResult } = useApiPrivate();
  const { apiGetPrivate, result: getResult } = useApiPrivate();
  const [showModal, setShowModal] = useState(false);
  const [buckets, setBuckets] = useState([]);

  const movePlayer = (newBucketId) => {
    const data = {
      currentBucketId: currentBucketId,
      newBucketId: newBucketId,
      playerId: playerId
    };
    apiPutPrivate(`/api/player-buckets/move-player/${playerId}/`,  data );
  }

  const getCoachBuckets = () => {
    const data = { playerId }
    apiGetPrivate(`/api/player-buckets/player-not-in/`, { params: data });
  }

  const showModalH = () => {
    getCoachBuckets();
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if (putResult?.moved) {
      setShowModal(false);
      onMoved();
    }
  }, [onMoved, putResult]);

  useEffect(() => {
    if (getResult?.buckets) {
      setBuckets(getResult.buckets);
    }
  }, [getResult]);

  const renderBuckets = (bucket, index) => {  
    return (
      <div
        key={index}
        className='b-1 radius1 px-3 py-1 mb-3 cursor-pointer'
        onClick={() => movePlayer(bucket.id)}>
        <h1 className='t-w'>{bucket.title}</h1>
      </div>
    )
  }
  return (
    <>
      <span onClick={showModalH}>{children}</span>

      {showModal &&
        <BackDrop onClose={closeModal}>
          <div className='w-40/100 p-6 b-1 bg-bk radius1'>
            {buckets.map(renderBuckets)}
          </div>
        </BackDrop>}
      </>
  )
}

export default MovePlayerToDiffBucket;
