import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import useRefreshAccess from './apiRefreshAccess';


const useMediaPrivate = () => {
  const [result, setResult] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.user.token);
  const { refreshToken } = useRefreshAccess();

  const mediaGetPrivate = useCallback(async (url) => {
    if (token !== '') {
      setLoading(true);
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.get(process.env.REACT_APP_BASE_MEDIA_URL + url, {})
        .then((res) => {
          console.log('GET RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken])

  const mediaPostPrivate = useCallback(async (url, data, options) => {
    if (token !== '') {
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.post(process.env.REACT_APP_BASE_MEDIA_URL + url, data, options)
        .then((res) => {
          console.log('POST RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken])

  const mediaPutPrivate = useCallback(async (url, data, options) => {
    if (token !== '') {
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.put(process.env.REACT_APP_BASE_MEDIA_URL + url, data, options)
        .then((res) => {
          console.log('PUT RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken])

  const mediaDeletePrivate = useCallback(async (url, data, options) => {
    if (token !== '') {
      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        },
        withCredentials: true
      });

      axiosPrivate.delete(process.env.REACT_APP_BASE_MEDIA_URL + url, data, options)
        .then((res) => {
          console.log('DELETE RES PRIVATE: ', url, res.data);
          setResult(res.data);
          setStatus(res.status);
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response);
          setStatus(err.status);

          if (err.response.status === 401) {
            
            refreshToken();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refreshToken])



  return {
    mediaGetPrivate,
    mediaPostPrivate,
    mediaPutPrivate,
    mediaDeletePrivate,
    setStatus,
    status,
    result,
    error,
    loading
  }
}

export default useMediaPrivate;
