import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';

const NavPublic = () => {
  return (
    <div className=''>
      <NavLink
        to=''
        end
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faChartSimple} className='mr-3' />
        Stats
      </NavLink>

      <NavLink
        to='videos/gallery'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faPhotoFilm} className='mr-3' />
        Video Gallery
      </NavLink>
    </div>
  )
}

export default NavPublic;
