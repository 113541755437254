import { useEffect, useCallback } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';


const AcceptBtn = ({ matchId, classes, onAccept }) => {
  const { apiPutPrivate, result } = useApiPrivate();

  useEffect(() => {
    if (result?.accepted) {
      console.log('confirmed accepted')

      onAccept();
    }
  }, [onAccept, result]);

  const clickH = useCallback((e) => {
    e.stopPropagation();

    const data = { matchId };

    apiPutPrivate(`/api/matches/player-accept`, data)
  }, [apiPutPrivate, matchId]);

  return (
    <button
      className={classes}
      onClick={clickH} >Accept</button>
  )
}

export default AcceptBtn;
