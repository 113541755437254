import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../hooks/useApiPrivate';


const PlayerRegisterGamertagSection = ({ onDone, onBack}) => {
  const { apiPutPrivate, result } = useApiPrivate();
  const [lolTag, setLolTag] = useState('');
  const [showLol, setShowLol] = useState(false);
  const [rlTag, setRlTag] = useState('');
  const [showRlTag, setShowRlTag] = useState(false);
  const [ovTag, setOvTag] = useState('');
  const [showOvTag, setShowOvTag] = useState(false);
  const [formClean, setFormClean] = useState(false);

  useEffect(() => {
    if (lolTag.length > 0 || rlTag.length > 0 || ovTag.length > 0) {
      setFormClean(true);
    } else {
      setFormClean(false);
    }
  }, [lolTag, rlTag, ovTag]);

  const doneH = useCallback(() => {
    const data = {
      lolTag,
      rlTag,
      ovTag
    }
    onDone(data);
  }, [onDone, lolTag, rlTag, ovTag]);

  useEffect(() => {
    if (result?.gamertags) {
      doneH();
    }
  }, [doneH, result]);

  const submitH = (e) => {
    e.preventDefault();

    const newTags = {};
    if (lolTag !== '') newTags['lol'] = lolTag;
    if (rlTag !== '') newTags['rl'] = rlTag;
    if (ovTag !== '') newTags['ov'] = ovTag;

    const data = { gamertags: JSON.stringify(newTags) };

    apiPutPrivate('/api/auth/register-gamertags', data);
  }

  const lolChangeH = (e) => {
    setLolTag(e.target.value);
  }

  const rlChangeH = (e) => {
    setRlTag(e.target.value);
  }

  const ovChangeH = (e) => {
    setOvTag(e.target.value);
  }

  return (
    <div>
      <h1 className='mb-4 ml-4 fs-20'>Gametags</h1>
      <form onSubmit={submitH}>
        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='flex w-full md:w-48/100 b-4 radius2 py-5 px-8 bg-prgr leading-none'>
            <div
              onClick={() => setShowLol(previous => !previous)}
              className='self-center text-center btn w-full t-1 ft1-med relative radius2 cursor-pointer'>
              League of Legends</div>
          </div>
          {showLol &&
            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Gamertag</label>
              <input
                id='lol'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={lolTag}
                autoComplete='off'
                onChange={lolChangeH} />
            </div>}
        </div>

        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='flex w-full md:w-48/100 b-4 radius2 py-5 px-8 bg-prgr leading-none'>
            <div
              onClick={() => setShowRlTag(previous => !previous)}
              className='self-center text-center btn w-full t-1 ft1-med relative radius2 cursor-pointer'>
              Rocket League</div>
          </div>
          {showRlTag &&
            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Gamertag</label>
              <input
                id='rocketleage'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={rlTag}
                autoComplete='off'
                onChange={rlChangeH} />
            </div>}
        </div>

        <div className='mb-8 flex justify-between flex-wrap'>
          <div className='flex w-full md:w-48/100 b-4 radius2 py-5 px-8 bg-prgr leading-none'>
            <div
              onClick={() => setShowOvTag(previous => !previous)}
              className='self-center text-center btn w-full t-1 ft1-med relative radius2 cursor-pointer'>
              Overwatch</div>
          </div>
          {showOvTag &&
            <div className='w-full md:w-48/100 b-4 radius2 py-2.5 px-8 mt-8 md:mt-0 bg-prgr leading-none'>
              <label className='fs-13 t-5'>Gamertag</label>
              <input
                id='overwatch'
                className='auth w-full p-0 t-4 fs-15'
                type='text'
                value={ovTag}
                autoComplete='off'
                onChange={ovChangeH} />
            </div>}
        </div>


        <div className='mt-8 flex justify-between flex-wrap'>
          <button
              className='btn w-full md:w-48/100 py-4 bg-5 t-3 ft1-med relative radius2'>Skip</button>
          <button
            type='submit'
            disabled={!formClean}
            className='btn w-full md:w-48/100 py-4 bg-5 t-3 ft1-med relative radius2'>Next</button>
        </div>
      </form>
    </div>
  )
}

export default PlayerRegisterGamertagSection;
