import { createSlice } from '@reduxjs/toolkit';


const initUserState = {
  user: localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : {},
  token: '',
  loggedIn: false
}

const userSlice = createSlice({
  name: 'user',
  initialState: initUserState,
  reducers: {
    refreshAccessToken(state, action) {
      state.token = action.payload.token;
      state.loggedIn = true;
    },
    refreshUser(state, action) {
      const {
        id, username, img = '', accountType = 1, subLevel, verified
      } = action.payload.user;

      state.user.id = id;
      state.user.username = username;
      state.user.img = img;
      state.user.accountType = accountType;
      state.user.subLevel = subLevel;
      state.user.verified = verified;
      state.token = action.payload.token;
      state.loggedIn = true;
      localStorage.setItem('u', JSON.stringify(state.user));
    },
    login(state, action) {
      const {
        id, username, img = '', accountType = 1, subLevel, verified = false, token
      } = action.payload;

      state.user.id = id;
      state.user.username = username;
      state.user.img = img;
      state.user.accountType = accountType;
      state.user.subLevel = subLevel;
      state.user.verified = verified;
      state.token = token;
      state.loggedIn = true;
      localStorage.setItem('u', JSON.stringify(state.user));
    },
    logout(state) {
      state.user = {};
      state.token = '';
      state.loggedIn = false;
      localStorage.removeItem('u');
    },
    update(state, action) {
      const {
        username, img = '', accountType = 1, subLevel, verified, token
      } = action.payload;

      if (username) state.user.username = username;
      if (img) state.user.img = img;
      if (accountType) state.user.accountType = accountType;
      if (subLevel) state.user.subLevel = subLevel;
      if (verified !== undefined) state.user.verified = verified;
      if (token) state.token = token;
      state.loggedIn = true;
      localStorage.setItem('u', JSON.stringify(state.user));
    },
    register(state, action) {
      const {
        id, username, img = '', accountType = 1, verified = false, subLevel, token
      } = action.payload;

      state.user.id = id;
      state.user.username = username;
      state.user.img = img;
      state.user.accountType = accountType;
      state.user.subLevel = subLevel;
      state.user.verified = verified;
      state.token = token;
      state.loggedIn = true;
      localStorage.setItem('u', JSON.stringify(state.user));
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
