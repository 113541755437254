import { useEffect, useState, useCallback } from "react";

import PageContainer from "../../components/layout/PageContainer"
import useApiPublic from "../../hooks/useApiPublic";
import CoachesCoachCard from "../../components/coaches/CoachesCoachCard";
import Button from "../../components/layout/buttons/Buttons";


const CoachesPage = () => {
  const { apiGetPublic, error } = useApiPublic();
  const [page, setPage] = useState(0);
  const [perPage] = useState(5);
  const [moreUsers, setMoreUsers] = useState(true);
  const [users, setUsers] = useState([]);

  const usersSet = useCallback((result) => {
    if (result?.users.length === 0) {
      setMoreUsers(false);
    } else {
      setUsers(previous => [...previous, ...result.users]);
      if (result?.users.length < perPage) {
        setMoreUsers(false);
      }
    }
  }, [perPage]);

  const getCoaches = useCallback((page, perPage) => {
    const params = { page: page, perPage: perPage };

    apiGetPublic(
      '/api/coaches/',
      { params: params},
      usersSet);
  }, [apiGetPublic, usersSet]);

  useEffect(() => {
    getCoaches(0, perPage);
    setPage(1);
  }, [getCoaches, perPage]);

  const getMoreCoaches = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getCoaches(page, perPage)
  }

  const renderCoach = (coach, index) => {
    return (
      <CoachesCoachCard
        key={index}
        coach={coach} />
    )
  }

  return (
    <PageContainer>
      <div className='grid grid-cols-2 md:grid-cols-5 gap-8 mb-10'>
        {users.length > 0 && users.map(renderCoach)}
      </div>

      {moreUsers && error === undefined &&
        <div>
          <Button
            classes='btn-blk b-pr py-3 px-2.5 radius1 bg-pr t-w'
            click={getMoreCoaches}>Get More</Button>
        </div>}
    </PageContainer>
  )
}

export default CoachesPage;
