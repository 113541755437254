import { useEffect, useState, useRef } from 'react';

import useVideoUpload from '../../../../hooks/useVideoUpload';
import { GAMES } from '../../../../data/games';
import VideoUploadSubmitModal from './VideoUploadSubmitModal';


const VideoUpload = ({ player }) => {
  const { uploadVideo, result, loading } = useVideoUpload();
  const [videoFile, setVideoFile] = useState('');
  const [videoPreview, setVideoPreview] = useState();
  const videoPreviewRef = useRef();
  const [games,] = useState(GAMES);
  const [game, setGame] = useState();
  const [title, setTitle] = useState('');
  const [fileSizeError, setFileSizeError] = useState('');

  useEffect(() => {
    if (result === 'success') {
      setVideoFile('');
      setVideoPreview(undefined);
      setGame(undefined);
      setTitle('');
      videoPreviewRef.current.load();
    }
  }, [result]);

  useEffect(() => {
    videoPreviewRef.current?.load();
  }, [videoPreview]);

  const videoChangeH = (e) => {
    URL.revokeObjectURL(videoPreview)
    const file = e.target.files[0];
    if (file === undefined) {
      return;
    }
    if (file.size < 26214400) {
      setVideoPreview(URL.createObjectURL(file));
      setVideoFile(e.target.files[0]);
      setFileSizeError('');
    } else {
      setFileSizeError('File size was to big');
      e.target.value = null;
    }
    return () => URL.revokeObjectURL(videoPreview);
  }

  const gameChangeH = (e) => {
    setGame(e.target.value);
  }

  const titleChangeH = (e) => {
    setTitle(e.target.value);
  }


  const submitH = async (e) => {
    e.preventDefault();

    uploadVideo(videoFile, game, title);
  }

  // const renderGames = (game, index) => {
  //   return (
  //     <option key={index} value={game.name}>{game.name}</option>
  //   )
  // }

  return (
    <>
      <div className='mx-auto w-50/100'>
        <h1 className='pl-4 mb-4 fs-30 ft1-med'>Upload game clip</h1>

        <form onSubmit={submitH}>

          <div className='w-full b-4 radius2 py-2 px-8 mt-8 md:mt-0 bg-prgr leading-none mb-8'>
            <label className='fs-13 t-5'>Associated game</label>
            <select
              id='game'
              className='auth w-full p-0 t-4 fs-15'
              value={game}
              onChange={gameChangeH} >

              {game?.id && <option value={game.name}>{game.name}</option>}
              {!game?.id &&<option value=''>Select One</option>}
              {/* <option value='None'>None</option> */}
              <option value='1'>League of Legends</option>
              {/* {games.map(renderGames)} */}
            </select>
          </div>

          {fileSizeError &&<p className='ml-6 mb-1 t-r'>* {fileSizeError}</p>}
          <div className='b-4 w-full radius2 py-2.5 px-8 bg-prgr leading-none mb-8'>
            <label className='fs-13 t-5'>Video file * must be less that 25mb</label>
            <input
              id='videofile'
              className='-ml-0.5 auth w-full p-0 t-4 fs-15'
              type='file'
              accept='video/mp4, video/mpeg, video/quicktime, video/x-msvideo,'
              onChange={videoChangeH} />
          </div>

          {videoPreview &&
            <div className='b-4 w-full radius1 leading-none mb-8'>
              <video
                className='w-full radius1'
                controls
                ref={videoPreviewRef}>
                <source src={videoPreview} type='video/mp4' />
              </video>
            </div>}

          <div className='b-4 w-full radius2 py-2.5 px-8 bg-prgr leading-none mb-8'>
            <label className='fs-13 t-5'>Video clip title</label>
            <input
              id='name'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              value={title}
              autoComplete='off'
              onChange={titleChangeH} />
          </div>


          <div className='flex justify-center mt-12'>
            <button
              className={`btn w-50/100 py-4 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}
              type='submit'>
              <span className={`${loading ? 'invisible' : ''}`}>Submit</span>
            </button>
          </div>

        </form>

      </div>


      {loading && <VideoUploadSubmitModal />}
    </>
  )
}

export default VideoUpload;
