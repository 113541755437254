import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageContainer from '../../components/layout/PageContainer';
import BasePrivateC from '../../components/coaches/profilePrivate/BasePrivateC';
import BasePublicC from '../../components/coaches/profilePublic/BasePublicC';


const CoachPage = () => {
  const { slug } = useParams();
  const userState = useSelector(state => state.user.user);
  const loggedIn = useSelector(state => state.user.loggedIn);

  let coachComponent = '';
  if (loggedIn && slug === userState.username.toLowerCase()) {
    coachComponent = <BasePrivateC slug={slug} />;
  } else {
    coachComponent = <BasePublicC slug={slug} />;
  }

  return (
    <PageContainer>
      {coachComponent !== '' && coachComponent}
    </PageContainer>
  )
}

export default CoachPage;
