import { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';

import useApiPrivate from '../../../../hooks/useApiPrivate';
// import { userActions } from '../../../../store/user';
import FormLabelInput from '../FormLabelInput';
import FormInput from '../FormInput';
import Button from '../../buttons/Buttons';


const SocialLinksForm = ({ username, socialLinks, onDone, onCancel }) => {
  const { apiPutPrivate, status } = useApiPrivate();

  const [twitter, setTwitter] = useState({
    val: socialLinks.tt ? socialLinks.tt : '',
    origVal: socialLinks.tt ? socialLinks.tt : '',
    changed: false
  });
  const [twitch, setTwitch] = useState({
    val: socialLinks.tw ? socialLinks.tw : '',
    origVal: socialLinks.tw ? socialLinks.tw : '',
    changed: false
  });
  const [youTube, setYouTube] = useState({
    val: socialLinks.yt ? socialLinks.yt : '',
    origVal: socialLinks.yt ? socialLinks.yt : '',
    changed: false
  });
  const [tikTok, setTikTok] = useState({
    val: socialLinks.tk ? socialLinks.tk : '',
    origVal: socialLinks.tk ? socialLinks.tk : '',
    changed: false
  });
  const [instagram, setInstagram] = useState({
    val: socialLinks.ig ? socialLinks.ig : '',
    origVal: socialLinks.ig ? socialLinks.ig : '',
    changed: false
  });

  useEffect(() => {
    if (status === 200) {
      let l = {};
      if (twitter.val !== '') l['tt'] = twitter.val;
      if (twitch.val !== '') l['tw'] = twitch.val;
      if (youTube.val !== '') l['yt'] = youTube.val;
      if (tikTok.val !== '') l['tk'] = tikTok.val;
      if (instagram.val !== '') l['ig'] = instagram.val;
      onDone({ links: l });
    }
  }, [onDone, status, twitter, twitch, youTube, tikTok, instagram]);

  const twitterChange = (e) => {
    setTwitter({ ...twitter, val: e.target.value, changed: true });
  }
  const twitchChange = (e) => {
    setTwitch({ ...twitch, val: e.target.value, changed: true });
  }
  const youTubeChange = (e) => {
    setYouTube({ ...youTube, val: e.target.value, changed: true });
  }
  const tikTokChange = (e) => {
    setTikTok({ ...tikTok, val: e.target.value, changed: true });
  }
  const instagramChange = (e) => {
    setInstagram({ ...instagram, val: e.target.value, changed: true });
  }

  const submit = (e) => {
    e.preventDefault();

    let newLinks = {};
    if (twitter.val !== '') newLinks['tt'] = twitter.val;
    if (twitch.val !== '') newLinks['tw'] = twitch.val;
    if (youTube.val !== '') newLinks['yt'] = youTube.val;
    if (tikTok.val !== '') newLinks['tk'] = tikTok.val;
    if (instagram.val !== '') newLinks['ig'] = instagram.val;

    const data = { form: e.target.id, links: JSON.stringify(newLinks)};

    apiPutPrivate(`/api/players/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='links' onSubmit={submit}>
      <FormLabelInput
        label='Social Links'
        classes=''
        classes2='mb-2 b-bk'
        placeholder='Twitter'
        name='twitter'
        value={twitter.val}
        onChangH={twitterChange} />
      <FormInput
        classes='mb-2 b-bk'
        placeholder='Twitch'
        name='twitch'
        value={twitch.val}
        onChangH={twitchChange} />
      <FormInput
        classes='mb-2 b-bk'
        placeholder='YouTube'
        name='youtube'
        value={youTube.val}
        onChangH={youTubeChange} />
      <FormInput
        classes='mb-2 b-bk'
        placeholder='TikTok'
        name='tiktok'
        value={tikTok.val}
        onChangH={tikTokChange} />
      <FormInput
        classes='mb-2 b-bk'
        placeholder='Instagram'
        name='instagram'
        value={instagram.val}
        onChangH={instagramChange} />
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2 mr-3'
        type='submit'>Save</Button>
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2'
        click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default SocialLinksForm;
