import { useCallback } from 'react';

import ProfileImg from '../ProfileImg';
import { formatPlayerType } from '../../../utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import SocialMediaIcon from '../utils/SocialMediaIcon';


const PlayerModalViewHeader = ({ player, onSectionChange }) => {

  const sectionChangeH = useCallback((section) => {
    onSectionChange(section);
  }, [onSectionChange]);

  return (
    <div className='w-full px-5 pt-2 flex justify-between radius1 bg-217-3 relative min-h-max'>


      <div className='flex justify-start'>
        <div className='w-32 relative -bottom-5'>
        {player?.img_1 &&
            <div className='rounded-full mx-auto img-container'>
              <ProfileImg
                userId={player.id}
                imgName={player.img_1}
                classes='rounded-full b4px-w' />
          </div>}
        </div>

        <div className='ml-6 t-w mt-5 ft1 fs-14 leading-none'>
          <p className='fs-24 t-1 ft1-med mb-1'>{player.username}</p>
          <p className='t-4 mb-4'>{formatPlayerType(player.player_type)}</p>
          <p className='mb-1.5'>
            <FontAwesomeIcon icon={faGraduationCap} className='mr-1.5' />{player.username}</p>
          <p className=''>
            <FontAwesomeIcon icon={faLocationDot} className='ml-1 mr-2.5' />
            {player?.city_state?.city}, {player?.city_state?.state}</p>
        </div>

      </div>

      <div className='flex flex-col justify-center'>
        <div className='flex justify-center mb-5'>
        {player.links?.ig &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={player.links?.ig}
              icon='instagram' />}
          {player.links?.tw &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={player.links?.tw}
              icon='twitch' />}
          {player.links?.tt &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={player.links?.tt}
              icon='twitter' />}
          {player.links?.tk &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={player.links?.tk}
              icon='tiktok' />}
          {player.links?.yt &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={player.links?.yt}
              icon='youtube' />}
        </div>

        <div className='flex justify-center t-w leading-none'>
          <div
            className='w-30/100 cursor-pointer'
            onClick={() => sectionChangeH('followers')}>
            <p className='fs-14 mb-1.5'>Followers:</p>
            <p className='fs-24'>{player.follower_count}</p>
          </div>
          <div
            className='w-30/100 mx-4 cursor-pointer'
            onClick={() => sectionChangeH('following')}>
            <p className='fs-14 mb-1.5'>Following:</p>
            <p className='fs-24'>{player.following_count}</p>
          </div>
          <div
            className='w-30/100 cursor-pointer'
            onClick={() => {sectionChangeH('following-coaches')}} >
            <p className='fs-14 mb-1.5'>Coaches:</p>
            <p className='fs-24'>{player.coach_follower_count}</p>
          </div>
        </div>
      </div>


    </div>
  )
}

export default PlayerModalViewHeader;
