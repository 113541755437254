import { useEffect, useState } from 'react';

import FormatDetailsCard from './FormatDetailsCard';


const FormatDetails = ({ matchId, gameId, formatId, matchStatus, entrants }) => {
  const [teamSpots, setTeamSpots] = useState();
  const [teamOne, setTeamOne] = useState([]);
  const [teamTwo, setTeamTwo] = useState([]);

  useEffect(() => {
    let tempTeam1 = [];
    let tempTeam2 = [];
    let tempSpots = null;
    let readyPlayersCount = 0;

    if (gameId === '1') {
      if (formatId === 1) {
        tempSpots = 5;
      } else if (formatId === 2) {
        tempSpots = 3;
      } else if (formatId === 3) {
        tempSpots = 1;
      }
    }

    for (let e of entrants) {
      // if player status is accepted
      if (e.entrant_status === 2) {
        readyPlayersCount = readyPlayersCount + 1;
      }

      // entrant is either pending or accepted
      if (e.entrant_status === 1 || e.entrant_status === 2) {
        if (e.team === 1) {
          tempTeam1.push(e);
        } else if (e.team === 2) {
          tempTeam2.push(e);
        }
      }
    }

    if (tempTeam1.length < tempSpots) {
      tempTeam1[tempSpots - 1] = 0;
      tempTeam1.fill(0, tempTeam1.length, tempSpots);
    }

    if (tempTeam2.length < tempSpots) {
      tempTeam2[tempSpots - 1] = 0;
      tempTeam2.fill(0, tempTeam2.length, tempSpots);
    }

    setTeamOne([...tempTeam1]);
    setTeamTwo([...tempTeam2]);
    setTeamSpots(tempSpots);
  }, [entrants, gameId, formatId, matchStatus]);

  const renderTeam1 = (player, index) => {
    return (
      <FormatDetailsCard
        key={index}
        matchId={matchId}
        player={player} />
    )
  }

  const renderTeam2 = (player, index) => {
    return (
      <FormatDetailsCard
        key={index}
        matchId={matchId}
        player={player} />
    )
  }

  return (
    <div>
      <div className='flex justify-center'>
        {teamOne.map(renderTeam1)}
      </div>

      <div className='my-8 fs-32 ft1-med text-center'>VS</div>

      <div className='flex justify-center'>
        {teamTwo.map(renderTeam2)}
      </div>

    </div>
  )
}

export default FormatDetails;
