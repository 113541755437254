import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';

import useApiPrivate from '../../../../hooks/useApiPrivate';
// import { userActions } from '../../../../store/user';
import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';
import Button from '../../buttons/Buttons';


const PlayerTypeForm = ({ username, playerType, gamertags, onDone, onCancel }) => {
  const { apiPutPrivate, result, status } = useApiPrivate();

  const [pType, setPType] = useState(playerType);

  useEffect(() => {
    if (status === 200) {
      onDone({ player_type: pType });
    }
  }, [onDone, status, result, pType]);

  const pTypeChange = (e) => {
    setPType(parseInt(e.target.value));
  }

  const submit = (e) => {
    e.preventDefault();
    
    const jsonGamertags = JSON.stringify(gamertags);

    const data = {
      form: e.target.id,
      playerType: parseInt(pType),
      gamertags: jsonGamertags === '{}' ? false : jsonGamertags
    };

    apiPutPrivate(`/api/players/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='playertype' onSubmit={submit}>
      <FormLabel htmlFor='ptype' label='Player Type' />
      <FormSelect
        id='ptype'
        classes='mb-2 b-bk block'
        placeholder='Player type'
        onChangeH={pTypeChange}
        value={pType} >

        <option value='1'>Amateur</option>
        <option value='2'>High School</option>
        <option value='3'>Collegiate</option>
        <option value='4'>Semi Pro</option>
        <option value='5'>Pro</option>

      </FormSelect>

      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2 mr-3'
        type='submit'>Save</Button>
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2'
        click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default PlayerTypeForm;
