import { Link } from 'react-router-dom';

import ProfileImg from '../players/ProfileImg';
import SocialLinks from '../players/utils/SocialLinks';


const CoachesCoachCard = ({ coach }) => {
  return (
    <div className='px-8 py-14 radius1 b-4 border-box bg-wgr'>
      <div className='b4px-w w-60/100 rounded-full mx-auto img-container'>
        <Link to={`/coaches/${coach.slug}/`}>
          <ProfileImg
            userId={coach.id}
            imgName={coach.img_1}
            classes='rounded-full' />
        </Link>
      </div>
      <div className='mt-2.5 text-center leading-none'>
        <p className='ft1-med t-1 fs-20'>{coach.username}</p>
        <hr className='my-2.5'></hr>
        <div>
          <SocialLinks
            links={coach.links}
            ctnClasses='flex justify-center'
            iconClasses='mx-2 fs-24 t-1' />
        </div>
      </div>
    </div>
  )
}

export default CoachesCoachCard;
