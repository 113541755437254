import { useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FormContainer from '../../components/layout/FormContainer';
import useApiPublic from '../../hooks/useApiPublic';
import { userActions } from '../../store/user';
import logoImg from '../../assets/imgs/logos/nav-logo.png';


const LoginPage = () => {
  const { apiPostPublic, result, error, loading } = useApiPublic();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usernameRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    if (result) {
      dispatch(userActions.login(
        {
          ...result.user,
          token: result.accessToken
        }
      ));

      const slug = result.user.username.toLowerCase(); 
      navigate(`/${result.user.accountType === 1 ? 'players' : 'coaches'}/${slug}/`);
    }
  }, [dispatch, navigate, result]);

  const submitH = (e) => {
    e.preventDefault();

    const data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    }

    apiPostPublic('/api/auth/login/', data, { withCredentials: true });
  }

  const usernameCheckH = () => {
    // console.log('username check');
  }

  const passwordCheckH = () => {
    // console.log('password check');
  }

  return (
    <FormContainer>
      <img
        className=''
        src={logoImg}
        alt='Logo' />

      <div className='mt-14 w-85/100 mx-auto'>
        <h1 className='fs-32 ft1-med mb-6'>Login</h1>

        {error &&
        <div className='mb-3 t-r'>
          <p>{error.data.message}</p>
        </div>}

        <form onSubmit={submitH}>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Username</label>
            <input
              id='username'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              autoComplete='off'
              ref={usernameRef}
              onChange={usernameCheckH}
              onFocus={usernameCheckH}
              onBlur={usernameCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-2 leading-none'>
            <label className='fs-13 t-5'>Password</label>
            <input
              id='password'
              className='auth w-full p-0 t-4 fs-15'
              type='password'
              autoComplete='off'
              ref={passwordRef}
              onChange={passwordCheckH}
              onFocus={passwordCheckH}
              onBlur={passwordCheckH} />

          </div>

          <div className='mb-10'>
            <Link className='ml-4 mb-10 fs-14 ft1' to='/forgot-password'>Forgot password?</Link>
          </div>

          <div className='flex justify-center mb-12'>
            <button
              className={`btn w-50/100 py-4 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}
              type='submit'>
              <span className={`${loading ? 'invisible' : ''}`}>Login</span>
            </button>
          </div>

          <div className='text-center t-4 fs-16'>
            <p className='mb-4'>Don't have an account yet?</p>
            <Link className='mb-4 block underline ft1-med' to='/register'>Register as player</Link>
            <Link className='mb-4 block underline ft1-med' to='/register/coach'>Register as coach</Link>
          </div>

        </form>
      </div>
    </FormContainer>
  )
}

export default LoginPage;
