import { Link } from 'react-router-dom';

import logoImg from '../../assets/imgs/logos/nav-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';


const Footer = () => {
  return (
    <div className='footer-container leading-none t-4'>
      <div className='container h-full'>
        <div className='flex justify-between items-center footer-inner'>
          <div className='flex'>
            <Link
              to='/'
              className='flex self-center mr-6'>
              <img
                className='footer-logo'
                src={logoImg}
                alt='Logo' /></Link>

            <div className='flex items-center'>
              <FontAwesomeIcon icon={faCopyright} size='xs' />
              <span className='ml-1 fs-14'>2023</span>
            </div>
          </div>
          <div className='fs-14 ft1 t-4'>
            <Link className='mx-3' to='/games'>Games</Link>
            <Link className='mx-3' to='/about'>About</Link>
            <Link className='mx-3' to='/contact'>Contact</Link>
            <Link className='mx-3' to='/faq'>FAQ</Link>
            <Link className='mx-3' to='/privacy-policy'>Privacy</Link>
            <Link className='mx-3' to='/terms-and-conditions'>Terms of Use</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
