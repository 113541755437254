import { Link } from 'react-router-dom';

import ProfileImg from '../../players/ProfileImg';
import DateTimeFormat from '../../layout/DateTimeFormat';


const PlayerProfileSubmitCard = ({ player }) => {
  return (
    <div className='py-8 px-4 radius1 b-4 border-box bg-wgr'>
      <div className='b4px-w w-70/100 rounded-full mx-auto img-container'>
        <Link to={`/players/${player.slug}/`}>
          <ProfileImg
            userId={player.id}
            imgName={player.img_1}
            classes='rounded-full' />
        </Link>
      </div>
      <div className='mt-2 text-center'>
        <p className='mb-1'>{player.username}</p>
        <p className='fs-12 t-4'><DateTimeFormat dateTime={player.submitted} /></p>
        <hr className='my-2.5'></hr>
        <p>Stats</p>
      </div>
    </div>
  )
}

export default PlayerProfileSubmitCard;
