import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import useApiPrivate from '../../hooks/useApiPrivate';


const FeatureClipPrivate = ({ clipId, featured, size = 'fs-24' }) => {
  const { apiPutPrivate, result } = useApiPrivate();
  const [isFeatured, setIsFeatured] = useState(featured);

  useEffect(() => {
    if (featured !== undefined) {
      setIsFeatured(featured);
    }
  }, [featured]);

  useEffect(() => {
    if (result?.featured === true || result?.featured === false) {
      setIsFeatured(result.featured);
    }
  }, [result]);

  const featureH = (e) => {
    e.stopPropagation();

    const toFeature = isFeatured ? false : true;
    apiPutPrivate(`/api/clips/${clipId}/feature`, { toFeature, clipId });
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faStar}
        className={`${size} ${isFeatured ? 't-y' : 't-w'}`}
        onClick={featureH} />
    </>
  )
}

export default FeatureClipPrivate;
