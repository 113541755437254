import { useState } from 'react';

import Button from '../../layout/buttons/Buttons';
import { STATES } from '../../../data/states';
// import FormSelect from '../../layout/forms/FormSelect';

const FilterPlayers = ({ onFilter }) => {
  const [states] = useState(STATES);
  const [currentFilter, setCurrentFilter] = useState('');
  const [playerTypeFilter, setPlayerTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  // const [filter, setFilter] = useState('');

  const onFilterH = () => {
    let value = '';
    if (currentFilter === 'playertype') {
      value = playerTypeFilter;
    } else if (currentFilter === 'state') {
      value = stateFilter;
    }

    onFilter({ on: currentFilter, value: value });
  }

  const onStateFilterChangeH = (e) => {
    setStateFilter(e.target.value);
    setCurrentFilter('state');
    setPlayerTypeFilter('');
  }

  const onPlayerTypeChangeH = (e) => {
    setPlayerTypeFilter(parseInt(e.target.value));
    setCurrentFilter('playertype');
    setStateFilter('');
  }

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  return (
    <>
      <div className='w-full b-4 radius2 py-1.5 px-8 bg-prgr leading-none mr-4'>
        <label className='fs-13 t-5'>Player type</label>
        <select
          id='playertype'
          className='auth w-full p-0 t-4 fs-15'
          value={playerTypeFilter}
          onChange={onPlayerTypeChangeH} >

          <option value=''>Select one</option>
          <option value='1'>Amateur</option>
          <option value='2'>High School</option>
          <option value='3'>Collegiate</option>
          <option value='4'>Semi Pro</option>
          <option value='5'>Pro</option>

        </select>
      </div>

      <div className='w-full b-4 radius2 py-1.5 px-8 bg-prgr leading-none mr-4'>
        <label className='fs-13 t-5'>Players location</label>
        <select
          id='playertype'
          className='auth w-full p-0 t-4 fs-15'
          value={stateFilter}
          onChange={onStateFilterChangeH} >

          <option value=''>Select One</option>
          {states.map(renderStates)}

        </select>
      </div>

      <Button
        click={onFilterH}
        classes='py-1.5 px-6 bg-5 t-3 ft1-med radius2 mr-3'>Apply</Button>
    </>
  )
}

export default FilterPlayers;
