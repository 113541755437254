import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { userActions } from '../store/user';


const useApiRefreshAccessPromise = () => {
  const dispatch = useDispatch();

  const refreshToken = useCallback(async () => {
    return new Promise((resolve, reject) => {
      axios.get(
        process.env.REACT_APP_BASE_URL + '/api/auth/refresh/',
        { withCredentials: true })
        .then((res) => {
          dispatch(
            userActions.refreshAccessToken(
            { token: res.data.accessToken }
          ));
          resolve(res.data.accessToken);
        })
        .catch((err) => {
          dispatch(userActions.logout());
          reject(err.response);
        })

    });

  }, [dispatch]);

  return refreshToken
}

export default useApiRefreshAccessPromise;
