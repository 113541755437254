import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useApiPrivate from '../../hooks/useApiPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


const FollowCoachPlayer = ({ playerId, following = undefined, classes }) => {
  const { apiPostPrivate, apiDeletePrivate, result, status } = useApiPrivate();
  const [isFollowing, setIsFollowing] = useState(following);
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);
  const userStateAccountType = useSelector(state => state.user.user.accountType);

  useEffect(() => {
    setIsFollowing(following);
  }, [following]);

  useEffect(() => {
    if (result && status === 200) {
      setIsFollowing(previous => !previous);
    }
  }, [result, status]);

  const follow = useCallback(() => {
    const data = { userId: playerId };
    apiPostPrivate(`/api/coach-player-follows/follow/${playerId}/`, data);
  }, [apiPostPrivate, playerId]);

  const unFollow = useCallback(() => {
    const data = { userId: playerId };
    apiDeletePrivate(`/api/coach-player-follows/follow/${playerId}/`, { data: data });
  }, [apiDeletePrivate, playerId]);

  let action = undefined;
  if (!isFollowing) {
    action = follow;
  } else {
    action = unFollow;
  }

  return (
    <>
      {userStateIsLoggedIn &&
      userStateAccountType === 2 &&
      isFollowing !== undefined &&
        <div className={classes}>
          <FontAwesomeIcon icon={faCheck} className='mr-3' />
          <button onClick={action}>
            {isFollowing ? 'Unfollow' : 'Follow'}
          </button>
        </div>}
    </>
  )
}

export default FollowCoachPlayer;
