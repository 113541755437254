import { useParams } from 'react-router-dom';

import PageContainer from "../../components/layout/PageContainer";


const CollegePage = () => {
  const { id } = useParams();

  return (
    <PageContainer>
      <div>
        <h1>College id: {id} page</h1>
      </div>
    </PageContainer>
  )
}

export default CollegePage;
