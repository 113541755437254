import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import useApiPublic from '../../../../hooks/useApiPublic';
import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';
import Button from '../../buttons/Buttons';
import { STATES } from '../../../../data/states';


const LocationForm = ({ username, cityState, onDone, onCancel }) => {
    const { apiGetPublic, result: pubResult } = useApiPublic();
    const { apiPutPrivate, status: priStatus } = useApiPrivate();
    const [states] = useState(STATES);
    const [cities, setCities] = useState([]);
    const [state, setState] = useState();
    const [city, setCity] = useState();

    useEffect(() => {
      if (priStatus === 200) {
        console.log(city)
        onDone(city);
      }
    }, [onDone, city, priStatus]);

    useEffect(() => {
      console.log(pubResult)
      if (pubResult?.cities) {
        setCities(pubResult.cities);
      }
    }, [pubResult]);

    useEffect(() => {
      if (state) {
        apiGetPublic('/api/locations/cities/', { params: { state_abr: state }});
      }
    }, [apiGetPublic, state]);

    const stateChange = useCallback((e) => {
      setState(e.target.value);
    }, []);

    const cityChange = useCallback((e) => {
      const city = e.target.options[e.target.selectedIndex].getAttribute('data-name');
      setCity({ id: e.target.value, city: city, state: state });
    }, [state]);

    const submit = (e) => {
      e.preventDefault();

      const data = { form: e.target.id, city_state: city };

      apiPutPrivate(`/api/players/${username.toLowerCase()}/`, data, {});
    }

    const renderStates = (state, index) => {
      return (
        <option key={index} value={state}>{state}</option>
      )
    }

    const renderCities = (city, index) => {
      return(
        <option
          key={index}
          value={city.id}
          data-name={city.city} >{city.city}</option>
      )
    }

    return (
      <form id='city_state' onSubmit={submit}>
      <FormLabel htmlFrom='state' label='State / Province' />
      <FormSelect
        id='state'
        classes='mb-2 b-bk block'
        placeholder='States'
        items={states}
        onChangeH={stateChange} >

        <option value='0'>Select One</option>
        {states.map(renderStates)}

      </FormSelect>

      <FormLabel htmlFrom='school' label='School' />
      <FormSelect
        id='city'
        classes='mb-2 b-bk block'
        placeholder='Cities'
        onChangeH={cityChange} >

        {city?.id && <option value={city.id}>{city.city}</option>}
        {!city?.id &&<option value=''>Select One</option>}
        <option value='0'>None</option>
        {cities.map(renderCities)}

      </FormSelect>

      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2 mr-3'
        type='submit'>Save</Button>
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2'
        click={(e) => onCancel(e)}>Cancel</Button>
    </form>
    )
}

export default LocationForm;
