import { useState, useEffect } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import useApiPublic from '../../../../hooks/useApiPublic';
import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';
import Button from '../../buttons/Buttons';
import { STATES } from '../../../../data/states';


const CollegeForm = ({ username, coachCollege, onDone, onCancel }) => {
  const { apiPutPrivate, status } = useApiPrivate();
  const { apiGetPublic, result: pubResult } = useApiPublic();

  const [state, setState] = useState('');
  const [college, setCollege] = useState(coachCollege.id ? coachCollege : {});
  const [states] = useState(STATES);
  const [colleges, setColleges] = useState([]);

  useEffect(() => {
    if (pubResult !== undefined) {
      console.log(pubResult)
      setColleges(pubResult.colleges);
    }

  }, [pubResult]);

  useEffect(() => {
    if (state) {
      apiGetPublic('/api/colleges/', { params: { state: state }});
    }
  }, [apiGetPublic, state]);

  useEffect(() => {
    if (status === 200) {
      onDone({ college: { id: college.id, name: college.name }});
    }
  }, [onDone, status, college]);

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  const renderColleges = (college, index) => {
    return (
      <option
        key={index}
        value={college.id}
        data-name={college.name} >{college.name} ({college.state})</option>
    )
  }

  const stateChange = (e) => {
    setState(e.target.value);
  }

  const collegeChange = (e) => {
    const collegeName = e.target.options[e.target.selectedIndex].getAttribute('data-name');
    setCollege({ id: e.target.value, name: collegeName });
  }
  
  const submit = (e) => {
    e.preventDefault();

    const data = { form: e.target.id, college_id: college.id }

    apiPutPrivate(`/api/coaches/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='college' onSubmit={submit}>
      <FormLabel htmlFrom='state' label='State' />
      <FormSelect
        id='state'
        classes='mb-2 b-bk block'
        placeholder='States'
        items={states}
        onChangeH={stateChange} >

        <option value='0'>Select One</option>
        {states.map(renderStates)}

      </FormSelect>

      <FormLabel htmlFrom='school' label='School' />
      <FormSelect
        id='school'
        value={college.id}
        classes='mb-2 b-bk block'
        placeholder='Colleges'
        onChangeH={collegeChange} >

        {college?.id && <option value={college.id}>{college.name}</option>}
        {!college?.id &&<option value=''>Select One</option>}
        <option value='0'>None</option>
        {colleges.map(renderColleges)}

      </FormSelect>

      <Button classes='btn disabled btn-edit-save mr-3' type='submit'>Save</Button>
      <Button classes='btn btn-edit-save' click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default CollegeForm;
