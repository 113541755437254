// import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faPhotoFilm, faGamepad, faMessage, faGear } from '@fortawesome/free-solid-svg-icons';

const NavPrivate = () => {

  return (
    <div className='flex'>
      <NavLink
        to=''
        end
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faChartSimple} className='mr-3' />
        Stats
      </NavLink>

      <NavLink
        to='videos/gallery'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faPhotoFilm} className='mr-3' />
        Video Gallery
      </NavLink>

      <NavLink
        to='matches'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faGamepad} className='mr-3' />
        Matches
      </NavLink>

      <NavLink
        to='messages'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faMessage} className='mr-3' />
        Messages
      </NavLink>

      <NavLink
        to='settings'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faGear} className='mr-3' />
        Settings
      </NavLink>
    </div>
  )
}

export default NavPrivate;
