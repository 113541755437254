import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import useApiPrivate from '../../../hooks/useApiPrivate';
import ClipCardProfile from '../../clips/ClipCardProfile';
import Button from '../../layout/buttons/Buttons';


const VideosPrivate = ({ player }) => {
  const { apiGetPrivate, result, error, status } = useApiPrivate();
  const [clips, setClips] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage] = useState(9);
  const [moreClips, setMoreClips] = useState(true);

  useEffect(() => {
    if (result?.clips) {
      setClips(previous => [...previous, ...result.clips]);
      setPage(previous => previous + 1);
      if (result.clips.length < perPage) {
        setMoreClips(false);
      }
    }
  }, [result, status, perPage]);

  const getClips = useCallback((page, perPage, userId) => {
    apiGetPrivate(`/api/clips/`, { params: { userId, page, perPage }});
  }, [apiGetPrivate]);

  useEffect(() => {
    if (player?.id) {
      getClips(0, perPage, player.id);
    }
    }, [apiGetPrivate, getClips, perPage, player.id]);

  const renderClips = (clip, index) => {
    return (
      <ClipCardProfile
        key={index}
        clip={clip}
        creator={player} />
    )
  }

  return (
    <div>
      <div className='mb-8 flex justify-end'>
        {player?.username &&
          <Link
            to={`/players/${player.username.toLowerCase()}/videos/upload`}
            className='text-center btn px-10 py-4 bg-5 t-3 ft1-med radius2'>
            Upload new</Link>}
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-6'>
        {clips && clips.map(renderClips)}
      </div>

      {moreClips && error === undefined &&
        <div className='mt-4 flex justify-center'>
          <Button
            classes='py-4 px-8 bg-5 t-3 ft1-med radius2'
            click={() => getClips(page, perPage, player.id)}>Load more</Button>
        </div>}
    </div>
  )
}

export default VideosPrivate;
