export const GAMES = [
  { id: 1, name: "Overwatch 2" },
  { id: 2, name: "Warzone 2" },
  { id: 3, name: "Fortnite" },
  { id: 4, name: "Rocket League" },
  { id: 5, name: "Valorant" },
  { id: 6, name: "League of Legends" },
  { id: 7, name: "Destiny 2" },
  { id: 8, name: "PUBG" },
  { id: 9, name: "Other" },
];
