import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BackDrop from '../../../layout/modals/Backdrop';
import useApiPrivate from '../../../../hooks/useApiPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';


const AddPlayerToBucket = ({ playerId, onAdded = undefined, classes }) => {
  const { apiPostPrivate, result: postResult } = useApiPrivate();
  const { apiGetPrivate, result: getResult } = useApiPrivate();
  const [showModal, setShowModal] = useState(false);
  const [buckets, setBuckets] = useState();
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);
  const userStateAccountType = useSelector(state => state.user.user.accountType);

  const addPlayerToBucket = (bucketId) => {
    const data = { bucketId: bucketId, playerId: playerId };
    apiPostPrivate(`/api/player-buckets/add-player/${playerId}/`, data);
  }
  const getCoachBuckets = () => {
    const data = { playerId: playerId };
    apiGetPrivate(`/api/player-buckets/player-not-in/`, { params: data });
  }

  const showModalH = () => {
    getCoachBuckets();
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if (postResult?.added) {
      setShowModal(false);
      if (onAdded !== undefined) {
        onAdded();
      }
    }
  }, [postResult, onAdded]);

  useEffect(() => {
    if (getResult?.buckets) {
      setBuckets(getResult.buckets);
    }
  }, [getResult]);

  const renderBuckets = (bucket, index) => {  
    return (
      <div
        key={index}
        className='b-1 radius1 px-3 py-1 mb-3 cursor-pointer'
        onClick={() => addPlayerToBucket(bucket.id)}>
        <h1 className='t-w'>{bucket.title}</h1>
      </div>
    )
  }

  return (
    <>
      {userStateIsLoggedIn && userStateAccountType === 2 &&
        <div className={classes}>
          <FontAwesomeIcon icon={faLock} className='mr-3' />
          <button onClick={showModalH}>
            Bucket
          </button>
        </div>}

      {showModal &&
        <BackDrop onClose={closeModal}>
          <div className='w-40/100 p-6 b-1 bg-bk radius1'>
            {buckets && buckets.map(renderBuckets)}
            {buckets && buckets.length === 0 &&
              <p className='text-center'>This player is currently in all of your buckets</p>}
          </div>
        </BackDrop>}
    </>
  )
}

export default AddPlayerToBucket;
