import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageContainer from '../../components/layout/PageContainer';
import BasePrivate from '../../components/players/profilePrivate/BasePrivate';
import BasePublic from '../../components/players/profilePublic/BasePublic';

const PlayerPage = () => {
  const { slug } = useParams();
  const userState = useSelector(state => state.user.user);
  const loggedIn = useSelector(state => state.user.loggedIn);


  let playerComponent = '';
  if (loggedIn && slug === userState.username.toLowerCase()) {
    playerComponent = <BasePrivate slug={slug} />;
  } else {
    playerComponent = <BasePublic slug={slug} />;
  }

  return (
    <PageContainer>
      {playerComponent !== '' && playerComponent}
    </PageContainer>
  )
}

export default PlayerPage;
