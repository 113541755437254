// import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faGamepad, faMessage, faGear, faPaperPlane } from '@fortawesome/free-solid-svg-icons';


const NavPrivateC = () => {

  return (
    <div className='flex'>
      <NavLink
        to=''
        end
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faChartSimple} className='mr-3' />
        Buckets
      </NavLink>

      <NavLink
        to='matches'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faGamepad} className='mr-3' />
        Matches
      </NavLink>

      <NavLink
        to='player-profile-submissions'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faPaperPlane} className='mr-3' />
        Submitted profiles
      </NavLink>

      <NavLink
        to='messages'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faMessage} className='mr-3' />
        Messages
      </NavLink>

      <NavLink
        to='settings'
        className='px-2 prof-link'>
        <FontAwesomeIcon icon={faGear} className='mr-3' />
        Settings
      </NavLink>
    </div>
  )
}

export default NavPrivateC;
