import { useEffect, useState, useCallback } from 'react';

import FormSelect from '../../layout/forms/FormSelect';
import useApiPrivate from '../../../hooks/useApiPrivate';
import MatchCardProfileC from './matches/MatchCardProfileC';
import MatchDetails from './matches/MatchDetails';
import CreateMatchModal from './matches/CreateMatchModal';


const MatchesPrivateC = ({ coach }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [matches, setMatches] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState();
  const [tab, setTab] = useState('created');
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    if (result?.matches) {
      setMatches(result.matches);
    }
  }, [result]);

  useEffect(() => {
    if (coach?.id) {
      let filter = '';
      if (tab === 'created') {
        filter = 'created';
      } else if (tab === 'sent') {
        filter = 'sent';
      } else if (tab === 'uploaded') {
        filter = 'uploaded';
      } else if (tab === 'finished') {
        filter = 'finished';
      }
      apiGetPrivate(`/api/matches/by-coach`, { params: { filter }});
    }
  }, [apiGetPrivate, coach.id, tab]);

  const showCreateModalH = useCallback(() => {
    setShowCreateModal(previous => !previous);
  }, []);

  const onCreatMatchH = useCallback((newMatch) => {
    setMatches(previous => {
      return [...previous, newMatch];
    });
  }, []);

  const showMatchDetailsH = (matchId) => {
    if (selectedMatchId === matchId) {
      setSelectedMatchId(null);
    } else {
      setSelectedMatchId(matchId);
    }
  }

  const onRemoveMatchH = useCallback((matchIdToRemove) => {
    const newMatchList = matches.filter(m => m.id !== matchIdToRemove);
    setMatches(newMatchList);
  }, [matches]);

  const renderMatches = (match, index) => {
    return (
      <div
        key={index}
        onClick={() => showMatchDetailsH(match.id)}
        className='cursor-pointer' >

        <MatchCardProfileC match={match} />

        {match.id === selectedMatchId &&
          <div className='mb-6'>
            <MatchDetails
              matchId={selectedMatchId}
              onRemoveMatch={onRemoveMatchH} />
          </div>}
      </div>
    )
  }

  return (
    <>
      <div>
        <div>
          <FormSelect
            classes='mb-6 py-2 px-6 w-20/100 radius1 b-4 bg-prgr'>
            <option>All</option>
          </FormSelect>
        </div>
        <div className='mb-6 flex justify-between'>
          <div>
            <button
              onClick={() => setTab('created')}
              className={`mr-4 prof-link${tab === 'created' ? ' active' : ''}`}>Created</button>
            <button
              onClick={() => setTab('sent')}
              className={`mr-4 prof-link${tab === 'sent' ? ' active' : ''}`}>Sent</button>
            <button
              onClick={() => setTab('uploaded')}
              className={`mr-4 prof-link${tab === 'uploaded' ? ' active' : ''}`}>Uploaded</button>
            <button
              onClick={() => setTab('finished')}
              className={`prof-link${tab === 'finished' ? ' active' : ''}`}>Finished</button>
          </div>
          <button
            className='self-center py-1 px-6 bg-5 t-3 ft1-med radius2'
            onClick={showCreateModalH}>Create match</button>
        </div>

        <div>
          {matches.length === 0 && <p>No {tab} matches</p>}
          {matches.map(renderMatches)}
        </div>
      </div>

      {showCreateModal &&
        <CreateMatchModal
          onClose={showCreateModalH}
          onCreated={onCreatMatchH} />}
    </>
  )
}

export default MatchesPrivateC;
