import SocialMediaIcon from "../../layout/SocialMediaIcon";


const SocialLinks = ({ links, ctnClasses = '', iconClasses = '' }) => {
  const cClasses = ctnClasses ? ctnClasses : 'flex justify-center btb-4 py-8';
  const iClasses = iconClasses ? iconClasses : 'mx-2 fs-24';

  return (
    <div className={cClasses}>
      {Object.keys(links).length === 0 && <p>No social links</p>} 
      {links?.tw &&
        <SocialMediaIcon
          link={links.tw}
          linkKey={'tw'}
          classes={iClasses} />}
      {links?.tt &&
        <SocialMediaIcon
          link={links.tt}
          linkKey={'tt'}
          classes={iClasses} />}
      {links?.yt &&
        <SocialMediaIcon
          link={links.yt}
          linkKey={'yt'}
          classes={iClasses} />}
      {links?.tk &&
        <SocialMediaIcon
          link={links.tk}
          linkKey={'tk'}
          classes={iClasses} />}
      {links?.ig &&
        <SocialMediaIcon
          link={links.ig}
          linkKey={'ig'}
          classes={iClasses} />}
    </div>
  )
}

export default SocialLinks;
