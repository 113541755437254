import { useEffect, useState } from 'react';

import useApiPrivate from '../../../hooks/useApiPrivate';
import Button from '../buttons/Buttons';


const PasswordChange = ({ username, onDone, onCancel }) => {
  const { apiPutPrivate, result, error, status } = useApiPrivate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (status === 200) {
      onDone();
    } else if (status === 400) {
      setErrors(error.data)
    }
  }, [onDone, status, result, error])

  const currentPassChange = (e) => {
    setCurrentPassword(e.target.value);
  }

  const newPassChange = (e) => {
    setPassword(e.target.value)
  }

  const newPass2Change = (e) => {
    setPassword2(e.target.value);
  }

  const renderErrors = (e, index) => {
    return (
      <li key={index}><span className='t-r'>* </span>{e.message}</li>
    )
  }

  const submit = (e) => {
    e.preventDefault();

    const data = { username, currentPassword, password, password2 };
    apiPutPrivate(`/api/auth/change-password/`, data, {});
  }

  return (
    <form id='passwordchange' onSubmit={submit}>
      {errors.length > 0 &&
      <div className='mb-3 t-w'>
        <ul>
          {errors.map(renderErrors)}
        </ul>
      </div>}

      <label htmlFor='passwordchange'>Change Password</label>
      <input
        id='currentpass'
        className='mb-2 b-bk'
        type='password'
        autoComplete='off'
        placeholder='Current password'
        onChange={currentPassChange} />
      <input
        id='password'
        className='mb-2 b-bk'
        type='password'
        autoComplete='off'
        placeholder='New password'
        onChange={newPassChange} />
      <input
        id='password2'
        className='mb-2 b-bk'
        type='password'
        autoComplete='off'
        placeholder='New password confirm'
        onChange={newPass2Change} />
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2 mr-3'
        type='submit'>Save</Button>
      <Button
        classes='btn py-2 px-4 bg-5 t-3 fs-14 ft1-med radius2'
        click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default PasswordChange;
