import { useEffect, useState } from 'react';

import { formatDateTime } from '../../../../utils/formatting';


const MatchCardProfileC = ({ match }) => {
  const [matchEdited, setMatchEdited] = useState();

  useEffect(() => {
    setMatchEdited(match.edited);
  }, [match.edited]);

  let startTime = '';
  if (match?.start) {
    startTime = formatDateTime(match.start);
  } else {
    startTime = 'TBD';
  }

  return (
    <div className='b-4 radius1 px-4 py-2 mb-4 ft1 bg-wgr flex justify-between'>
      <div className='flex items-center'>
        <p>ID{String(match.id).padStart(8, '0')}</p>
        {matchEdited &&
          <span className='ml-4 t-4 px-4 py-1 bg-pr radius1 fs-14'>Edited</span>}
      </div>

      <p>Starting, {startTime}</p>
    </div>
  )
}

export default MatchCardProfileC;
