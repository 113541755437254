import { useEffect } from 'react';

import useApiPrivate from '../../hooks/useApiPrivate';


const AdminRunSummonerCheck = () => {
  const { apiPostPrivate, result } = useApiPrivate();

  useEffect(() => {
    console.log('R=>', result);
  }, [result]);

  const runSommonerCheck = () => {
    console.log('running check')
    apiPostPrivate('/api/lol-api/update-summoners', {});
  }

  const stopSommonerCheck = () => {
    apiPostPrivate('/api/lol-api/update-summoners/stop', {});
  }

  return (
    <div>
      <button
        onClick={runSommonerCheck}
        className='b-pr px-3 py-1 radius1 bg-pr mr-4'>Run Sommoner Update</button>

      <button
        onClick={stopSommonerCheck}
        className='b-pr px-3 py-1 radius1 bg-pr'>Stop Sommoner Update</button>
    </div>
  )
}

export default AdminRunSummonerCheck;
