import { useEffect, useState, useCallback } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import BackDrop from '../../../layout/modals/Backdrop';
import Format from './Format';


const InvitePlacementModal = ({ matchId, playerId, onClose }) => {
  const { apiPostPrivate, result: postResult } = useApiPrivate();
  const { apiGetPrivate, result } = useApiPrivate();
  const [match, setMatch] = useState({});
  const [entrants, setEntrants] = useState([]);
  const [alreadyInMatch, setAlreadyInMatch] = useState();

  useEffect(() => {
    if (result?.match) {
      setMatch(result.match);
      setEntrants(result.match.entrants);
    }
  }, [result]);

  useEffect(() => {
    if (postResult?.added?.entrant) {
      setEntrants(previous => [...previous, postResult.added.entrant]);
    }
  }, [postResult]);

  useEffect(() => {
    if (entrants.length > 0) {
      const  inMatch = entrants.some(entrant => entrant.user_id === parseInt(playerId));
      if (inMatch) {
        setAlreadyInMatch(true);
      } else {
        setAlreadyInMatch(false);
      }
    }
  }, [playerId, entrants]);

  useEffect(() => {
    if (matchId) {
      apiGetPrivate(`/api/matches/for-coach/${matchId}`);
    }
  }, [apiGetPrivate, matchId]);


  const addEntrantH = useCallback((team) => {
    const data = {
      matchId,
      playerId,
      team
    }

    apiPostPrivate('/api/matches/add-player', data);
  }, [apiPostPrivate, matchId, playerId]);

  return (
    <BackDrop onClose={onClose}>
      <div className=''>
        {match?.id &&
          <Format
            matchId={match.id}
            gameId={match.game_id}
            formatId={match.format_id}
            entrants={entrants}
            onEntrantAdd={alreadyInMatch ? undefined : addEntrantH}
            alreadyInMatch={alreadyInMatch} />}
      </div>
    </BackDrop>
  )
}

export default InvitePlacementModal;
