import BackDrop from '../layout/modals/Backdrop';


const DeleteClipModal = ({ onClose, onConfirm }) => {
  return (
    <BackDrop onClose={onClose}>
      <div className='b-1 radius1 px-10 pt-10 pb-10 bg-7 text-center'>
        <h1 className='fs-30 ft1-med mb-6'>Delete clip confirm</h1>

        <button
          className='px-12 py-4 bg-5 t-3 ft1-med relative radius2'
          onClick={onConfirm} >Confirm deletion</button>
      </div>
    </BackDrop>
  )
}

export default DeleteClipModal;
