import { useEffect, useState } from 'react';

import MatchPlayersCard from './MatchPlayersCard';


const FormatDetails = ({
    matchId, gameId, formatId, matchStatus, entrants,
    onEntrantRemoved, onAllowUpload, onAllowSend
  }) => {
  // onEntrantAdd will === undefined if player is already in match

  const [teamSpots, setTeamSpots] = useState();
  const [teamOne, setTeamOne] = useState([]);
  const [teamTwo, setTeamTwo] = useState([]);
  const [declines, setDeclines] = useState([]);

  useEffect(() => {
    let tempTeam1 = [];
    let tempTeam2 = [];
    let tempDeclines = [];
    let tempSpots = null;
    let readyPlayersCount = 0;

    if (gameId === '1') {
      if (formatId === 1) {
        tempSpots = 5;
      } else if (formatId === 2) {
        tempSpots = 3;
      } else if (formatId === 3) {
        tempSpots = 1;
      }
    }

    for (let e of entrants) {
      // if player status is accepted
      if (e.entrant_status === 2) {
        readyPlayersCount = readyPlayersCount + 1;
      }

      // player has declined
      if (e.entrant_status === 3) {
        tempDeclines.push(e);
      }

      // entrant is either pending or accepted
      if (e.entrant_status === 1 || e.entrant_status === 2) {
        if (e.team === 1) {
          tempTeam1.push(e);
        } else if (e.team === 2) {
          tempTeam2.push(e);
        }
      }
    }

    if (tempTeam1.length < tempSpots) {
      tempTeam1[tempSpots - 1] = 0;
      tempTeam1.fill(0, tempTeam1.length, tempSpots);
    }

    if (tempTeam2.length < tempSpots) {
      tempTeam2[tempSpots - 1] = 0;
      tempTeam2.fill(0, tempTeam2.length, tempSpots);
    }

    if (readyPlayersCount >= (tempSpots * 2)) {
      onAllowUpload(true);
    } else {
      onAllowUpload(false);
    }

    // if match status is created 1 or editing 4
    if (matchStatus === 1 && entrants.length >= (tempSpots * 2)) {
      onAllowSend(true);
    } else {
      onAllowSend(false);
    }

    setTeamOne([...tempTeam1]);
    setTeamTwo([...tempTeam2]);
    setDeclines([...tempDeclines]);
    setTeamSpots(tempSpots);
  }, [entrants, gameId, formatId, matchStatus, onAllowUpload, onAllowSend]);

  const renderTeam1 = (player, index) => {
    return (
      <MatchPlayersCard
        key={index}
        matchId={matchId}
        player={player}
        onEntrantRemoved={onEntrantRemoved} />
    )
  }

  const renderTeam2 = (player, index) => {
    return (
      <MatchPlayersCard
        key={index}
        matchId={matchId}
        player={player}
        onEntrantRemoved={onEntrantRemoved} />
    )
  }

  const renderDeclines = (player, index) => {
    return (
      <MatchPlayersCard
        key={index}
        matchId={matchId}
        player={player} />
    )
  }

  return (
    <div>
      <div className='flex justify-center'>
        {teamOne.map(renderTeam1)}
      </div>

      <div className='my-8 fs-32 ft1-med text-center'>VS</div>

      <div className='flex justify-center'>
        {teamTwo.map(renderTeam2)}
      </div>

      {declines.length > 0 && <>
        <div className='mt-8 mb-4 fs-32 ft1-med text-center'>Declined</div>

        <div className='flex flex-wrap justify-center'>
          {declines.map(renderDeclines)}
        </div></>}
    </div>
  )
}

export default FormatDetails;
