import './Form.css';


const FormSelect = ({
    classes,
    id,
    type,
    name,
    placeholder,
    value,
    onChangeH,
    onBlurH,
    onFocusH,
    errors,
    children
  }) => {

  const renderErrors = (error, index) => {
    return (
      <li key={index}>{error}</li>
    )
  }

  return (
    <>
      <select
        id={id}
        className={classes}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete='off'
        onChange={onChangeH}
        onFocus={onFocusH}
        onBlur={onBlurH} >

        {children}

      </select>

      <div>
        <ul>
          {errors && errors.map(renderErrors)}
        </ul>
      </div>
    </>
  )
}

export default FormSelect;
