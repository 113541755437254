import { Link } from 'react-router-dom';

import GameImg from './GameImg';


const GamesGameCard = ({ game }) => {
  return (
    <div className='p-3 radius1 fs-14 b-pr'>
      <div className='radius1 bg-2'>
        <Link to={`/games/${game.slug}/`}>
          <GameImg
            gameId={game.id}
            imgName={game.img_1}
            classes='radius1' />
        </Link>

        <p className='mt-2 text-center'>{game.name}</p>
      </div>
    </div>
  )
}

export default GamesGameCard;
