import Instagram from '../../../assets/imgs/social/instagram.svg';
import Twitch from '../../../assets/imgs/social/twitch.svg';
import Twitter from '../../../assets/imgs/social/twitter.svg';
import Tiktok from '../../../assets/imgs/social/tiktok.svg';
import Youtube from '../../../assets/imgs/social/youtube.svg';


const SocialMediaIcon = ({ icon, link, classes = '' }) => {
  let iconImg = '';
  if (icon === 'instagram') {
    iconImg = <img className={classes} src={Instagram} alt='Instagram Icon' />
  } else if (icon === 'twitch') {
    iconImg = <img className={classes} src={Twitch} alt='Twitch Icon' />
  } else if (icon === 'twitter') {
    iconImg = <img className={classes} src={Twitter} alt='Twitter Icon' />
  } else if (icon === 'tiktok') {
    iconImg = <img className={classes} src={Tiktok} alt='Tiktok Icon' />
  } else if (icon === 'youtube') {
    iconImg = <img className={classes} src={Youtube} alt='Youtube Icon' />
  }

  return (
    <a
      href={link}
      rel='noreferrer'
      target='_blank'
      className='cursor-pointer'>
      {iconImg}
    </a>
  )
}

export default SocialMediaIcon;
