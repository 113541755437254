import FollowPlayerPlayer from '../../userInteractions/FollowPlayerPlayer';
import ChatButton from '../../chat/ChatButton';
import BlockUser from '../../userInteractions/BlockUser';
import AddPlayerToBucket from '../../coaches/profilePrivate/buckets/AddPlayerToBucket';
import FollowCoachPlayer from '../../userInteractions/FollowCoachPlayer';
import InviteMatchListModal from '../../coaches/profilePrivate/matches/InviteMatchListModal';


const ActionsPublic = ({ player }) => {

  return (
    <div className='flex'>
      <FollowPlayerPlayer
        classes='button action leading-none self-center radius2'
        playerId={player.id}
        following={player.isFollowing} />
      <FollowCoachPlayer
        classes='button action leading-none self-center radius2'
        playerId={player.id}
        following={player.isFollowing} />
      <ChatButton
        classes='button action leading-none self-center radius2'
        otherUserId={player.id}
        otherUserUsername={player.username}
        otherUserImg={player.img_1} />
      <AddPlayerToBucket
        classes='button action leading-none self-center radius2'
        playerId={player.id} />
      <InviteMatchListModal
        classes='button action leading-none self-center radius2'
        playerId={player.id} />
      <BlockUser
        classes='button action leading-none self-center radius2'
        playerId={player.id} />
    </div>
  )
}

export default ActionsPublic;
