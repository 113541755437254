import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';


const ChatButton = ({ otherUserId, otherUserUsername, otherUserImg, classes }) => {
  const userStateAccountType = useSelector(state => state.user.user.accountType);
  const userStateUsername = useSelector(state => state.user.user.username)

  return (
    <>
      <div className={classes}>
      <FontAwesomeIcon icon={faMessage} className='mr-3 ml-0.5 fs-13' />
        <Link
          to={`/${userStateAccountType === 1 ? 'players' : 'coaches'}/${userStateUsername.toLowerCase()}/messages`}
          state={{ otherUserId, otherUserUsername, otherUserImg }} >Message</Link>
      </div>
    </>  
  )
}

export default ChatButton;
