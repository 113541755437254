import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from '../../components/layout/PageContainer';
import LolLeaderboard from '../../components/leaderboards/LolLeaderboard';


const Leaderboard = () => {
  const { game } = useParams();
  const [leaderboard, setLeaderboard] = useState();

  useEffect(() => {
    if (game === 'league-of-legends') {
      setLeaderboard(<LolLeaderboard />);
    }
  }, [game]);

  return (
    <PageContainer>
      {leaderboard}
    </PageContainer>
  )
}

export default Leaderboard;
