import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import FormLabelInput from '../FormLabelInput';
import FormInput from '../FormInput';
import Button from '../../buttons/Buttons';


const NameFormCoach = ({ username, fName, lName, onDone, onCancel }) => {
  const { apiPutPrivate, result, status } = useApiPrivate();
  const [firstName, setFirstName] = useState({
    val: fName ? fName : '',
    origVal: fName ? fName : '',
    changed: false
  });
  const [lastName, setLastName] = useState({
    val: lName ? lName : '',
    origVal: lName ? lName : '',
    changed: false
  });

  useEffect(() => {
    if (status === 200) {
      onDone({ firstName: firstName.val, lastName: lastName.val });
    }
  }, [onDone, status, result, firstName.val, lastName.val]);

  const fNameChange = (e) => {
    setFirstName({ ...firstName, val: e.target.value, changed: true });
  }

  const lNameChange = (e) => {
    setLastName({ ...lastName, val: e.target.value, changed: true });
  }

  const submit = (e) => {
    e.preventDefault();

    const data = { form: e.target.id, fName: firstName.val, lName: lastName.val }

    apiPutPrivate(`/api/coaches/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='names' onSubmit={submit}>
      <FormLabelInput
        label='Name'
        classes=''
        classes2='mb-2 b-bk'
        type='text'
        placeholder='First'
        value={firstName.val}
        onChangH={fNameChange} />
      <FormInput
        classes='mb-2 b-bk'
        type='text'
        placeholder='Last'
        value={lastName.val}
        onChangH={lNameChange} />
      <Button classes='btn btn-edit-save mr-3' type='submit'>Save</Button>
      <Button classes='btn btn-edit-save' click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default NameFormCoach;
