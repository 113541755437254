import ChatButton from '../../chat/ChatButton';
import FollowPlayerCoach from '../../userInteractions/FollowPlayerCoach';
import SendPlayerCoach from '../../userInteractions/SendPlayerCoach';


const ActionsPublicC = ({ coach }) => {
  return (
    <div className='flex'>
      <SendPlayerCoach
        classes='button action leading-none self-center radius2'
        coachId={coach.id}
        sentProfile={coach.profileSent} />
      <FollowPlayerCoach
        classes='button action leading-none self-center radius2'
        coachId={coach.id}
        following={coach.isFollowing} />
      <ChatButton
        classes='button action leading-none self-center radius2'
        otherUserId={coach.id}
        otherUserUsername={coach.username}
        otherUserImg={coach.img_1} />
    </div>
  )
}

export default ActionsPublicC;
