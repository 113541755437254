import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import ProfileImg from '../../../players/ProfileImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import RemoveEntrant from './RemoveEntrant';
import acceptedSvg from '../../../../assets/imgs/icons/check.svg';
import declinedSvg from '../../../../assets/imgs/icons/close.svg';
import pendingSvg from '../../../../assets/imgs/icons/question-mark-circle.svg';

import { formatPlayerType } from '../../../../utils/formatting';
import SocialMediaIcon from '../../../players/utils/SocialMediaIcon';
import PlayerModalViewBase from '../../../players/profileModalView/PlayerModalViewBase';

const MatchPlayersCard = ({ matchId, player, onEntrantRemoved }) => {
  const [showActions, setShowActions] = useState(false);
  const [showPlayerLinks, setShowPlayerLinks] = useState(false);
  const [modalView, setModalView] = useState(false);

  const openModalViewH = useCallback(() => {
    setModalView(true);
  }, []);

  const closeModalViewH = useCallback(() => {
    setModalView(false);
  }, []);

  const showActionsH = (e) => {
    e.stopPropagation();
    setShowActions(previous => !previous);
  }

  return (
    <>
      {player?.user_id !== undefined &&
        <div className='px-6 mx-6 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48'>
          {player.entrant_status === 2 &&
            <div
              className='absolute top-1 left-1 accept-burst p-3' >
              <img className='check-burst' src={acceptedSvg} alt='accepted' />
            </div>}
          {player.entrant_status === 3 &&
            <div
              className='absolute top-1 left-1 decline-burst p-3' >
              <img className='check-burst' src={declinedSvg} alt='declined' />
            </div>}
          {player.entrant_status === 1 &&
            <div
              className='absolute top-1 left-1 pending-burst p-3' >
              <img className='check-burst' src={pendingSvg} alt='accepted' />
            </div>}
          <div
            className='rounded-full px-1 py-0.5 bg-6 absolute top-3 right-3 cursor-pointer'
            onClick={showActionsH}>
            <FontAwesomeIcon
              icon={faEllipsis}
              size='xl' />
          </div>

          {showActions &&
            <div className='b3px-2 bg-1 p-3 radius05 bucket-actions top-0 right-10 z-50'>
              <RemoveEntrant
                matchId={matchId}
                playerId={player.user_id}
                onEntrantRemoved={onEntrantRemoved} >Remove</RemoveEntrant>
            </div>}

            <div className='b4px-w w-60/100 rounded-full mx-auto img-container'>
              <Link to={`/players/${player.slug}/`} >
                <ProfileImg
                  userId={player.id}
                  imgName={player.img_1}
                  classes='rounded-full' />
              </Link>
            </div>

          <div className='shrink-0'>
          <div className='mt-2.5 text-center leading-none'>
          <div className='cursor-pointer' onClick={openModalViewH}>
            <p className='mb-2.5 ft1-med t-1 fs-20'>{player.username}</p>
            <p className='t-4' >{formatPlayerType(player.player_type)}</p>
          </div>
          <hr className='mt-2.5'></hr>
          <div className='mt-4 flex justify-evenly'>
            <SocialMediaIcon
              classes='w-6'
              link={player.links?.ig}
              icon='instagram' />
            <SocialMediaIcon
              classes='w-6'
              link={player.links?.tw}
              icon='twitch' />
            <SocialMediaIcon
              classes='w-6'
              link={player.links?.tt}
              icon='twitter' />
            <SocialMediaIcon
              classes='w-6'
              link={player.links?.tk}
              icon='tiktok' />
            <SocialMediaIcon
              classes='w-6'
              link={player.links?.yt}
              icon='youtube' />
          </div>
        </div>
          </div>
        </div>}

      {player?.user_id === undefined &&
        <div className='px-8 mx-6 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48 relative'>
          {showPlayerLinks &&
            <div className='b3px-2 bg-1 p-3 radius05 bucket-actions top-0 right-10'>
              <div>
                <Link to='/players'>Player search</Link>
              </div>
              <div>
                <Link to='/leaderboards'>Leader boards</Link>
              </div>

              <div onClick={() => setShowPlayerLinks(false)}>Cancel</div>

            </div>}

          <div className='shrink-0' onClick={() => setShowPlayerLinks(true)}>
            <p className='text-center fs-14 mb-2'>Add players</p>
            <hr className='mx-4'></hr>
          </div>
        </div>}

        {modalView &&
        <PlayerModalViewBase
          playerId={player.id}
          slug={player.slug}
          onClose={closeModalViewH} />}
    </>
  )
}

export default MatchPlayersCard;
