import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import FormContainer from '../../components/layout/FormContainer';
import useApiPublic from '../../hooks/useApiPublic';
import { userActions } from '../../store/user';
import { STATES } from '../../data/states';
import FormSelect from '../../components/layout/forms/FormSelect';
import logoImg from '../../assets/imgs/logos/nav-logo.png';


const CoachRegisterPage = () => {
  const { apiPostPublic, apiGetPublic, result, error, loading } = useApiPublic();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coachType, setCoachType] = useState('');
  const [states] = useState(STATES);
  const [state, setState] = useState('');
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState('');
  const usernameRef = useRef();
  const fNameRef = useRef();
  const lNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const password2Ref = useRef();

  useEffect(() => {
    if (result?.schools) {
      setSchools(result.schools);
    } else if (result?.colleges) {
      setSchools(result.colleges);
    }
  }, [result]);

  useEffect(() => {
    if (state && coachType === '2') {
      apiGetPublic('/api/highschools/', { params: { state: state }});
    } else if (state && coachType === '3') {
      apiGetPublic('/api/colleges/', { params: { state: state }});
    }
  }, [apiGetPublic, state, coachType]);

  useEffect(() => {
    if (result?.user) {
      dispatch(userActions.register(
        {
          ...result.user,
          token: result.accessToken
        }
      ));
      const slug = result.user.username.toLowerCase();
      navigate(`/coaches/${slug}/`);
    }
  }, [dispatch, navigate, result]);

  const submitH = (e) => {
    e.preventDefault();

    const data = {
      username: usernameRef.current.value,
      fName: fNameRef.current.value,
      lName: lNameRef.current.value,
      coachType: coachType,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      password2: password2Ref.current.value
    }

    if (coachType === '2') {
      data['highSchool'] = school;
    } else if (coachType === '3') {
      data['college'] = school;
    }

    apiPostPublic('/api/auth/register/coaches', data, { withCredentials: true });
  }

  const usernameCheckH = () => {
    // console.log('username check');
  }

  const fNameCheckH = () => {

  }

  const lNameCheckH = () => {

  }

  const coachTypeCheckH = (e) => {
    setCoachType(e.target.value);
  }

  const stateChangeH = (e) => {
    setState(e.target.value);
  }

  const schoolChangeH = (e) => {
    setSchool(e.target.value);
  }

  const emailCheckH = () => {
    // console.log('email check');
  }

  const passwordCheckH = () => {
    // console.log('password check');
  }

  const password2CheckH = () => {
    // console.log('confirm password check');
  }

  const renderErrors = (error, index) => {
    return (
      <li key={index}>{error.message}</li>
    )
  }

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  const renderSchools = (school, index) => {
    return (
      <option
        key={index}
        value={school.id}
        data-name={school.name} >
        {school.name} ({school?.city} {school?.state})
      </option>
    )
  }

  return (
    <FormContainer>
      <img
        className=''
        src={logoImg}
        alt='Logo' />

      <div className='mt-14 w-85/100 mx-auto'>
        <h1 className='fs-32 ft1-med mb-6'>Coach Register</h1>

        {error?.data !== undefined &&
          <div className='mb-3 t-pk'>
            <ul>
              {error?.data.map(renderErrors)}
            </ul>
          </div>}

        <form onSubmit={submitH}>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Username</label>
            <input
              id='username'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              autoComplete='off'
              ref={usernameRef}
              onChange={usernameCheckH}
              onFocus={usernameCheckH}
              onBlur={usernameCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>First name</label>
            <input
              id='f-name'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              autoComplete='off'
              ref={fNameRef}
              onChange={fNameCheckH}
              onFocus={fNameCheckH}
              onBlur={fNameCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Last name</label>
            <input
              id='l-name'
              className='auth w-full p-0 t-4 fs-15'
              type='text'
              autoComplete='off'
              ref={lNameRef}
              onChange={lNameCheckH}
              onFocus={lNameCheckH}
              onBlur={lNameCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Coach Type</label>
            <select
              id='coach-type'
              className='auth w-full p-0 t-4 fs-15'
              onChange={coachTypeCheckH} >

              <option value='0'>Select One</option>
              <option value='2'>High School</option>
              <option value='3'>College</option>
              <option value='4'>Pro</option>

            </select>
          </div>

          {(coachType === '2' || coachType === '3') &&
            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
              <label className='fs-13 t-5'>State</label>
              <FormSelect
                id='state'
                classes='auth w-full p-0 t-4 fs-15'
                placeholder='States'
                items={states}
                onChangeH={stateChangeH  } >

                <option value='0'>Select State</option>
                {states.map(renderStates)}

              </FormSelect>
            </div>}

          {coachType === '2' && state !== '' &&
            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
              <label className='fs-13 t-5'>High school</label>
              <FormSelect
                id='school'
                classes='auth w-full p-0 t-4 fs-15'
                placeholder='Schools'
                onChangeH={schoolChangeH} >

                <option value=''>Select High School</option>
                {schools.map(renderSchools)}

              </FormSelect>
            </div>}

          {coachType === '3' && state !== '' &&
            <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
              <label className='fs-13 t-5'>College</label>
              <FormSelect
                id='college'
                classes='auth w-full p-0 t-4 fs-15'
                placeholder='Colleges'
                onChangeH={schoolChangeH} >

                <option value=''>Select College</option>
                {schools.map(renderSchools)}

              </FormSelect>
            </div>}

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Email</label>
            <input
              id='email'
              className='auth w-full p-0 t-4 fs-15'
              type='email'
              autoComplete='off'
              ref={emailRef}
              onChange={emailCheckH}
              onFocus={emailCheckH}
              onBlur={emailCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-7 leading-none'>
            <label className='fs-13 t-5'>Password</label>
            <input
              id='password'
              className='auth w-full p-0 t-4 fs-15'
              type='password'
              autoComplete='off'
              ref={passwordRef}
              onChange={passwordCheckH}
              onFocus={passwordCheckH}
              onBlur={passwordCheckH} />
          </div>

          <div className='b-4 radius2 py-2.5 px-8 bg-prgr mb-10 leading-none'>
            <label className='fs-13 t-5'>Confirm password</label>
            <input
              id='password2'
              className='auth w-full p-0 t-4 fs-15'
              type='password'
              autoComplete='off'
              ref={password2Ref}
              onChange={password2CheckH}
              onFocus={password2CheckH}
              onBlur={password2CheckH} />
          </div>

          <div className='flex justify-center mb-12'>
            <button
              className={`btn w-50/100 py-4 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}
              type='submit'>
              <span className={`${loading ? 'invisible' : ''}`}>Register</span>
            </button>
          </div>


          <div className='text-center t-4 fs-16'>
            <p className='mb-4'>Already have an account? &nbsp;
              <Link className='ft1-med underline' to='/login'>Login</Link>
            </p>
            <p>
              Register as a player? &nbsp;
              <Link className='underline ft1-med' to='/register'>Register</Link>
            </p>
          </div>

        </form>
      </div>
    </FormContainer>
  )
}

export default CoachRegisterPage;
