import { useSelector } from 'react-redux';

import NavBar from './NavBar';


const TopNav = () => {
  const userState = useSelector(state => state.user.user);
  const userStateIsLoggedIn = useSelector(state => state.user.loggedIn);

  return (
    <NavBar
      user={userState}
      loggedIn={userStateIsLoggedIn} />
  )
}

export default TopNav;
