import './Form.css';


const FormLabelInput = ({
    classes,
    classes2,
    label,
    id,
    type,
    name,
    placeholder,
    value,
    onChangH,
    onBlurH,
    onFocusH,
    disabled = false,
    errors
}) => {
  const renderErrors = (error, index) => {
    return (
      <li key={index}>{error}</li>
    )
  }

  return (
    <>
      <label className={classes}>{label}</label>
      <input
        id={id}
        className={classes2}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete='off'
        onChange={onChangH}
        onFocus={onFocusH}
        onBlur={onBlurH}
        disabled={disabled} />
      <div>
        <ul>
          {errors && errors.map(renderErrors)}
        </ul>
      </div>
    </>
  )
}

export default  FormLabelInput;
