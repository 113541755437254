import { useEffect, useState } from 'react';

import FormatCard from './FormatCard';


const Format = ({ gameId, formatId, entrants, onEntrantAdd, alreadyInMatch }) => {
  // onEntrantAdd will === undefined if player is already in match

  const [teamSpots, setTeamSpots] = useState();
  const [teamOne, setTeamOne] = useState([]);
  const [teamTwo, setTeamTwo] = useState([]);
  const [declines, setDeclines] = useState([]);

  useEffect(() => {
    let tempTeam1 = [];
    let tempTeam2 = [];
    let tempDeclines = [];
    let tempSpots = null;

    if (gameId === '1') {
      if (formatId === 1) {
        tempSpots = 5;
      } else if (formatId === 2) {
        tempSpots = 3;
      } else if (formatId === 3) {
        tempSpots = 1;
      }
    }

    for (let e of entrants) {
      if (e.entrant_status === 3) {
        tempDeclines.push(e);
      } else if (e.team === 1) {
        tempTeam1.push(e);
      } else if (e.team === 2) {
        tempTeam2.push(e);
      }
    }

    if (tempTeam1.length < tempSpots) {
      tempTeam1[tempSpots - 1] = 0;
      tempTeam1.fill(0, tempTeam1.length, tempSpots);
    }

    if (tempTeam2.length < tempSpots) {
      tempTeam2[tempSpots - 1] = 0;
      tempTeam2.fill(0, tempTeam2.length, tempSpots);
    }

    setTeamOne([...tempTeam1]);
    setTeamTwo([...tempTeam2]);
    setDeclines([...tempDeclines]);
    setTeamSpots(tempSpots);
  }, [entrants, gameId, formatId]);

  const renderTeam1 = (player, index) => {
    return (
      <FormatCard
        key={index}
        player={player}
        onEntrantAdd={onEntrantAdd}
        team={1} />
    )
  }

  const renderTeam2 = (player, index) => {
    return (
      <FormatCard
        key={index}
        player={player}
        onEntrantAdd={onEntrantAdd}
        team={2} />
    )
  }

  return (
    <div className='b-1 p-14 radius1 bg-7'>
      {alreadyInMatch &&
        <div className='mb-6'>
          <h1 className='ft1-med'>Player is already in this match</h1>
        </div>}

      <div className={`grid grid-cols-${teamSpots} gap-10 justify-center`}>
        {teamOne.map(renderTeam1)}
      </div>

      <div className='my-8 fs-32 ft1-med text-center'>VS</div>

      <div className={`grid grid-cols-${teamSpots} gap-10 justify-center`}>
        {teamTwo.map(renderTeam2)}
      </div>
    </div>
  )
}

export default Format;
