import PageContainer from '../../components/layout/PageContainer';


const Terms = () => {
  return (
    <PageContainer>
      <h1>Terms of use</h1>
    </PageContainer>
  )
}

export default Terms;
