import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import useApiPrivate from '../../../hooks/useApiPrivate';
import PageContainer from '../../layout/PageContainer';
import HeaderPublicC from './HeaderPublicC';
import NavPublicC from './NavPublicC';
import ActionsPublicC from './ActionsPublicC';
import HomePublicC from './HomePublicC';
import FollowersPublicC from './FollowersPublicC';
import FollowingPublicC from './FollowingPublicC';


const BasePublicC = ({ slug }) => {
  const { apiGetPrivate, result } = useApiPrivate();
  const [coach, setCoach] = useState({});

  useEffect(() => {
    if (result?.coach) {
      setCoach(result.coach);
    }
  }, [result]);

  useEffect(() => {
    apiGetPrivate(`/api/coaches/${slug}/`);
  }, [apiGetPrivate, slug]);

  return (
    <>
      <PageContainer>
        <HeaderPublicC coach={coach} />

        <div className='mt-10 mb-6 flex justify-between'>
          <ActionsPublicC coach={coach} />
          <NavPublicC />
        </div>
      </PageContainer>

      <Routes>
        <Route
          path=''
          element={<HomePublicC coach={coach} />} />
        <Route
          index
          path='/followers'
          element={<FollowersPublicC coach={coach} />} />
        <Route
          index
          path='/following'
          element={<FollowingPublicC coach={coach} />} />
      </Routes>
    </>
  )
}

export default BasePublicC;
