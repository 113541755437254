import DefaultImg from '../assets/imgs/defaults/default-prof.webp';


export const formatTagLabel = (tag) => {
  let formattedTag = '';
  if (tag === 'lol') {
    formattedTag = 'League of Legends';
  } else if (tag === 'rl') {
    formattedTag = 'Rocket League';
  }

  return formattedTag;
}

export const formatLinkLabel = (link) => {
  let formattedLink = '';
  if (link === 'tt') {
    formattedLink = 'Twitter';
  } else if (link === 'tw') {
    formattedLink = 'Twitch';
  } else if (link === 'yt') {
    formattedLink = 'YouTube';
  } else if (link === 'tk') {
    formattedLink = 'TikTok';
  } else if (link === 'ig') {
    formattedLink = 'Instagram';
  }

  return formattedLink
}

export const formatPlayerType = (playerType) => {
  if (playerType === 1) {
    return 'Amateur';
  } else if (playerType === 2) {
    return 'High school';
  } else if (playerType === 3) {
    return 'Collegiate';
  } else if (playerType === 4) {
    return 'Semi pro';
  } else if (playerType === 5) {
    return 'Pro';
  }
}

export const formatCoachType = (coachType) => {
  if (coachType === 1) {
    return 'Unknown';
  } else if (coachType === 2) {
    return 'High school';
  } else if (coachType === 3) {
    return 'Collegiate';
  } else if (coachType === 4) {
    return 'Pro';
  }
}

export const formatTeamType = (teamType) => {
  if (teamType === 1) {
    return 'Unknown';
  } else if (teamType === 2) {
    return 'Amateur';
  } else if (teamType === 3) {
    return 'High school';
  } else if (teamType === 4) {
    return 'Collegiate';
  } else if (teamType === 5) {
    return 'Pro';
  }
}

export const formatInviteStatus = (status) => {
  if (status === 1) {
    return 'Pending';
  } else if (status === 2) {
    return 'Accepted';
  } else if (status === 3) {
    return 'Rejected';
  }
}

export const statFormat = (num, places = 3) => {
  return Number(num).toLocaleString(
    "en-US",
    { maximumFractionDigits: places, minimumFractionDigits: places }
  );
}

export const formatDateTime = (dateTime) => {
  if (dateTime === null) {
    return '';
  }
  const date = new Date(dateTime);
  return date.toLocaleString(
    "en-us",
    { month: 'long', day: 'numeric', year: 'numeric' }
  );
}

export const formatLolTierInt = (tierInt) => {
  let tierName = '';
  if (tierInt === 1) {
    tierName = 'CHALLENGER';
  } else if (tierInt === 2) {
    tierName = 'GRANDMASTER';
  } else if (tierInt === 3) {
    tierName = 'GRANDMASTER';
  } else if (tierInt === 4) {
    tierName = 'DIAMOND';
  } else if (tierInt === 10) {
    tierName = 'EMERALD';
  } else if (tierInt === 5) {
    tierName = 'PLATINUM';
  } else if (tierInt === 6) {
    tierName = 'GOLD';
  } else if (tierInt === 7) {
    tierName = 'SILVER';
  } else if (tierInt === 8) {
    tierName = 'BRONZE';
  } else if (tierInt === 9) {
    tierName = 'IRON';
  } else {
    tierName = 'NA';
  }
  return tierName;
}

export const formatLolRankInt = (rankInt) => {
  let rankName = '';
  if (rankInt === 1) {
    rankName = 'I';
  } else if (rankInt === 2) {
    rankName = 'II';
  } else if (rankInt === 3) {
    rankName = 'III';
  } else if (rankInt === 4) {
    rankName = 'IV';
  } else {
    rankName = 'NA';
  }
  return rankName;
}

export const getImgUrl = (imgName) => {
  let src = '';
  if (imgName && imgName !== 'img/defaults/user.webp') {
    src = `${process.env.REACT_APP_BASE_MEDIA_URL}/images${imgName}`;
  } else {
    src = `${window.location.origin.toString()}${DefaultImg}`;
  }
  return src;
}

export const formatPlayerHeaderSchools = (hs, c) => {
  if (hs?.id && c?.id) {
    return `${hs.name}, ${c.name}`;
  } else if (hs?.id && !c?.id) {
    return hs.name;
  } else if (c?.id && !hs?.id) {
    return c.name;
  } else if (!c?.id && !hs?.id) {
    return 'No school info';
  }
}

export const formatFormatId = (formatId) => {
  if (formatId === 1) {
    return '5x5';
  } else if (formatId === 2) {
    return '3x3';
  } else if (formatId === 3) {
    return '1x1';
  } else {
    return 'Unknown';
  }
}
