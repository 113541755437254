import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useApiPrivate from './useApiPrivate';
import { userActions } from '../store/user';


const useApiUpdateUserState = () => {
  const { apiGetPrivate, result, error, loading } = useApiPrivate();
  const dispatch = useDispatch();
 
  useEffect(() => {
    if (result?.accessToken && result?.user) {
      dispatch(userActions.refreshUser({ token: result.accessToken, user: result.user }));
    }
  }, [dispatch, result]);

  const updateState = useCallback(() => {
    apiGetPrivate('/api/auth/refresh-user');
  }, [apiGetPrivate]);

  return {
    updateState,
    result,
    error,
    loading
  }
}

export default useApiUpdateUserState;
