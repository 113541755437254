import { useEffect, useState, useCallback } from 'react';

import useApiPublic from '../../hooks/useApiPublic';
import LolLeaderCard from './LolLeaderCard';
import FormSelect from '../layout/forms/FormSelect';
import Button from '../layout/buttons/Buttons';


const LolLeaderboard = () => {
  const { apiGetPublic, error } = useApiPublic();
  const [leaders, setLeaders] = useState([]);
  const [order, setOrder] = useState('kda_avg');
  const [pType, setPType] = useState(0);
  const [tier, setTier] = useState(0);
  const [rank, setRank] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage] = useState(25)
  const [moreLeaders, setMoreLeaders] = useState(false);

  const leadersSet = useCallback((result) => {
    if (result?.data.length === 0) {
      setMoreLeaders(false);
    } else {
      setLeaders(previous => [...previous, ...result.data]);
      if (result?.data.length < perPage) {
        setMoreLeaders(false);
      } else {
        setMoreLeaders(true);
      }
    }
  }, [perPage]);

  const getLeaders = useCallback((page, perPage, order, pType, tier, rank) => {
    const ascDesc = order === 'deaths_avg' ? 'ASC' : 'DESC';
    const params = { page, perPage, order, ascDesc, pType, tier, rank };

    apiGetPublic('/api/leaderboards/lol', { params: params }, leadersSet);
  }, [apiGetPublic, leadersSet]);

  useEffect(() => {
    getLeaders(0, perPage, 'kda_avg', 0, 0, 0);
    setPage(1);
  }, [getLeaders, perPage]);

  const getMoreLeaders = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getLeaders(page, perPage, order, pType, tier, rank);
  }

  const searchH = () => {
    setLeaders(previous => []);
    setMoreLeaders(false);
    getLeaders(0, perPage, order, pType, tier, rank);
    setPage(1);
  }

  const resetH = useCallback(() => {
    setOrder('kda_avg');
    setPType(0);
    setTier(0);
    setRank(0);
    setPage(0);
  }, []);

  const orderLeaders = useCallback((order, ascDesc) => {
    setOrder(order);
  }, []);

  const pTypeChangeH = useCallback((e) => {
    setPType(parseInt(e.target.value));
  }, []);

  const tierChangeH = useCallback((e) => {
    setTier(parseInt(e.target.value));
  }, []);

  const rankChangeH = useCallback((e) => {
    setRank(parseInt(e.target.value));
  }, []);

  const renderLeader = (leader, index) => {
    return (
      <LolLeaderCard
        key={index}
        leader={leader} />
    )
  }

  return (
    <div className=''>
      <h1 className='mb-3 fs-20'>LOL Leaderboard</h1>

      <div className='mb-4 flex'>
        <FormSelect
          classes={`radius1 py-0.5 px-3 mr-4 ${pType === 0 ? 't-pr' : 't-w bg-pr'}`}
          placeholder='Player type'
          onChangeH={pTypeChangeH}
          value={pType} >

          <option value='0'>Player Types All</option>
          <option value='1'>Ameteur</option>
          <option value='2'>High School</option>
          <option value='3'>Collegiate</option>
          <option value='4'>Semi Pro</option>
          <option value='5'>Pro</option>
        </FormSelect>

        <FormSelect
          classes={`radius1 py-0.5 px-3 mr-4 ${tier === 0 ? 't-pr' : 't-w bg-pr'}`}
          placeholder='Player type'
          onChangeH={tierChangeH}
          value={tier} >

          <option value='0'>Tiers All</option>
          <option value='1'>Challenger</option>
          <option value='2'>Grandmaster</option>
          <option value='3'>Master</option>
          <option value='4'>Diamond</option>
          <option value='5'>Platinum</option>
          <option value='6'>Gold</option>
          <option value='7'>Silver</option>
          <option value='8'>Bronze</option>
          <option value='9'>Iron</option>
        </FormSelect>

        <FormSelect
          classes={`radius1 py-0.5 px-3 mr-4 ${rank === 0 ? 't-pr' : 't-w bg-pr'}`}
          placeholder='Player type'
          onChangeH={rankChangeH}
          value={rank} >

          <option value='0'>Ranks All</option>
          <option value='1'>I</option>
          <option value='2'>II</option>
          <option value='3'>III</option>
          <option value='4'>IV</option>
        </FormSelect>
      </div>

      <div className='mb-4 fs-15'>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'kda_avg' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('kda_avg')}>KDA</button>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'kills_avg' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('kills_avg')}>Kills</button>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'assists_avg' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('assists_avg')}>Assists</button>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'deaths_avg' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('deaths_avg')}>Deaths</button>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'dpm_avg' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('dpm_avg')}>DPM</button>
        <button
          className={`btn px-6 py-1.5 ft1-med radius2 mr-4 bg-pr ${order === 'gold_per_min' ? 'bg-pr t-w' : 'bg-5 t-3'}`}
          onClick={() => orderLeaders('gold_per_min')}>Gold / Min</button>
      </div>

      <div className='mb-4'>
        <button
          className={`btn px-10 b-g bg-g py-1.5 mr-4`}
          onClick={searchH}>Search</button>

        <button
          className={`btn px-10 b-g bg-g py-1.5`}
          onClick={resetH}>Reset</button>
      </div>

      {leaders.map(renderLeader)}

      {moreLeaders && error === undefined &&
        <div className='flex justify-center'>
          <Button
            classes='py-4 px-8 bg-5 t-3 ft1-med radius2'
            click={getMoreLeaders}>Load more</Button>
        </div>}
    </div>
  )
}

export default LolLeaderboard;
