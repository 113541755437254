import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';

import useApiPrivate from '../../../../hooks/useApiPrivate';
// import { userActions } from '../../../../store/user';
import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';
import Button from '../../buttons/Buttons';


const CoachTypeForm = ({ username, coachType, onDone, onCancel }) => {
  const { apiPutPrivate, result, status } = useApiPrivate();

  const [cType, setCType] = useState(coachType);

  useEffect(() => {
    if (status === 200) {
      onDone({ player_type: cType });
    }
  }, [onDone, status, result, cType]);

  const cTypeChange = (e) => {
    setCType(parseInt(e.target.value));
  }

  const submit = (e) => {
    e.preventDefault();
    const data = { form: e.target.id, coachType: parseInt(cType) };

    apiPutPrivate(`/api/coaches/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='coachType' onSubmit={submit}>
      <FormLabel htmlFor='ctype' label='Coach Type' />
      <FormSelect
        id='ctype'
        classes='mb-2 b-bk block'
        placeholder='Coach Type'
        onChangeH={cTypeChange}
        value={cType} >

        <option value='1'>Unknown</option>
        <option value='2'>High School</option>
        <option value='3'>Collegiate</option>
        <option value='4'>Pro</option>

      </FormSelect>

      <Button classes='btn disabled btn-edit-save mr-3' type='submit'>Save</Button>
      <Button classes='btn btn-edit-save' click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default CoachTypeForm;
