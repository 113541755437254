import { Link } from 'react-router-dom';

import ProfileImg from '../../players/ProfileImg';
import SocialMediaIcon from '../../players/utils/SocialMediaIcon';
import HeaderOrganization from '../HeaderOrganization';
import { formatCoachType } from '../../../utils/formatting';


const HeaderPublicC = ({ coach }) => {
  return (
    <div className='w-full px-5 pt-2 flex justify-between radius1 bg-217-3 relative min-h-max'>
      <div className='flex justify-start'>
        <div className='w-32 relative -bottom-5'>
          {coach?.img_1 &&
            <div className='rounded-full mx-auto img-container'>
              <ProfileImg
                userId={coach.id}
                imgName={coach.img_1}
                classes='rounded-full b4px-w' />
          </div>}
        </div>

        <div className='ml-6 t-w mt-5 ft1 fs-14 leading-none'>
        <p className='fs-24 t-1 ft1-med mb-1'>{coach.username}</p>
          <p className='t-4 mb-4'>{formatCoachType(coach.coach_type)}</p>
          <HeaderOrganization
            coachType={coach.coach_type}
            highSchool={coach.high_school}
            college={coach.college} />
        </div>
      </div>

      <div className='flex flex-col justify-center'>
        <div className='flex justify-center mb-5'>
        {coach.links?.ig &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={coach.links?.ig}
              icon='instagram' />}
          {coach.links?.tw &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={coach.links?.tw}
              icon='twitch' />}
          {coach.links?.tt &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={coach.links?.tt}
              icon='twitter' />}
          {coach.links?.tk &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={coach.links?.tk}
              icon='tiktok' />}
          {coach.links?.yt &&
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={coach.links?.yt}
              icon='youtube' />}

        </div>

        <div className='flex justify-center t-w leading-none'>
          <Link to='followers'>
            <div className='w-30/100 mx-2'>
              <p className='fs-14 mb-1.5'>Followers:</p>
              <p className='fs-24'>{coach.followerCount}</p>
            </div>
          </Link>
          <Link to='following'>
            <div className='w-30/100 mx-2'>
              <p className='fs-14 mb-1.5'>Following:</p>
              <p className='fs-24'>{coach.followingCount}</p>
            </div>
          </Link>
        </div>
      </div>

    </div>
  )
}

export default HeaderPublicC;
