import { useState, useEffect } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import useApiPublic from '../../../../hooks/useApiPublic';
import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';
import Button from '../../buttons/Buttons';
import { STATES } from '../../../../data/states';


const SchoolForm = ({ username, coachesSchool = {}, onDone, onCancel }) => {
  const { apiPutPrivate, status } = useApiPrivate();
  const { apiGetPublic, result: pubResult } = useApiPublic();

  const [state, setState] = useState('');
  const [school, setSchool] = useState(coachesSchool.id ? coachesSchool : {});
  const [states] = useState(STATES); 
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    if (pubResult !== undefined) {
      setSchools(pubResult.schools);
    }
  }, [pubResult]);

  useEffect(() => {
    if (state) {
      apiGetPublic('/api/highschools/', { params: { state: state }});
    }
  }, [apiGetPublic, state]);

  useEffect(() => {
    if (status === 200) {
      onDone({ high_school: { id: school.id, name: school.name }});
    }
  }, [onDone, status, school]);

  const renderStates = (state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  }

  const renderSchools = (school, index) => {
    return (
      <option
        key={index}
        value={school.id}
        data-name={school.name} >{school.name} ({school.city})</option>
    )
  }

  const stateChange = (e) => {
    setState(e.target.value);
  }

  const schoolChange = (e) => {
    const schoolName = e.target.options[e.target.selectedIndex].getAttribute('data-name');
    setSchool({ id: e.target.value, name: schoolName });
  }
  
  const submit = (e) => {
    e.preventDefault();

    const data = { form: e.target.id, high_school_id: school.id }

    apiPutPrivate(`/api/coaches/${username.toLowerCase()}/`, data, {});
  }

  return (
    <form id='high_school' onSubmit={submit}>
      <FormLabel htmlFrom='state' label='State / Province' />
      <FormSelect
        id='state'
        classes='mb-2 b-bk block'
        placeholder='States'
        items={states}
        onChangeH={stateChange} >

        <option value='0'>Select One</option>
        {states.map(renderStates)}

      </FormSelect>

      <FormLabel htmlFrom='school' label='School' />
      <FormSelect
        id='school'
        classes='mb-2 b-bk block'
        placeholder='Schools'
        onChangeH={schoolChange} >

        {school?.id && <option value={school.id}>{school.name}</option>}
        {!school?.id &&<option value=''>Select One</option>}
        <option value='0'>None</option>
        {schools.map(renderSchools)}

      </FormSelect>

      <Button classes='btn disabled btn-edit-save mr-3' type='submit'>Save</Button>
      <Button classes='btn btn-edit-save' click={(e) => onCancel(e)}>Cancel</Button>
    </form>
  )
}

export default SchoolForm;
