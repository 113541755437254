import { useEffect } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';


const RemoveEntrant = ({ children, matchId, playerId, onEntrantRemoved = undefined }) => {
  const { apiDeletePrivate, result } = useApiPrivate();

  useEffect(() => {
    if (result?.removed) {
      // removed is removed players id
      onEntrantRemoved(result.removed);
    }
  }, [result, onEntrantRemoved]);

  const clickH = (e) => {
    e.stopPropagation();

    const data = { matchId, playerId };
    apiDeletePrivate('/api/matches/remove-player', { data: data });
  }

  return (
    <>
      <span
        className='t-bk'
        onClick={clickH} >{children}</span>
    </>
  )
}

export default RemoveEntrant;
