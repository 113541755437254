import './Badges.css';


const Badge = ({ children, classes, click = undefined }) => {

  return (
    <span
      onClick={click}
      className={classes} >
      {children}
    </span>
  )
}

export default Badge;
