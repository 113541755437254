import { useState, useEffect, useCallback } from 'react';

import ProfileImg from '../players/ProfileImg';
import { formatPlayerType } from '../../utils/formatting';
import LikeClip from './LikeClip';


const ClipModalViewInfo = ({ clip, creator }) => {
  const [likesCount, setLikesCount] = useState();

  useEffect(() => {
    setLikesCount(clip.likes);
  }, [clip.likes]);

  const onLikedH = useCallback(() => {
    setLikesCount(previous => {
      return parseInt(previous) + 1;
    });
  }, []);

  const onUnLikedH = useCallback(() => {
    setLikesCount(previous => {
      return parseInt(previous) - 1;
    });
  }, []);

  return (
    <div className='p-6'>

      <div className='mb-8'>
        <h1 className='ft1-med fs-24 leading-7'>{clip.title}</h1>
      </div>

      <div className='flex mb-6'>
        <div className='w-18/100 mr-4'>
          <ProfileImg
            userId={creator.id}
            imgName={creator.img_1}
           classes='b4px-w rounded-full' />
        </div>
        <div className='flex'>
          <div className='self-center'>
            <p className='fs-24 ft1-med leading-7'>{creator.username}</p>
            <p className='fs-14'>{formatPlayerType(creator.player_type)}</p>
          </div>
        </div>
      </div>

      <div className='mb-6'>
        Likes {likesCount}
      </div>

      <div className='clip-info-actions flex justify-between'>
        <div>
          <LikeClip
            clipId={clip.id}
            liked={clip.liked}
            onLiked={onLikedH}
            onUnliked={onUnLikedH} />
        </div>
        <div>Comment</div>
        <div>Share</div>
      </div>

    </div>
  )
}

export default ClipModalViewInfo;
