import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import useApiPrivate from '../../../hooks/useApiPrivate';
import PageContainer from '../../layout/PageContainer';
import HeaderPublic from './HeaderPublic';
import ActionsPublic from './ActionsPublic';
import NavPublic from './navPublic';
import HomePublic from './HomePublic';
import VideosPublic from './VideosPublic';
import FollowersPublic from './FollowersPublic';
import FollowingPublic from './FollowingPublic';
import FollowersCoachesPublic from './FollowersCoachPublic';
import FollowingCoachPublic from './FollowingCoachPublic';

const BasePublic = ({ slug }) =>{
  const { apiGetPrivate, result } = useApiPrivate();
  const [player, setPlayer] = useState({});

  useEffect(() => {
    if (result?.user) {
      setPlayer(result.user);
    }
  }, [result]);

  useEffect(() => {
    apiGetPrivate(`/api/players/${slug}/`);
  }, [apiGetPrivate, slug]);

  return (
    <>
      <PageContainer>
        <HeaderPublic player={player} />

        <div className='mt-10 mb-6 flex justify-between'>
          <ActionsPublic player={player} />
          <NavPublic />
        </div>

      </PageContainer>
      <Routes>
        <Route
          path=''
          element={<HomePublic player={player} />} />
        <Route
          index
          path='/followers'
          element={<FollowersPublic player={player} />} />
        <Route
          index
          path='/following'
          element={<FollowingPublic player={player} />} />
        <Route
          index
          path='/followers-coach'
          element={<FollowersCoachesPublic player={player} />} />
        <Route
          index
          path='/following-coach'
          element={<FollowingCoachPublic player={player} />} />
        <Route
          path='/videos/gallery'
          element={<VideosPublic player={player} />} />
      </Routes>
    </>
  )
}

export default BasePublic;
