import { useEffect, useState, useRef, useCallback } from 'react';

import useApiPrivate from '../../../hooks/useApiPrivate';
import Button from '../../layout/buttons/Buttons';
import PlayerCard from '../../players/PlayerCard';


const FollowingPrivateC = ({ coach }) =>{
  const { apiGetPrivate, error } = useApiPrivate();
  const [page, setPage] = useState(0);
  const [perPage] = useState(5);
  const [moreUsers, setMoreUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const initLoad = useRef(true);

  const usersSet = useCallback((result) => {
    if (result?.users.length === 0) {
      setMoreUsers(false);
    } else {
      setUsers(previous => [...previous, ...result.users]);
      if (result?.users.length < perPage) {
        setMoreUsers(false);
      }
    }
  }, [perPage]);

  const getPlayers = useCallback((page, perPage) => {
    const params = { page, perPage };

    apiGetPrivate(
      `/api/coach-player-follows/following/${coach.id}/`,
      { params: params},
      usersSet);
  }, [apiGetPrivate, usersSet, coach.id]);
  
  useEffect(() => {
    if (initLoad.current) {
      getPlayers(0, perPage);
      setPage(1);
      initLoad.current = false;
    }
  }, [getPlayers, perPage]);

  const getMorePlayers = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getPlayers(page, perPage)
  }

  const renderPlayers = (player, index) => {
    return (
      <PlayerCard
        key={index}
        playerId={player.id}
        username={player.username}
        slug={player.slug}
        imgUrl={player.img_1}
        playerType={player.player_type} />
    )
  }

  return (
    <div className=''>
      {users.length === 0 && <p>You aren't following anyone yet</p>}
      <div className='grid grid-cols-2 md:grid-cols-6 gap-6 mb-10'>
        {users.length > 0 && users.map(renderPlayers)}
      </div>

      {moreUsers && error === undefined &&
        <div>
          <Button
            classes='btn-blk b-pr py-3 px-2.5 radius1 bg-pr t-w'
            click={getMorePlayers}>Get More</Button>
        </div>}
    </div>
  )
}

export default FollowingPrivateC;
