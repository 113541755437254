import { useEffect, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import ConfirmModal from '../../../layout/modals/ConfirmModal';


const DeleteBucket = ({ bucketId, children, onDeleted = undefined }) => {
  const { apiDeletePrivate, result } = useApiPrivate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (result?.deleted) {
      if (onDeleted !== undefined) {
        onDeleted(bucketId);
      }
    }
  }, [result, onDeleted, bucketId]);

  const removePlayerH = () => {
    const data = { bucketId: bucketId };
    apiDeletePrivate(`/api/player-buckets/${bucketId}/`, { data: data });
    setShowModal(false);
  }

  const cancelRemoveH = () => {
    setShowModal(false);
  }

  const clickH = (e) => {
    e.stopPropagation();
    setShowModal(true);
  }

  return (
    <>
      <span onClick={clickH}>{children}</span>

      {showModal &&
        <ConfirmModal
          onConfirm={removePlayerH}
          onCancel={cancelRemoveH} >

          <div className='fs-20 text-center'>
            Confirm bucket deletion
          </div>
        </ConfirmModal>}
    </>
  )
}

export default DeleteBucket;