import { createPortal } from 'react-dom';


const BackDrop = (props) => {
  const onClickH = (e) => {
    if (e.target !== e.currentTarget) {
      return
    } else {
      props.onClose();
    }
  }

  return (
    <>
      {createPortal(
        <div
          className='flex justify-center items-center z-50 backdrop'
          onClick={onClickH}>

          {props.children}

        </div>,
        document.getElementById('modal')
      )}
    </>
  )
}

export default BackDrop;
