import { useState } from 'react';


const DateTimeFormat = ({ dateTime, classes }) => {
  const [dt] =
    useState(new Date(dateTime).toLocaleString(
      'en-us',
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      }
    ));

  return (
    <>{dt}</>
  )
}

export default DateTimeFormat;
