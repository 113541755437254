import { useEffect, useState, useCallback } from 'react'

import ClipModalViewBase from './ClipModalViewBase';
import Clip from './Clip';
import Badge from '../layout/badges/Badges';
import { formatDateTime } from '../../utils/formatting';
import FeatureClipPrivate from './FeatureClipPrivate';
import FeatureClipPublic from './FeatureClipPublic';
import DeleteClip from './DeleteClip';


const ClipCardProfile = ({ clip, creator }) => {
  const [modalView, setModalView] = useState(false);
  const [privateClip, setPrivateClip] = useState();

  useEffect(() => {
    if (creator?.id) {
      setPrivateClip(creator.private);
    }
  }, [creator.id, creator.private]);

  const openModal = useCallback(() => {
    setModalView(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalView(false);
  }, []);

  return (
    <>
      <div className='pb-10 b-w radius1 relative mb-2'>
        {privateClip && <DeleteClip clipId={clip.id} />}

        <Clip
          clipUrl={clip.video}
          classes='radius1-top z-0'
          hasControls={false} />
        
        <div
          onClick={openModal}
          className='radius1-bottom -mt-10 px-4 pt-4 bg-255-grad-blur h-20 absolute w-full cursor-pointer flex justify-between'>
          <div className='flex flex-col justify-between items-start pb-2 w-70/100 text-ellipsis overflow-hidden'>
            <p className='ml-0.5 whitespace-nowrap'>{clip.title}</p>
            <Badge classes='badge-sm badge-pr radius1'>
              League of Legends
            </Badge>
          </div>
          <div className='flex flex-col justify-between items-end pb-2'>
            {privateClip && <FeatureClipPrivate clipId={clip.id} featured={clip.selected} />}
            {!privateClip && <FeatureClipPublic featured={clip.selected} />}
            <p>{formatDateTime(clip.added)}</p>
          </div>
        </div>
      </div>

      {modalView &&
        <ClipModalViewBase
          clipId={clip.id}
          creator={creator}
          onClose={closeModal} />}
    </>
  )
}

export default ClipCardProfile;
