import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faXmark } from '@fortawesome/free-solid-svg-icons';


const BlockUser = ({ playerId, classes }) => {
  const action = () => {
    console.log('Blocking');
  }

  return (
    <>
      <div className={classes}>
        <FontAwesomeIcon icon={faXmark} className='mr-3' />
        <button disabled onClick={action}>Block</button>
      </div>
    </>
  )
}

export default BlockUser;
