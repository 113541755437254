import { Link } from 'react-router-dom';

import PlayerLeaderboardCard from './PlayerLeaderboardCard';
import { statFormat, formatPlayerType, formatLolTierInt, formatLolRankInt } from '../../utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import ToolTip from '../layout/ToolTip';


const LolLeaderCard = ({ leader }) => {
  // console.log('==>', leader)
  return (
    <div className='bg-wgr b-4 radius1 px-1.5 py-1.5 mb-4 flex'>
      <div className='mr-4 w-5/100 shrink-0'>
        {leader?.username &&
          <PlayerLeaderboardCard
            playerId={leader.user_id}
            slug={leader.username.toLowerCase()}
            imgUrl={leader.img_1} />}
      </div>

      <div className='w-full fs-13 flex justify-start leading-tight whitespace-nowrap'>
        <div className='self-center t-4 mr-5 w-8/100'>
          {leader?.username &&
            <Link to={`/players/${leader.username.toLowerCase()}`}>
              <p className='t-1 fs-14 mt-0-5 truncate ...'>{leader.username}</p>
              <p>{formatPlayerType(leader.player_type)}</p>
              <p>School</p>
            </Link>}
        </div>
        <div className='self-center t-4 mr-10 w-8/100'>
          <p>Summoner name</p>
          <p className='t-1 fs-14 mt-1 truncate ...'>{leader.summoner_name}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Games</p>
          <p className='t-1 fs-15 mt-1'>{leader.num_games}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <div className='relative'>
            <p className='tool leading-none'>Tier
            <FontAwesomeIcon
              icon={faInfo}
              className='fs-11 ml-1.5 self-center' /></p>
            <ToolTip><span>Tier & rank for 5x5 solo ranked</span></ToolTip>
          </div>
          <p className='t-1 fs-15 mt-1'>{formatLolTierInt(leader.ranked_solo_5x5_tier_int)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Rank</p>
          <p className='t-1 fs-15 mt-1'>{formatLolRankInt(leader.ranked_solo_5x5_rank_int)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>KDA</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.kda_avg)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Kills AVG</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.kills_avg)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Assists AVG</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.assists_avg)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Deaths AVG</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.deaths_avg)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>DPM AVG</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.dpm_avg)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Gold / Min</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.dpm_avg)}</p>
        </div>
        {/* <div className='self-center t-4 mr-10'>
          <p>Wins</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.ranked_solo_5x5_wins, 0)}</p>
        </div>
        <div className='self-center t-4 mr-10'>
          <p>Losses</p>
          <p className='t-1 fs-15 mt-1'>{statFormat(leader.ranked_solo_5x5_losses, 0)}</p>
        </div> */}
      </div>
    </div>
  )
}

export default LolLeaderCard;
