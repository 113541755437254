import { NavLink } from 'react-router-dom';

import logoImg from '../../assets/imgs/logos/nav-logo.png';
import ProfileImg from '../players/ProfileImg';
import useTalkjs from '../../hooks/useTalkjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';


const NavBar = ({ user, loggedIn}) => {
  const activeClass = 'nav-atv mx-6 flex';

  const { unreads } = useTalkjs();

  let loggedInLinks = '';
  if (loggedIn === true) {
    loggedInLinks = (
      <div className='flex'>

        <div className='flex'>
          <span className='self-center mr-1'>{unreads}</span>
          <FontAwesomeIcon className='self-center mr-3' icon={faMessage} />
        </div>

        <NavLink
          to={`/${user.accountType === 1 ? 'players' : 'coaches'}/${user.username?.toLowerCase()}/`}
          className='self-center'>{user.username}</NavLink>

        <ProfileImg
          userId={user.id}
          imgName={user.img}
          classes='w-8 h-8 ml-2.5 self-center rounded-full b-4' />

      </div>
    )
  } else if (loggedIn === false) {
    loggedInLinks = (
        <NavLink
          to='/login'
          className={({isActive}) => isActive ? activeClass : ''}>Login</NavLink>
    )
  }

  return (
    <header className='nav-container'>
      <div className='container flex justify-between items-center h-full'>

        <div className='flex nav-links-container h-full'>
          <NavLink
            to='/'
            className='flex self-center mr-14 nav-logo'>
            <img
              className='nav-logo'
              src={logoImg}
              alt='Logo' /></NavLink>
          <NavLink
            to='/'
            className={({isActive}) => isActive ? activeClass + ' w43' : 'mx-6 w43 flex'}>
              <span className='self-center'>Home</span></NavLink>
          <NavLink
            to='/leaderboards'
            className={({isActive}) => isActive ? activeClass + ' w99' : 'mx-6 w99 flex'}>
              <span className='self-center'>Leaderboards</span></NavLink>
          <NavLink
            end
            to='/players'
            className={({isActive}) => isActive ? activeClass + ' w54' : 'mx-6 w54 flex'}>
              <span className='self-center'>Players</span></NavLink>
          <NavLink
            end
            to='/coaches'
            className={({isActive}) => isActive ? activeClass + ' w63' : 'mx-6 w63 flex'}>
              <span className='self-center'>Coaches</span></NavLink>
        </div>

        <div className='flex items-center relative'>
          {loggedInLinks}
        </div>

      </div>
    </header>
  )
}

export default NavBar;
