import { useEffect, useState, useCallback } from 'react';

import PageContainer from '../../components/layout/PageContainer';
import useApiPrivate from '../../hooks/useApiPrivate';
import Button from '../../components/layout/buttons/Buttons';
import FeedClipCard from '../../components/feed/FeedClipCard';


const FeedPage = () => {
  const { apiGetPrivate, result, error } = useApiPrivate();
  const [page, setPage] = useState(0);
  const [perPage] = useState(5);
  const [moreClips, setMoreClips] = useState(true);
  const [clips, setClips] = useState([]);

  useEffect(() => {
    if (result?.clips) {
      setClips(previous => [...previous, ...result.clips]);
      setPage(previous => previous + 1);
      if (result.clips.length < perPage) {
        setMoreClips(false);
      }
    }
  }, [perPage, result]);

  const getFeedClips = useCallback((page, perPage) => {
    const params = { page, perPage };

    apiGetPrivate('/api/clips/feed', { params: params });
  }, [apiGetPrivate]);

  useEffect(() => {
    getFeedClips(0, perPage);
  }, [getFeedClips, perPage]);

  const renderClips = (clip, index) => {
    return (
      <FeedClipCard
        key={index}
        clip={clip} />
    )
  }

  return (
    <PageContainer>
      <div className='flex justify-between'>
        <div className='w-65/100'>
          {clips && clips.map(renderClips)}

          {moreClips && error === undefined &&
            <div className='mt-4 flex justify-center'>
              <Button
                classes='py-4 px-8 bg-5 t-3 ft1-med radius2'
                click={() => getFeedClips(page, perPage)}>Load more</Button>
            </div>}
        </div>

        <div></div>
      </div>
    </PageContainer>
  )
}

export default FeedPage;
