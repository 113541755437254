import PageContainer from "../../components/layout/PageContainer";


const HighSchoolsPage = () => {

  return (
    <PageContainer>
      <div>
        <h1>High schools list page coming soon</h1>
      </div>
    </PageContainer>
  )
}

export default HighSchoolsPage;
  