import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import DeleteBucket from './DeleteBucket';
import EditBucket from './EditBucket';
import ProfileImg from '../../../players/ProfileImg';


const BucketCard = ({
    bucketId, bucketTitle, bucketDescription,
    playersList, selected, onDeleted }) => {

  const [showActions, setShowActions] = useState(false);
  const [title, setTitle] = useState(bucketTitle);
  const [description, setDescription] = useState(bucketDescription);
  const [players, setPlayers] = useState(playersList);

  useEffect(() => {
    if (bucketTitle) {
      setTitle(bucketTitle);
    }
    if (bucketDescription) {
      setDescription(bucketDescription);
    }
    if (playersList) {
      const listLength = playersList.length;
      if (listLength < 5) {
        const baseList = Object.assign(new Array(5).fill({}), playersList);
        setPlayers(baseList);
      } else {
        setPlayers(playersList);
      }
    }
  }, [bucketTitle, bucketDescription, playersList]);

  const showActionsH = (e) => {
    e.stopPropagation();

    setShowActions(previous => !previous);
  }

  const hideActionsH = (e) => {
    e.stopPropagation();

    setShowActions(previous => !previous);
  }

  const updateBucketH = (updatedBucket) => {
    setTitle(updatedBucket.title);
    setDescription(updatedBucket.description);
    setShowActions(false);
  }

  const renderPlayers = (player, index) => {
    return (
      <div key={index} className='img-container rounded-full b4px-w w-30/100 mx-1 mb-4'>
        <ProfileImg
          userId={player[0]}
          imgName={player[1]}
          classes='rounded-full' />
      </div>
    )
  }

  return (
    <div className='flex flex-1 flex-col'>
      <p className='fs-20 ft1-med t-1 my-5'>{title}</p>
      <div
        className={`px-2 pb-4 radius1 border-box bg-wgr cursor-pointer relative ${selected ? 'b3px-w' : 'b-4'}`}>

        <div className='relative flex justify-center'>
          <div className='my-4 relative w-full'>
          <div className='flex justify-end mb-3 relative'>
            <div className='rounded-full px-1 py-0.5 bg-6'>
              <FontAwesomeIcon
                icon={faEllipsis}
                size='xl'
                className=''
                onClick={showActionsH} />
            </div>
            {showActions &&
              <div className='b3px-2 bg-1 p-3 radius05 bucket-actions top-0 right-10'>
                <div>
                  <DeleteBucket
                    key={bucketId}
                    bucketId={bucketId}
                    onDeleted={onDeleted} >Delete bucket</DeleteBucket>
                </div>
                <div>
                  <EditBucket
                    bucketId={bucketId}
                    currentTitle={title}
                    currentDescription={description}
                    onSaved={updateBucketH} >
                    Edit bucket
                  </EditBucket>
                </div>

                <div onClick={hideActionsH}>Cancel</div>

              </div>}
            </div>

            <div className='flex flex-wrap justify-center h-fit'>
              {players.length > 0 && players.map(renderPlayers)}
            </div>

          </div>

        </div>

        <div className=''>
          <hr className='mx-2 mt-2.5 mb-2.5'></hr>
          <div className='px-2 h-10 fs-14 t-4 line-clamp-2'>{bucketDescription}</div>
        </div>
      </div>
    </div>
  )
}

export default BucketCard;
