import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user';
import messagesReducer from './messages';


const store = configureStore({
  reducer: {
    user: userReducer,
    messages: messagesReducer
  }
});

export default store;
