import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import useApiRefreshAccessPromise from './hooks/apiRefreshPromise';
import useTalkjs from './hooks/useTalkjs';

import TopNav from './components/nav/TopNav';
import Footer from './components/nav/Footer';
import HomePage from './pages/home/HomePage';
import FeedPage from './pages/home/FeedPage';
import PlayersPage from './pages/users/PlayersPage';
import PlayerPage from './pages/users/PlayerPage';
import CoachesPage from './pages/users/CoachesPage';
import CoachPage from './pages/users/CoachPage';
import ClipPage from './pages/clips/ClipPage';
import LeaderboardsPage from './pages/leaderboards/LeaderboardsPage';
import LeaderboardPage from './pages/leaderboards/LeaderboardPage';
import GamesPage from './pages/games/GamesPage';
import GamePage from './pages/games/GamePage';
import HighSchoolsPage from './pages/schools/HighSchoolsPage';
import HighSchoolPage from './pages/schools/HighSchoolPage';
import CollegesPage from './pages/schools/CollegesPage';
import CollegePage from './pages/schools/CollegePage';
import PlayerRegisterPage from './pages/auth/PlayerRegisterPage';
import PlayerSubscriptionPage from './pages/auth/PlayerSubscriptionPage';
import CoachRegisterPage from './pages/auth/CoachRegisterPage';
import LoginPage from './pages/auth/LoginPage';
import ForgotPassword from './pages/auth/ForgotPassword';
import AboutPage from './pages/info/AboutPage';
import ContactPage from './pages/info/ContactPage';
import Faq from './pages/info/Faq';
import Terms from './pages/info/Terms';
import Privacy from './pages/info/Privacy';
import EmailVerifyPage from './pages/auth/EmailVerify';
import EmailVerifyLandingPage from './pages/auth/EmailVerifyLandingPage';


// import ClearBtn from './components/auth/ClearBtn';


const App = () => {
  const refreshToken = useApiRefreshAccessPromise();
  useTalkjs();
  const [initLoad, setInitLoad] = useState(true);
  const loggedIn = useSelector(state => state.user.loggedIn);
  const accoutverified = useSelector(state => state.user.user.verified);
  const location = useLocation();

  useEffect(() => {
    console.log('ENV:', process.env.REACT_APP_ENVIRONMENT);
    console.log('VERSION:', process.env.REACT_APP_VERSION);
  }, [])

  useEffect(() => {
    const getToken = async () => {
      const t = await refreshToken();
      return t;
    }

    // check check cookie to see if there is a refresh token httpOnly cookie
    const checkCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('check='))
      ?.split('=')[1];

    if (checkCookie === '1') {
      getToken().then(res => setInitLoad(false));
    } else {
      setInitLoad(false);
    }
  }, [refreshToken]);

  if (initLoad) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  const pathname = location.pathname.split('/')[1];

  if (!accoutverified &&
      pathname !== 'login' &&
      pathname !== 'register' &&
      pathname !== 'email-verify' &&
      pathname !== 'email-verification-requried' &&
      pathname !== 'forgot-password') {
    if (loggedIn) {
      return <Navigate to='/email-verification-requried' replace />;
    } else {
      return <Navigate to='/login' replace />
    }
  }

  let layoutOptions = '';
  if (location.pathname !== '/login' &&
      location.pathname !== '/register' &&
      location.pathname !== '/register/coach' &&
      location.pathname !== '/forgot-password') {
    layoutOptions = (
      <>
        <span className='purple-burst'></span>
        <span className='pink-burst'></span>
        <TopNav />
      </>
    )
  } else {
    layoutOptions = (
      <>
        <span className='purple-burst-forms'></span>
      </>
    )
  }

  return (
    <>
      {/* <ClearBtn classes='t-w fs-11 absolute top-0 left-1' /> */}
      <div>
        {layoutOptions}

        <Routes>
          <Route path='/players' element={<PlayersPage />} />
          <Route path='/players/:slug/*' element={<PlayerPage />} />
          <Route path='/coaches' element={<CoachesPage />} />
          <Route path='/coaches/:slug/*' element={<CoachPage />} />
          <Route path='/leaderboards' element={<LeaderboardsPage />} />
          <Route path='/leaderboards/:game' element={<LeaderboardPage />} />
          <Route path='/register' element={<PlayerRegisterPage />} />
          <Route path='/register/coach' element={<CoachRegisterPage />} />
          <Route path='/players/subscription' element={<PlayerSubscriptionPage />} />
          <Route path='/clips/:id' element={<ClipPage />} />
          <Route path='/games' element={<GamesPage />} />
          <Route path='/games/:slug' element={<GamePage />} />
          <Route path='/high-schools' element={<HighSchoolsPage />} />
          <Route path='/high-schools/:id' element={<HighSchoolPage />} />
          <Route path='/colleges' element={<CollegesPage />} />
          <Route path='/colleges/:id' element={<CollegePage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/forgot-password/:token' element={<ForgotPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path='/email-verify/:token' element={<EmailVerifyPage />} />
          <Route path='/email-verification-requried' element={<EmailVerifyLandingPage />} />
          <Route exact path='/' element={loggedIn ? <FeedPage /> : <HomePage />} />
        </Routes>
      </div>    
      <Footer />
    </>
  )
}

export default App;
