import PageContainer from "../../components/layout/PageContainer";


const CollegesPage = () => {

  return (
    <PageContainer>
      <div>
        <h1>Colleges list page coming soon</h1>
      </div>
    </PageContainer>
  )
}

export default CollegesPage;
