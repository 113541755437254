import PageContainer from '../../components/layout/PageContainer';


const HomePage = () => {
  return (
    <PageContainer>
      <h1>Home Info Page</h1>
      <p>Log in or register to see more</p>
    </PageContainer>
  )
}

export default HomePage;
