import { useEffect, useState, useCallback } from 'react';

import LikeClip from '../clips/LikeClip';
import Clip from '../clips/Clip';


const FeedClipCard = ({ clip }) => {
  // console.log('Clip=>', clip)

  const [likesCount, setLikesCount] = useState();

  useEffect(() => {
    setLikesCount(clip.likes);
  }, [clip.likes]);

  const onLikedH = useCallback(() => {
    setLikesCount(previous => {
      return parseInt(previous) + 1;
    });
  }, []);

  const onUnLikedH = useCallback(() => {
    setLikesCount(previous => {
      return parseInt(previous) - 1;
    });
  }, []);


  return (
    <div className='b-4 pt-6 pb-6 px-8 radius1 bg-wgr mb-8'>
      <Clip
        clipUrl={clip.video}
        classes='radius1-top w-full'
        hasControls={true} />
      
      <div className='  '>
        <p>{clip.title}</p>
        <div className=''>
          <p>Likes {likesCount} &nbsp;</p>
          <LikeClip
            clipId={clip.id}
            liked={clip.liked}
            onLiked={onLikedH}
            onUnliked={onUnLikedH} />
        </div>
      </div>
    </div>
  )
}

export default FeedClipCard;
