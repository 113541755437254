import { useEffect, useState } from 'react';

import useApiPrivate from '../../../../hooks/useApiPrivate';
import ConfirmModal from '../../../layout/modals/ConfirmModal';


const RemovePlayerFromBucket = ({
    bucketId, playerId, children,
    onRemoved = undefined, allBuckets = false }) => {

  const { apiDeletePrivate, result } = useApiPrivate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (result?.removed) {
      if (onRemoved !== undefined) {
        onRemoved();
      }
    }
  }, [result, onRemoved]);

  const removePlayerH = () => {
    if (!allBuckets) {
      const data = { bucketId: bucketId, playerId: playerId };
      apiDeletePrivate(`/api/player-buckets/delete-player/${playerId}/`, { data: data });
    } else {
      const data = { playerId: playerId };
      apiDeletePrivate(`/api/player-buckets/delete-player-all/${playerId}/`, { data: data });
    }
    setShowModal(false);
  }

  const cancelRemoveH = () => {
    setShowModal(false);
  }

  return (
    <>
      <span onClick={() => setShowModal(true)}>{children}</span>

      {showModal &&
        <ConfirmModal
          onConfirm={removePlayerH}
          onCancel={cancelRemoveH} >

          <div className='fs-20 text-center'>
            Confirm removing player
          </div>
        </ConfirmModal>}
    </>
  )
}

export default RemovePlayerFromBucket;
