
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faLocationDot } from "@fortawesome/free-solid-svg-icons";


const HeaderOrganization = ({ coachType, highSchool, college }) => {
  let org = {};
  if (coachType === 2 && highSchool?.id) {
    org.name = highSchool.name;
    org.address = `${highSchool.hs_city}, ${highSchool.state}`;
  } else if ((coachType === 3 || coachType === 4) && college?.id) {
    org.name = college.name;
    org.address = college.c_address;
  } else {
    org.name = 'No school info';
    org.address = ''
  }

  return (
    <>
      <p className='mb-1.5'>
        <FontAwesomeIcon icon={faGraduationCap} className='mr-1.5' />
        {org.name}
      </p>

      <p className=''>
        <FontAwesomeIcon icon={faLocationDot} className='ml-1 mr-2.5' />
        {org?.address}
      </p>
    </>
  )
}


export default HeaderOrganization;
