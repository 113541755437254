import { useEffect, useState } from 'react';

import BackDrop from '../../../layout/modals/Backdrop';
import useApiPrivate from '../../../../hooks/useApiPrivate';


const EditBucket = ({
    bucketId, currentTitle, currentDescription,
    onSaved = undefined, children }) => {

  const { apiPutPrivate, result, loading } = useApiPrivate();
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    if (currentTitle) {
      setTitle(currentTitle);
    }
    if (currentDescription) {
      setDescription(currentDescription);
    }
  }, [currentTitle, currentDescription]);

  const showFormH = () => {
    setShowForm(true);
  }

  const hideFormH = () => {
    setTitle(currentTitle);
    setDescription(currentDescription);
    setShowForm(false);
  }

  useEffect(() => {
    if (result?.updated) {
      if (onSaved !== undefined) {
        onSaved(result.updated);
      }
      setShowForm(false);
    }
  }, [onSaved, result]);

  const titleChangeH = (e) => {
    setTitle(e.target.value);
  }

  const descriptionChangeH = (e) => {
    setDescription(e.target.value);
  }

  const submitH = (e) => {
    e.preventDefault();

    const data = { title, description, bucketId };
    apiPutPrivate(`/api/player-buckets/edit-bucket/${bucketId}/`, data);
  }

  return (
    <>
      <span onClick={showFormH}>{children}</span>

      {showForm &&
        <BackDrop onClose={hideFormH}>
          <div className='w-40/100 p-6 b-1 bg-bk radius1'>
            <h1 className='mb-4'>Edit Player Bucket</h1>
            <form onSubmit={submitH}>
              <div className='mb-4'>
                <label className='fs-13 t-5'>Bucket name</label>
                <input
                  id='title'
                  className='w-full p-0 fs-15 px-2'
                  type='text'
                  value={title}
                  onChange={titleChangeH}
                  onFocus={titleChangeH}
                  onBlur={titleChangeH} />
              </div>
              <div className='mb-4'>
                <label className='fs-13 t-5'>Description</label>
                <textarea
                  id='description'
                  className='t-bk w-full p-0 fs-15 px-2'
                  value={description}
                  rows={4}
                  placeholder='Description'
                  autoComplete='off'
                  onChange={descriptionChangeH}
                  onFocus={descriptionChangeH}
                  onBlur={descriptionChangeH} />
              </div>
              <div className='flex justify-center mb-12'>
                <button
                  className={`btn w-full py-4 bg-5 t-3 ft1-med relative radius2${loading ? ' btn-loading' : ''}`}
                  type='submit'>
                  <span className={`${loading ? 'invisible' : ''}`}>Create bucket</span>
                </button>
              </div>
            </form>
          </div>
        </BackDrop>}
    </>
  )
}

export default EditBucket;
