import PageContainer from '../../components/layout/PageContainer';


const Faq = () => {
  return (
    <PageContainer>
      <h1>FAQs</h1>
    </PageContainer>
  )
}

export default Faq;
