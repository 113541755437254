import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import ProfileImg from './ProfileImg';
import { formatPlayerType } from '../../utils/formatting';
import PlayerModalViewBase from './profileModalView/PlayerModalViewBase';
import SocialMediaIcon from './utils/SocialMediaIcon';


const PlayerCard = ({ playerId, username, slug, imgUrl, playerType, links = {} }) => {
  const [modalView, setModalView] = useState(false);

  const openModalViewH = useCallback(() => {
    setModalView(true);
  }, []);

  const closeModalViewH = useCallback(() => {
    setModalView(false);
  }, []);

  return (
    <>
      <div className='px-4 py-7 radius1 b-4 border-box bg-wgr'>
        <div className='b4px-w w-60/100 rounded-full mx-auto img-container'>
          <Link to={`/players/${slug}/`} >
            <ProfileImg
              userId={playerId}
              imgName={imgUrl}
              classes='rounded-full' />
          </Link>
        </div>
        <div className='mt-2.5 text-center leading-none'>
          <div className='cursor-pointer' onClick={openModalViewH}>
            <p className='mb-2.5 ft1-med t-1 fs-20'>{username}</p>
            <p className='t-4' >{formatPlayerType(playerType)}</p>
          </div>
          <hr className='mt-2.5'></hr>
          <div className='mt-4 flex justify-center'>
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={links?.ig}
              icon='instagram' />
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={links?.tw}
              icon='twitch' />
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={links?.tt}
              icon='twitter' />
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={links?.tk}
              icon='tiktok' />
            <SocialMediaIcon
              classes='w-6 mx-2'
              link={links?.yt}
              icon='youtube' />
          </div>
        </div>
      </div>

      {modalView &&
        <PlayerModalViewBase
          playerId={playerId}
          slug={slug}
          onClose={closeModalViewH} />}
    </>
  )
}

export default PlayerCard;
