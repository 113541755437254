import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from '../../../../store/user';
import DefaultImg from '../../../../assets/imgs/defaults/default-prof.webp';
import useMediaPrivate from '../../../../hooks/useMediaPrivate';
import Button from '../../buttons/Buttons';


// saveToApiDb is true if player has any gamertags which would mean he has api stats
const ProfileImgForm = ({ saveToApiDb, onDone, onCancel }) => {
  const { mediaPostPrivate, result, status } = useMediaPrivate();
  const dispatch = useDispatch();
  const [imgFile, setImgFile] = useState();
  const [preview, setPreview] = useState(DefaultImg);

  useEffect(() => {
    if (status === 200) {
      onDone(result.img_1);
      dispatch(userActions.update({ img: result.img_1 }));
    }
  }, [dispatch, onDone, status, result]);

  const onChangeH = (e) => {
    setImgFile(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  }

  const submit = (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('img', imgFile);
    formData.append('saveToApiDb', saveToApiDb);

    mediaPostPrivate(`/api/images/players/profile/`, formData, {});
  }

  return (
    <>
      <div className='w-40/100'>
        <div className='mb-4 rounded-full b4px-w img-container'>
          <img
            className='rounded-full'
            src={preview}
            alt='preview img' />
        </div>

        <form onSubmit={submit}>
          <input
            className='mb-3 t-w b-w'
            type='file'
            onChange={onChangeH}
            accept='.png,.jpg,.jpeg,.webp' />

          <Button
            classes='btn py-2 px-4 bg-5 t-3 ft1-med relative radius2 mr-3'
            type='submit'>Save</Button>
          <Button
            classes='btn py-2 px-4 bg-5 t-3 ft1-med relative radius2'
            click={(e) => onCancel(e)}>Cancel</Button>
        </form>
      </div>
    </>
  )
}

export default ProfileImgForm;
