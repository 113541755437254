import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import useRefreshAccess from './apiRefreshAccess';


const useVideoUpload = () => {
  const [result, setResult] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.user.token);
  const { refreshToken } = useRefreshAccess();


  const uploadVideo = useCallback(async (videoFile, gameId, title) => {
    if (token !== '') {
      setLoading(true);

      const axiosPrivate = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        }
      });

      try {
        const fileExt = videoFile.name.split('.').pop();
        const data = {
          ext: fileExt,
          title: title,
          gameId: gameId
        }
        const url = await axiosPrivate.post(process.env.REACT_APP_BASE_MEDIA_URL + '/api/videos/get-url', data, {});
        const signedUrl = url.data.uploadUrl;

        console.log('SURL: ', signedUrl)
        
        const uploadedVidResp = await axios.put(signedUrl, videoFile, { headers: { 'Content-Type': 'video/mp4' }});

        setResult('success');
        setStatus(uploadedVidResp?.status);
        setLoading(false);
      } catch (error) {
        console.log('Error: ', error)
        if (error?.response.status === 401) {
          refreshToken();
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  }, [token, refreshToken]);

  return {
    uploadVideo,
    setStatus,
    status,
    result,
    error,
    loading
  }

}

export default useVideoUpload;
