import ProfileImg from '../../ProfileImg';
import acceptedSvg from '../../../../assets/imgs/icons/check.svg';
import pendingSvg from '../../../../assets/imgs/icons/question-mark-circle.svg';


const FormatDetailsCard = ({ matchId, player }) => {
  return (
    <>
      {player?.user_id !== undefined &&
        <div className='px-8 mx-6 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48'>
          {player.entrant_status === 2 &&
            <div
              className='absolute top-1 left-1 accept-burst p-3' >
              <img className='check-burst' src={acceptedSvg} alt='accepted' />
            </div>}
          {player.entrant_status === 1 &&
            <div
              className='absolute top-1 left-1 pending-burst p-3' >
              <img className='check-burst' src={pendingSvg} alt='accepted' />
            </div>}

          <div className='shrink-0'>
            <div className='img-container rounded-full b4px-w w-full mb-4'>
              <ProfileImg
                userId={player.id}
                imgName={player.img_1}
                classes='rounded-full' />
              </div>
            <p className='text-center fs-14 mb-2'>{player.username}</p>
            <hr className='mx-4'></hr>
          </div>
        </div>}

        {player?.user_id === undefined &&
        <div
          className='px-8 mx-6 bg-255-grad-blur b-4 radius1 h-60 flex flex-col justify-center w-48'>
          <div className='shrink-0'>
            <p className='text-center fs-14 mb-2'>Open spot</p>
            <hr className='mx-4'></hr>
          </div>
        </div>}
    </>
  )
}

export default FormatDetailsCard;
