import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import ProfileImg from '../../../players/ProfileImg';
import { formatPlayerType } from '../../../../utils/formatting';
import PlayerModalViewBase from '../../../players/profileModalView/PlayerModalViewBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdCard, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import RemovePlayerFromBucket from './RemovePlayerFromBucket';
import MovePlayerToDiffBucket from './MovePlayerToDiffBucket';
import AddPlayerToBucket from './AddPlayerToBucket';


const BucketPlayerCard = ({ bucketId, playerId, username, slug, imgUrl, playerType, onRemoved }) => {
  const [modalView, setModalView] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const openModalViewH = useCallback(() => {
    setModalView(true);
  }, []);

  const closeModalViewH = useCallback(() => {
    setModalView(false);
  }, []);

  const removePlayer = useCallback(() => {
    onRemoved(playerId);
    setShowActions(false);
  }, [onRemoved, playerId]);

  return (
    <>
      <div className='px-2 py-4 radius1 b-4 border-box bg-wgr'>
        <div className='b4px-w w-60/100 rounded-full mx-auto img-container'>
          <ProfileImg
            userId={playerId}
            imgName={imgUrl}
            classes='rounded-full' />
        </div>
        <div className='mt-2.5 text-center leading-none'>
          <p className='mb-2.5 ft1-med t-1 fs-15'>{username}</p>
          <p className='t-4'>{formatPlayerType(playerType)}</p>
          <hr className='mt-2.5'></hr>
          <div className='mt-4 flex justify-around'>
            <Link
              to={`/players/${slug}/`} >
              <FontAwesomeIcon
                icon={faUser}
                size='xl'
                className='t-1' />
            </Link>
            <FontAwesomeIcon
              icon={faIdCard}
              size='xl'
              className='t-1 cursor-pointer'
              onClick={openModalViewH} />
            <div className='relative'>
              <FontAwesomeIcon
                icon={faEllipsis}
                size='xl'
                className='t-1 cursor-pointer'
                onClick={() => setShowActions(previous => !previous)} />
              {showActions &&
                <div className='b3px-2 bg-1 p-3 radius05 bucket-player-actions bottom-0 right-10'>
                  <div>
                    <RemovePlayerFromBucket
                      key={playerId}
                      bucketId={bucketId}
                      playerId={playerId}
                      allBuckets={false}
                      onRemoved={removePlayer} >Remove from bucket</RemovePlayerFromBucket>
                  </div>
                  <div>
                    <RemovePlayerFromBucket
                      key={playerId}
                      bucketId={bucketId}
                      playerId={playerId}
                      allBuckets={true}
                      onRemoved={removePlayer} >Remove from all buckets</RemovePlayerFromBucket>
                  </div>
                  <div>
                    <MovePlayerToDiffBucket
                      currentBucketId={bucketId}
                      playerId={playerId}
                      onMoved={removePlayer}>
                      Move player to different bucket
                    </MovePlayerToDiffBucket>
                  </div>
                  <div> 
                    <AddPlayerToBucket
                      playerId={playerId}
                      onAdded={() => setShowActions(false)} >
                      Clone player to bucket
                    </AddPlayerToBucket>
                  </div>
                  <div onClick={() => setShowActions(previous => !previous)}>Cancel</div>
                </div>}
            </div>
          </div>
        </div>
      </div>

      {modalView &&
        <PlayerModalViewBase
          playerId={playerId}
          slug={slug}
          onClose={closeModalViewH} />}
    </>
  )
}

export default BucketPlayerCard;
